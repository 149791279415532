<template>
    <div
            :class="{ 'd-flex flex-row-reverse': !msg.is_mine,
             'd-flex flex-row': msg.is_mine }"
            style="max-width:100%"
    >

        <v-card
                dense
                style="height:auto;white-space: normal;"
                class="pa-1 ma-1 "
                :class="{'elevation-1':isImage(msg.attachment.extension),'elevation-0':!isImage(msg.attachment.extension)}"
                max-width="80%"
        >
            <v-card-text class="pa-0 ma-0">
                <div>
                    <v-chip
                            :color="msg.is_mine ? 'primary' : ''"
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-0"
                            v-if="!isImage(msg.attachment.extension)"
                    >
                        <div style="direction: ltr;">{{msg.attachment.name+'.'+msg.attachment.extension}}</div>
                        <div>

                            <v-progress-circular
                                    :value="downloadProgress"
                                    :size="25"
                                    :width="2"
                                    color="blue" class="mx-1"
                            >
                                <template v-if="status=='basic'">
                                    <v-icon small @click="downloadFile"> mdi-download</v-icon>
                                </template>
                                <template v-else-if="status=='inProgress'">
                                    <div style="font-size: .6em;margin-top: 3px;">{{downloadProgress}}</div>
                                </template>

                            </v-progress-circular>
                            <div style="font-size: .8em;direction: ltr" class="text-center">
                                <sub> {{ msg.attachment.size }}</sub>
                            </div>
                        </div>


                    </v-chip>
                    <template v-else>

                        <img @click="magnifyImage" :src="srcData" style="width: 100%;max-height: 300px;"/>
                    </template>
                </div>
                <sub
                        class="mt-0"
                        style="font-size: 0.5rem;"
                >{{ msg.date }}</sub>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    /*eslint-disable*/
    // import BaseMessage from './BaseMessage';
    import FileDownload from 'js-file-download';
    import Axios from 'axios';

    export default {
        props: ['msg', 'mode','clientId'],
        name: 'text-message',
        // extends: BaseMessage,
        data() {
            return {
                srcData: null,
                imageTypes: [
                    'png', 'jpg', 'jpeg', 'gif'
                ],
                status: 'basic',
                downloading: false,
                downloadProgress: 0
            }
        }
        ,
        methods: {
            magnifyImage() {
                this.$emit('magnify', this.srcData);
            },
            assignSource(data) {
                let reader = new window.FileReader();
                reader.onload = (e) => {
                    this.srcData = reader.result;
                }
                reader.readAsDataURL(data);
            },
            isImage(type) {
                return this.imageTypes.includes(type.toLowerCase()) || type.startsWith('image');
            },
            downloadFile() {

                this.status = 'inProgress';
                let url = this.msg.attachment.url;
                let name = this.msg.attachment.name + '.' + this.msg.attachment.extension;
                let ajaxUrl = '';

                if (this.mode == 'customer') {
                    ajaxUrl = this.$store.getters.getBaseUrl + `/user/conversations/${this.msg.conversation_id}/getFile/${url}`;
                } else if (this.mode == 'counselor') {
                    ajaxUrl = this.$store.getters.getBaseUrl + `/doctor/conversations/${this.msg.conversation_id}/getFile/${url}`;
                } else if (this.mode == 'admin') {
                    ajaxUrl = this.$store.getters.getBaseUrl + `/admin/conversations/${this.msg.conversation_id}/getFile/${url}`;
                } else if (this.mode == 'admin-client') {
                    ajaxUrl = this.$store.getters.getBaseUrl + `/admin/clients/${this.clientId}/getFile/${url}`;
                }

                this.ajaxDownload(ajaxUrl, name)
            },
            ajaxDownload(url, filename) {
                try {
                    return new Promise((resolve, reject) => {
                        Axios({
                            method: 'GET',
                            url,
                            withCredentials: true,
                            headers: {
                                Authorization: this.$store.getters.getAccessToken
                            },
                            responseType: 'blob',
                            onDownloadProgress: (progress) => {
                                this.downloadProgress = parseInt(Math.round((progress.loaded / progress.total) * 100))
                            }
                        }).then(resp => {
                            if (this.isImage(this.msg.attachment.extension)) {

                                this.assignSource(resp.data);

                            } else {
                                FileDownload(resp.data, filename);
                            }
                            resolve();
                        }).catch((err) => {
                            // store.commit('setLoading', false);
                            if (!url.includes('checkLogin')) {
                                // EventBus.$emit('notify', 'red', undefined, err.response.data.message);
                            }
                            reject(err);
                        }).finally(() => {
                            // store.commit('setLoading', false);
                        });
                    });
                } catch (err) {
                    // EventBus.$emit('notify', 'red', undefined, 'خطایی رخ داده است');
                    // store.commit('setLoading', false);
                }
            }
        },

        created() {
            if (this.isImage(this.msg.attachment.extension)) {
                this.downloadFile();
            }
        }
    }
</script>

<style scoped>

</style>