<template>
    <PackageComponent
            :myPack="pack"
            @addPackage="editPackage"
            :sending="sending"
            :closable="true"
            :title="'ویرایش بسته'"
            :submitTitle="'ویرایش'"
            @close="$emit('close')"
    ></PackageComponent>
</template>

<script>
    import PackageComponent from '@/components/Admin/packages/PackageComponent';
    import {EventBus} from "../../../event-bus";

    export default {
        props: ['packProp'],
        data() {
            return {
                pack: {
                    session_count: null,
                    title: null,
                    en_title: null,
                    payment_unit: null,
                    payment_amount: null,
                    multi_currency: null,
                    doctor_id: null,
                    prices: null,
                    type:null
                },
                sending: false,
            }
        },
        components: {
            PackageComponent
        },
        created() {

            let doctorId = this.packProp.doctor != null ? this.packProp.doctor.id : null;
            this.pack = {
                session_count: this.packProp.session_count,
                multi_currency: this.packProp.multi_currency,
                title: this.packProp.title,
                en_title: this.packProp.en_title,
                payment_unit: null,
                type:this.packProp.type,
                payment_amount: null,
                doctor_id: doctorId,
                conditions:this.packProp.conditions.map(item => item.id),
              prices: [...this.packProp.prices]
            }
          console.log(this.pack)
        },

        methods: {
            editPackage(pack) {
                this.sending = true;
                console.log('editing', pack);
                this.$store.dispatch('AdminEditPackages', {id: this.packProp.id, payload: pack}).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                    this.$emit('done');
                }).finally(() => {
                    this.sending = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>