<template>
  <v-container>
    <v-card class="my-8">
      <v-card-title ref="adminReports">
        گزارش پیش‌مشاوره
        <v-spacer></v-spacer>
        <TableOptions :hasExport="true" :searchFilters="searchFilters"
                      @applyFilters="applyFilters"></TableOptions>
      </v-card-title>
      <v-card-text>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="users"
            mobile-breakpoint="300"
        >
          <template v-slot:item.name="{ item }">
            <v-chip :to="{name:'clientDetail',params:{clientId:item.id}}" style="cursor: pointer;">
              {{ item.name }} {{ item.family }}
            </v-chip>
          </template>
          <template v-slot:item.refer="{ item }">
            {{item.refer.slice(0,25)}}
          </template>
          <template v-slot:item.country_name="{ item }">
            <template v-if="item.country_name">
              {{ item.country_name }}
            </template>
            <template v-else>
              <template v-if="item.country">
                {{ item.country.name }}
              </template>
            </template>
          </template>

          <template v-slot:item.familiar_by="{ item }">
            <template v-if="item.hasOwnProperty('document') && item.document">
              <template v-if="item.document.hasOwnProperty('familiar_by')">
                {{ item.document.familiar_by }}
              </template>
            </template>
            <template v-else>
              {{ item.familiar_by }}
            </template>
          </template>

          <template v-slot:item.pre_session_date="{ item }">
            <template v-if="item.pre_session_date">
              {{ item.pre_session_date }}
            </template>
            <!--            <template v-else>-->
            <!--              {{item.document.pre_session_date | dateFormat}}-->
            <!--            </template>-->
          </template>
          <template v-slot:item.pre_session_status="{ item }">
            <template v-if="item.pre_session_status == 'used'">
              برگزار شده
            </template>
            <template v-else-if="item.pre_session_status == 'canceled'">
              لغو‌شده
            </template>
            <template v-else-if="item.pre_session_status == 'date_fixed'">
              در انتظار برگزاری
            </template>
            <template v-else>
              {{ item.pre_session_status }}
            </template>
          </template>
          <template v-slot:item.register_method="{ item }">
            <template v-if="item.from_presession_landing">
              لندینگ پیش‌مشاوره
            </template>
            <template v-else-if="item.from_psytest">
              تست‌ها
            </template>
            <template v-else>
              پنل
            </template>
          </template>
          <template v-slot:item.document.birth_date="{ item }">
            <template v-if="item.document.birth_date">
              {{ calculateAge(item.document.birth_date) }}
            </template>
          </template>
          <template v-slot:item.document.gender="{ item }">
            <template v-if="item.document.gender == 'male'">
              مرد
            </template>
            <template v-else-if="item.document.gender == 'female'">
              زن
            </template>
            <template v-else>
              {{ item.document.gender }}
            </template>
          </template>
          <template v-slot:item.pre_session_agent="{ item }">
            <template v-if="item.pre_session_agent">
              {{ item.pre_session_agent.name }} {{ item.pre_session_agent.family }}
            </template>
          </template>
          <template v-slot:item.purchased_after="{ item }">
            <template v-if="item.purchased_after">
              <v-chip color="success">
                خرید بعد از جلسه
              </v-chip>
            </template>
            <template v-else>
              <template v-if="item.package_count == 0">
                <v-chip color="accent">
                  بدون خرید
                </v-chip>
              </template>
              <template v-else>
                <v-chip color="warning">
                  خرید قبل از جلسه
                </v-chip>
              </template>
            </template>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn class="my-2 elevation-1" color="primary" @click="downloadReport(item)" fab small
                   v-if="!isDownloading(item)">
              <v-icon small>fas fa-download</v-icon>
            </v-btn>
            <div style="min-width:50px" v-else>

              <v-progress-linear
                  v-model="getDownloading(item).value"
                  query
              ></v-progress-linear>

            </div>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-pagination
          v-model="page"
          :length="total"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
/*eslint-disable*/
import FileDownload from 'js-file-download';
import Axios from 'axios';
import {EventBus} from "@/event-bus";
import moment from "moment-jalaali";
import TableOptions from "@/components/TableOptions";
import {
  pre_session_report
} from "@/searchFilters";

export default {
  name: "UserReport",
  components: {TableOptions},
  created() {

  },
  props: {
    getReportAction: {
      default: 'adminSepidarExports'
    },
    title: {
      default: 'گزارشات مالی'
    },
    type: {
      default: 'payment'
    }
  },

  data() {
    return {
      searchFilters: pre_session_report,
      ToModal: false,
      FromModal: false,

      reportInfo: {},

      downloading: [],

      requesting: false,

      users: [],

      headers: [
        {
          text: 'نام کاربر',
          align: 'start',
          sortable: true,
          value: 'name',
        }, {
          text: 'تاریخ ثبت‌نام',
          align: 'center',
          sortable: true,
          value: 'created_at',
        }, {
          text: 'تاریخ جلسه',
          align: 'center',
          sortable: true,
          value: 'pre_session_date',
        }, {
          text: 'وضعیت برگزاری جلسه',
          align: 'center',
          sortable: true,
          value: 'pre_session_status',
        },
        {
          text: 'تاریخ اولین ‌خرید',
          align: 'center',
          sortable: true,
          value: 'first_purchase',
        },
        {
          text: 'نحوه ثبت‌نام',
          align: 'center',
          sortable: true,
          value: 'register_method',
        },
        {
          text: 'وضعیت‌خرید',
          align: 'center',
          sortable: true,
          value: 'purchased_after',

        },
        {
          text: 'مسئول جلسه',
          align: 'center',
          sortable: true,
          value: 'pre_session_agent',
        },
        {
          text: 'کشور',
          align: 'center',
          sortable: false,
          value: 'country_name',
        },
        // {
        //   text: 'سن',
        //   align: 'center',
        //   sortable: false,
        //   value: 'document.birth_date',
        // },
        // {
        //   text: 'جنسبت',
        //   align: 'center',
        //   sortable: false,
        //   value: 'document.gender',
        // },
        {
          text: 'نحوه آشنایی',
          align: 'center',
          sortable: false,
          value: 'familiar_by',
        },
        {
          text: 'از',
          align: 'center',
          sortable: false,
          value: 'refer',
        },
        {
          text: 'تعداد خرید',
          align: 'center',
          sortable: false,
          value: 'package_count',
        },
      ],
      reports: [],
      page: 1,
      total: 1,
      filters: null,
    }
  },
  methods: {
    applyFilters(filters) {
      this.page = 1
      this.filters = filters
      this.getAdminReports(filters)
    },

    calculateAge(birthdate) {
      // Parse the birthdate using Moment.js
      var birthdateMoment = moment(birthdate, 'YYYY-MM-DD');

      // Calculate the difference in years between the birthdate and the current date
      var age = moment().diff(birthdateMoment, 'years');

      return age;
    },
    getDownloading(item) {
      return this.downloading.find(downloadItem => downloadItem.id == item.id);
    },
    removeFromDownloading(item) {
      this.downloading.splice(this.downloading.indexOf(item), 1)
      // let item = this.downloading.find(item => item.id == id);
      // if (item != undefined) {
      //
      // }
    },
    isDownloading(item) {
      let hasItem = this.downloading.find(downloadItem => downloadItem.id == item.id)
      if (hasItem != undefined) {
        return true;
      }
      return false;
    },
    getAdminReports(filter) {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getPreSessionReport', {
        page: this.page,
        filters: filter
      }).then((resp) => {
        this.total = resp.data.pagination.total_pages;
        this.users = resp.data.users;
        // this.loading = false;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })

    },

    downloadReport(item) {
      let x = this.downloading.push({
        id: item.id,
        state: true,
        value: 0
      });

      this.ajaxDownload('https://api.simiaroom.com/api/v1/admin/reports/' + item.id, item.name, this.downloading[x - 1]);
    },

  },
  watch: {
    page() {
      this.getAdminReports(this.filters);
      this.$vuetify.goTo(this.$refs.adminReports.offsetTop);
    }
  },
  filters: {
    dateFormat(date) {
      if (!date) return null
      return moment(date).format('YYYY-MM-DD')
    }
  }
}

</script>

<style scoped>

</style>