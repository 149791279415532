<template>
  <div>
    <div class="text-left" v-if="$vuetify.breakpoint.smAndDown">
      <div class="d-flex">
        <v-btn color="primary" small @click="returnBackSm">
          <v-icon>mdi-arrow-right</v-icon>
          بازگشت
        </v-btn>
      </div>
    </div>
    <v-fade-transition :mode="'out-in'">
      <UserProfileSectionCRM key="1" :client-profile="clientProfile" v-if="clientProfile != null"/>
      <v-skeleton-loader
          v-else
          key="2"
          class="mx-auto"
          width="98%"
          height="260px"
          type="image"
      ></v-skeleton-loader >
    </v-fade-transition>

    <v-card-text>
      <v-tabs
          v-model="tabs"
          fixed-tabs
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
            href="#task"
            class="primary--text"
        >
          <v-icon>mdi-tag-multiple</v-icon>
          <p class="mt-4 px-3 text--secondary">تسک‌ها</p>
        </v-tab>
        <v-tab
            href="#request-session"
            class="primary--text"
        >
          <v-icon>fas fa-tv</v-icon>
          <p class="mt-4 px-3 text--secondary">درخواست جلسه</p>
        </v-tab>
        <v-tab
            href="#preferences"
            class="primary--text"
        >
          <v-icon>mdi-format-list-checks</v-icon>
          <p class="mt-4 px-3 text--secondary">ترجیحات</p>
        </v-tab>

        <v-tab
            href="#mobile-tabs-5-2"
            class="primary--text"
        >
          <v-icon>fas fa-list-alt</v-icon>
          <p class="mt-4 px-3 text--secondary">جلسات</p>
        </v-tab>

        <v-tab
            href="#mobile-tabs-5-3"
            class="primary--text"
        >
          <v-icon>fas fa-money-bill</v-icon>
          <p class="mt-4 px-3 text--secondary">بسته‌ها</p>
        </v-tab>


        <v-tab
            href="#questions"
            class="primary--text"
        >
          <v-icon>fas fa-question</v-icon>
          <p class="mt-4 px-3 text--secondary">سوالات و تست ها</p>
        </v-tab>

        <v-tab
            href="#conversations"
            class="primary--text"
        >
          <v-icon>fas fa-tv</v-icon>
          <p class="mt-4 px-3 text--secondary">گفتگو ها</p>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item
            :value="'task'"
        >
          <v-card flat class="pb-12">
            <v-window v-model="windowTask">
              <v-window-item :value="1">
                <TaskListCRM @changeWindow="changeWindow($event)"/>
              </v-window-item>
              <v-window-item :value="2">
                <template v-if="windowTask == 2">
                  <TaskLoadArea :taskInfo="taskinfo" :client-profile="clientProfile"/>
                </template>
              </v-window-item>
            </v-window>
          </v-card>
        </v-tab-item>
        <v-tab-item
            :value="'mobile-tabs-5-2'"
        >
          <AdminClientSessionTabs></AdminClientSessionTabs>
        </v-tab-item>
        <v-tab-item
            :value="'mobile-tabs-5-3'"
        >
          <v-card flat class="pb-12">
            <AdminOrders></AdminOrders>
          </v-card>
        </v-tab-item>
        <v-tab-item
            :value="'request-session'"
        >
          <template v-if="tabs == 'request-session'">
            <v-card flat class="pb-12">
              <AdminClientCycles
                  :customer="$route.params.clientId" :profile="profile"
                  :getProfile="false"
              ></AdminClientCycles>
            </v-card>
          </template>
        </v-tab-item>
        <v-tab-item
            :value="'conversations'"
        >
          <AdminClientMessenger :clientId="$route.params.clientId" :mode="'admin-client'"></AdminClientMessenger>

        </v-tab-item>


        <v-tab-item
            :value="'questions'"
        >

          <AdminQuestions/>
        </v-tab-item>

        <v-tab-item
            :value="'preferences'"
        >
          <AdminClientSurvey/>

        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-dialog v-model="doDateDialog" max-width="500" persistent>
      <AddDoDateCRM @DoDateDialogHandler="DialogHandler($event)" :taskinfo="taskinfo"/>
    </v-dialog>

  </div>
</template>

<script>
/*eslint-disable*/
// import {mapGetters} from "vuex";
import UserProfileSectionCRM from "@/views/admin/crm/sectionManager/UserProfileSectionCRM";
import AdminClientSessionTabs from "@/components/sessions/AdminClientSessionTabs";
import AdminClientSurvey from "@/components/Admin/user/AdminClientSurvey";
import AdminOrders from "@/components/orders/AdminClientPayments";
import AdminQuestions from "@/components/Admin/questions/AdminQuestions";
import AdminClientCycles from "@/views/admin/AdminClientCycles";
import AdminClientMessenger from '@/components/chat/AdminClientMessenger'
import TaskListCRM from "@/views/admin/crm/sectionManager/taskListCRM";
import {EventBus} from "@/event-bus";
import TaskLoadArea from "@/views/admin/crm/sectionManager/taskLoadArea";
import AddDoDateCRM from "@/components/crm/addDoDateCRM";


export default {
  components: {
    TaskLoadArea,
    TaskListCRM,
    AdminClientCycles,
    AdminQuestions, AdminOrders, AdminClientSessionTabs, UserProfileSectionCRM, AdminClientSurvey,
    AdminClientMessenger,
    AddDoDateCRM
  },
  // computed: {
  //   ...mapGetters({
  //     profile: 'getClientProfile'
  //   })
  // },
  data() {
    return {
      profile: [],
      tabs: 0,
      windowTask: 1,
      taskinfo: null,
      clientProfile: null,
      doDateDialog:false
    }
  },
  methods: {
    DialogHandler(val) {
      switch (val.target) {
        case 'NoteDialogHandler':
          this.noteDialog = val.status
          break;
        case 'DoDateDialogHandler':
          this.doDateDialog = val.status;
          break;
      }
    },
    getClientInfo() {
      this.clientProfile = null
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getUserProfile', {
        userid: this.$route.params.clientId
      }).then((resp) => {
        this.clientProfile = resp.data.client
        this.newProfile = resp.data.client
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    changeWindow(taskInfo) {
      this.taskinfo = taskInfo
      this.windowTask = 2
      this.$router.push({name: 'CrmUserInfo', query: {taskId: taskInfo.id}})
    },
    returnWindow() {
      this.windowTask = 1
      this.taskinfo = null
      this.$router.push({name: 'CrmUserInfo'})
    },
    updateClientInfo() {
      this.getClientInfo();
    },
    returnBackSm() {
      EventBus.$emit('returnBackSm');
    },
    doDateDialogHandler(){
      // EventBus.$emit('doDateDialog',true)
      this.doDateDialog  = true
    }
  },
  mounted() {

    this.getClientInfo()

    EventBus.$on('updateClientInfo', () => {
      this.getClientInfo();
    })
    EventBus.$on('returnWindow', () => {
      this.returnWindow()
      EventBus.$emit('getTaskList')
    })
    // EventBus.$emit('toggleDrawer', false)


    EventBus.$on('doDateDialog',(val) => {
      this.doDateDialog = val
    })
  },
  beforeDestroy() {
    EventBus.$off('updateClientInfo'),
    EventBus.$off('returnWindow')
    EventBus.$off('doDateDialog')
  },
  watch: {
    '$route.params.clientId'() {
      this.tabs = 0;
      this.getClientInfo()
    }
  }
}
</script>

<style scoped>

</style>