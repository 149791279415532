<template>
  <div>
    <v-container fluid class="px-lg-10 mt-10">
      <v-card>
        <v-card-title>کاربران جدید</v-card-title>
        <v-card-text>
            <template v-if="clients != null">
              <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="clients"
                  mobile-breakpoint="300"
              >
                <template v-slot:item.checkBox="{ item }">
                  <v-checkbox v-model="seletedUser" :value="item.id"></v-checkbox>
                </template>
<!--                <template v-slot:item.full_name="{ item }">-->
<!--                  <div style="position: relative;">-->
<!--                    <div v-if="item.leadUser">-->
<!--                      <a target="_blank" :href="'clients/'+item.id" class="lead-badge"-->
<!--                         style="text-decoration: none">lead</a>-->
<!--                    </div>-->
<!--                    <div v-if="item.newUser">-->
<!--                      <a target="_blank" :href="'clients/'+item.id" class="new-user-badge"-->
<!--                         style="text-decoration: none">new</a>-->
<!--                    </div>-->
<!--                    <div v-if="item.doctorReferralsCount > 0">-->
<!--                      <div target="_blank" :href="'clients/'+item.id" class="referral-badge"-->
<!--                           style="text-decoration: none">refer-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <v-chip :href="'clients/'+item.id">-->
<!--                    <a @click.prevent="getClientProfile(item.id)" :href="'clients/'+item.id"-->
<!--                       style="text-decoration: none">{{ item.full_name }}</a>-->
<!--                  </v-chip>-->

<!--                </template>-->
<!--                <template v-slot:item.action="{ item }">-->
<!--                  <v-btn class="my-2" color="primary" @click="getClientProfile(item.id)" fab small>-->
<!--                    <v-icon small>fas fa-user-alt</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
                <template v-slot:item.created_at="{ item }">
                  <div style="text-align: center;direction: ltr;">{{ item.created_at | toJalali}}</div>
                </template>
              </v-data-table>
              <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
              ></v-pagination>
            </template>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addSalesStuff" elevation="0">افزودن</v-btn>
        </v-card-actions>
      </v-card>
      <br>
    </v-container>
  </div>
</template>

<script>
import {adminClients} from "@/searchFilters";
import {EventBus} from "@/event-bus";
import moment from "moment-jalaali";

export default {
  data() {
    return {
      pagination:{
        page:1,
        total:1
      },
      searchFilters: [],
      seletedUser:[],
      headers: [
        {
          value: 'checkBox',
        },
        {
          text: 'نام و نام خانوادگی',
          align: 'center',
          sortable: true,
          value: 'full_name',
        },
        {
          text: 'ایمیل',
          align: 'center',
          sortable: false,
          value: 'email',
        }, {
          text: 'تلفن',
          align: 'center',
          sortable: false,
          value: 'mobile',
        }, {
          text: 'تاریخ ثبت نام',
          align: 'center',
          sortable: false,
          value: 'created_at',
        },

      ],
      clients: null,
      options: null,
      total: null,
    }
  },
  created() {
    this.searchFilters = adminClients;
  },

  methods: {
    applyFilters() {
    },
    getClients() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getListSalesStuff',this.pagination.page).then((data) => {
        this.clients = data.data.users.data;
        this.pagination = {
          page:data.data.users.current_page,
          total: data.data.users.last_page
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })

    },
    getClientProfile() {

    },
    addSalesStuff() {
      if (this.seletedUser.length > 0){
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('selectSalesStuff', {
          user_id: this.seletedUser
        }).then(() => {
          this.seletedUser = []
          this.getClients();
          EventBus.$emit('notify', 'green', undefined, 'کاربران به لیست شما اضافه شدند');
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }else{
        EventBus.$emit('notify', 'red', undefined, 'حداقل یک کاربر را انتخاب کنید');
      }
    }
  },
  mounted() {
    this.getClients()
  },
  filters:{
    toJalali(date) {
      return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
    },
  },
  watch:{
    'pagination.page'(){
      window.scrollTo({
        top:0
      })
      this.getClients()
    }
  }
}
</script>

<style scoped>
*{
  scroll-behavior: smooth;
}
.lead-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(0, 0, 230, 1);
  color: white;
  border-radius: 5px;
  z-index: 10;
}

.referral-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  left: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(0, 0, 150, 1);
  color: white;
  border-radius: 5px;
  z-index: 10;
}

.new-user-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(230, 0, 0, 1);
  color: white;
  border-radius: 5px;
  z-index: 10;
}
</style>