<template>
    <div>
<!--        <counselor-profile v-if="userHasRole('counselor')"></counselor-profile>-->
        <admin-profile ></admin-profile>
<!--        <client-profile v-else-if="userHasRole('customer')"></client-profile>-->
    </div>
</template>

<script>

    // import counselorProfile from '@/components/profiles/counselorProfile.vue';
    import adminProfile from '@/components/profiles/adminProfile.vue';
    // import clientProfile from '@/components/profiles/clientProfile.vue';

    export default {
        components: {
            // counselorProfile,
            adminProfile,
            // clientProfile
        },
        methods: {
            userHasRole(role) {
                return this.$store.getters.userHasRole(role);
            }
        }
    }
</script>

<style scoped>

</style>
