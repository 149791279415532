<template>
  <div>
    <v-card height="100%" class=" pa-0">
      <v-card-title class="secondary--text font-weight-bold">گزارشات</v-card-title>
      <v-divider></v-divider>
      <v-row class="pa-2" justify="center">
        <v-col cols="6" sm="4">
          <v-card class="pa-1 pa-md-5" to="sessionrequests">
            <v-row>
              <v-col cols="8" class="subtitle-2">
                <span>درخواست های جلسات</span>
              </v-col>
              <v-col cols="4">
                <v-chip class="px-2">
                  {{ dashboard.sessionRequests }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4">
          <v-card class="pa-1 pa-md-5" to="nocycle">
            <v-row>
              <v-col cols="8" class="subtitle-2">
                <span>تعیین مشاور نشده</span>
              </v-col>
              <v-col cols="4">
                <v-chip class="px-2">
                  {{ dashboard.notCycle }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4">
          <v-card class="pa-1 pa-md-5" to="notime">
            <v-row>
              <v-col cols="8" class="subtitle-2">
                <span>تعیین وقت نشده</span>
              </v-col>
              <v-col cols="4">
                <v-chip class="px-2">
                  {{ dashboard.notTimeBook }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="6" sm="4">
          <v-card class="pa-1 pa-md-5" :to="'sessions/' + `${today}`">
            <v-row>
              <v-col cols="8" class="subtitle-2">
                <span>جلسات روز</span>
              </v-col>
              <v-col cols="4">
                <v-chip class="px-2">
                  {{ dashboard.todaySessionsCount }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4">
          <v-card class="pa-1 pa-md-5" :to="{name: 'requests'}">
            <v-row>
              <v-col cols="8" class="subtitle-2">
                <span>درخواست ها</span>
              </v-col>
              <v-col cols="4">
                <v-chip class="px-2">
                  {{ dashboard.ticketsCount }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      dashboard: {
        usersCount: '',
        totalSession: '',
        allDoctorsCount: '',
        todaySales: [],
        totalSales: [],
        combinedSales: [],
        // doctors:null
      },
      doctors: [],

    }
  },
  getMyDoctor() {
    this.$store.dispatch('getAccountManagersDashboard').then((resp) => {
      this.doctors = resp.data.doctors
      console.log(this.doctors)
    })
  },
  created() {
    this.getMyDoctor()
    EventBus.$emit('setLoading', true);
    this.$store.dispatch('getAdminDashboard').then((resp) => {

      this.$store.commit('setAdminDashboard', resp.data)
      this.dashboard = resp.data;
      this.dashboard.todaySales = resp.data.today_sales;
      this.dashboard.combinedSales = resp.data.combined;


      // this.dashboard.todaySales = [
      //     {value: resp.data.todayDollarAmount.toFixed(2), title: 'دلار'},
      //     {value: Number(resp.data.todayRialAmountPayment).toLocaleString(), title: 'تومان'}];
      //
      //
      // this.dashboard.totalSales = [
      //     {value: resp.data.dollarAmount, title: 'دلار'},
      //     {value: Number(resp.data.rialAmountPayment).toLocaleString(), title: 'تومان'}
      // ];

    }).finally(() => {
      EventBus.$emit('setLoading', false);
    })
  },
}
</script>

<style scoped>

</style>