<template>
    <v-container fluid>
        <admin-clients ></admin-clients>
<!--        <counselor-clients v-else-if="$store.getters.userHasRole('counselor')"></counselor-clients>-->
    </v-container>
</template>

<script>
    import AdminClients from '@/components/clients/AdminClients.vue'
    // import CounselorClients from '@/components/clients/CounselorClients.vue'

    export default {
        components: {
            AdminClients,
            // CounselorClients,
        }
    }
</script>

<style scoped>

</style>
