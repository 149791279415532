<template>
  <div>
    <div v-if="voiceUrl">
      <audio controls style="width: 100%;min-width: 250px">
        <source :src="voiceUrl" type="audio/ogg">
      </audio>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "VoiceTelegramLoader",
  props: {
    message_id: Number,
    file_type: String,
  },
  data() {
    return {
      voiceUrl: null,
      baseUrl: process.env.VUE_APP_BASE_URL,

    }
  },
  methods: {
    getFileContent() {
      Axios({
        method: 'POST',
        url: this.baseUrl + '/admin/telegram/load-telegram-file',
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: {
          file_message_id: this.message_id
        },
        responseType: 'blob',
      }).then((resp) => {
        let blob = new Blob([resp.data], {type: 'audio/oga'}); // Adjust the type as per your image type

        // Create a URL for the Blob object
        this.voiceUrl = URL.createObjectURL(blob);

      })
    },
  },
  mounted() {
    this.getFileContent()
  }
}
</script>

<style scoped>

</style>