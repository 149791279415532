<template>
  <div class="fill-height">
    <v-fade-transition>
      <router-view></router-view>
    </v-fade-transition>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>