<template>
  <div>
    <v-container fluid>
      <v-card class="my-8">
        <v-dialog v-model="assignDoctorModal" width="500px">
          <v-card class="pa-4">
            <v-card-text>
              <AssignDoctorCRM v-if="assignDoctorModal" @callback="getAdminUsers" :userId="selectedUserId"
                               @closeModal="()=> assignDoctorModal = false"/>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="detachDoctorModal" width="600px">
          <v-card class="pa-4" v-if="doctorToRemove!=null">
            <v-card-title>
              حذف مشاور از کاربر
            </v-card-title>
            <v-divider/>
            <v-card-text class="font-weight-bold pt-3" style="font-size: 1.1em;">
              از حذف دکتر {{ doctorToRemove.doctorFullName }} برای کاربر {{ doctorToRemove.userFullName }} اطمینان
              دارید؟
            </v-card-text>
            <v-card-actions>
              <v-btn color="red" elevation="0" class="white--text" @click="detachDoctor">
                حذف تخصیص
              </v-btn>
              <v-spacer/>
              <v-btn text class="white--text grey" @click="detachDoctorModal = false">
                لغو
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="stopSessionModal" width="500px">
          <v-card>
            <v-card-title>
              علت لفو مسیر درمانی
            </v-card-title>
            <v-card-text>
              <v-form ref="stopSession">
                <v-select
                    :rules="requiredRules"
                    :items="stopReasons"
                    v-model="stopSession.reason"
                    item-value="key"
                    label="علت لغو مسیر درمان"
                    item-text="title"
                    dense
                >
                </v-select>
                <v-textarea
                    :rules="requiredRules"
                    label="توضیحات"
                    v-model="stopSession.desc"
                    v-if="stopSession.reason == 'other'"
                    outlined
                ></v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="stopSessionCycle">
                ثبت
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title ref="adminClients">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="checkRoleAndPermissions('admin.user.create')" class="mt-5"
                 :to="{name: 'addNewClient'}">افزودن مراجع جدید
          </v-btn>
          <!--<v-spacer></v-spacer>-->
          <!--<v-text-field-->
          <!--solo-->
          <!--v-model="search"-->
          <!--append-icon="mdi-magnify"-->
          <!--label="جستجو"-->
          <!--single-line-->
          <!--hide-details-->
          <!--&gt;</v-text-field>-->
        </v-card-title>
        <v-card-text>
          <TableOptions
              v-if="rerender"
              :hasExport="true"
              :searchFilters="searchFilters"
              @applyFilters="applyFilters"
              :hasSepidarExport="true"
              :custom="'user-join'"
              :custom-query-parameter="{page:1}"
              :action-required-permission="{
                              download:'admin.export.clients.default',
                              downloadClient:'admin.export.clients.customers',
                              downloadSepidar:'admin.export.clients.sepidar',
                            }"
          ></TableOptions>
        </v-card-text>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="clients"
            :search="search"
            mobile-breakpoint="300"
        >
          <template v-slot:item.id="{item}">
            <div style="cursor:pointer;" @click="copyToClipboard(item.id)">{{item.id}}</div>
          </template>
          <template v-slot:item.assign_doctor="{item}">

            <div class="d-flex">
              <div class="flex-grow-1">
                <template v-if="item.assignedDoctors.length != 0">
                  <v-chip close @click:close="openDetachCounselorModal(item)">
                    {{ item.assignedDoctors[0].doctor.user.full_name }}
                  </v-chip>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:item.full_name="{ item }">
            <div style="position: relative;">
              <div v-if="item.leadUser">
                <a target="_blank" :href="'clients/'+item.id" class="lead-badge"
                   style="text-decoration: none">lead</a>
              </div>
              <div v-if="item.newUser">
                <a target="_blank" :href="'clients/'+item.id" class="new-user-badge"
                   style="text-decoration: none">new</a>
              </div>
              <div v-if="item.doctorReferralsCount > 0">
                <div target="_blank" :href="'clients/'+item.id" class="referral-badge"
                     style="text-decoration: none">refer
                </div>
              </div>
            </div>
            <v-chip :href="'clients/'+item.id">
              <a @click.prevent="getClientProfile(item.id)" :href="'clients/'+item.id"
                 style="text-decoration: none">{{ item.full_name }}</a>
            </v-chip>

          </template>
          <template v-slot:item.action="{ item }">
            <!--            <v-btn class="my-2" color="primary" @click="getClientProfile(item.id)" fab small>-->
            <!--              <v-icon small>fas fa-user-alt</v-icon>-->
            <!--            </v-btn>-->
            <div>
              <!--              <v-btn color="primary" v-if="checkRoleAndPermissions('admin.assign_doctor')" fab x-small elevation="0"-->
              <!--                     @click="openModalAssignDoctor(item.id)">-->
              <!--                <v-icon>-->
              <!--                  mdi-doctor-->
              <!--                </v-icon>-->
              <!--              </v-btn>-->
              <!--              <v-btn class="mr-1" color="primary"-->
              <!--                     v-if="checkRoleAndPermissions('admin.detach_doctor') && item.assignedDoctors.length" fab-->
              <!--                     x-small-->
              <!--                     elevation="0"-->
              <!--                     @click="openModalStopSession(item,item.id)">-->
              <!--                <v-icon>-->
              <!--                  mdi-cancel-->
              <!--                </v-icon>-->
              <!--              </v-btn>-->

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      fab
                      x-small
                      elevation="0"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item class="pa-0"  v-if="checkRoleAndPermissions('admin.assign_doctor')">
                    <v-list-item-title class="px-2">
                      <div
                          class="menuItem"
                          @click="openModalAssignDoctor(item.id)"

                      >
                        تخصیص دکتر
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="pa-0"   v-if="checkRoleAndPermissions('admin.detach_doctor') && item.assignedDoctors.length">
                    <v-list-item-title class="px-2">
                      <div
                          class="menuItem"
                          @click="openModalStopSession(item,item.id)"

                      >
                        توقف مسیر درمان
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="px-2">
                      <router-link :to="{name:'clientHistory',params:{clientId:item.id}}" style="color: inherit;text-decoration: none">
                        <div
                            class="menuItem"
                        >
                          تاریخچه جور‌سازی
                        </div>
                      </router-link>

                    </v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>

            </div>
          </template>
          <template v-slot:item.cel="{ item }">
            <div style="direction: ltr">
              {{ item.cel != null ? item.cel.replace(/ /g, "") : '' }}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div style="text-align: center;direction: ltr;">{{ item.created_at }}</div>
          </template>
          <template v-slot:item.familiar_by="{item}">
            {{ item.familiar_by }}
            <template v-if="item.familiar_by == 'other'">
              - {{ item.familiar_by_meta }}
            </template>
          </template>
          <template v-slot:item.psytest_register="{item}">

                        <span v-if="item.psytest_register">
                          <v-chip class="blue white--text" x-small>PsyTest</v-chip>
                        </span>

          </template>
          <template v-slot:item.register_from="{item}">
            <v-chip v-if="item.register_from" class="blue white--text" x-small>{{ item.register_from }}</v-chip>
          </template>
        </v-data-table>
      </v-card>
      <div class="text-center" v-if="!fiveSession">
        <v-pagination
            @input="handlerPagination(getAdminUsers)"
            v-model="page"
            :length="total"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from 'vuex'
import {
  adminClients,
} from "../../searchFilters";
import TableOptions from '@/components/TableOptions.vue';
import {EventBus} from "@/event-bus";
import moment from "moment-jalaali";
import AssignDoctorCRM from "@/views/admin/crm/task/assignDoctorCRM";
import UrlQueryMixin from "@/Mixin/UrlQueryMixin";
import login from "@/views/Login";
/*eslint-disable*/
export default {
  mixins: [UrlQueryMixin],
  components: {
    AssignDoctorCRM,
    TableOptions
  },
  props: {
    // title: {
    //     default: 'مراجعان',
    //     type: String
    // },
    fiveSession: Boolean
  },
  filters: {
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD HH:mm").format('jYYYY/jMM/jDD HH:mm');
      }
      return date;
    },
  },

  data() {
    return {
      stopReasons: [
        {
          title: "عدم پاسخ دهی مشتری",
          key: 'client_not_answering'
        },
        {
          title: "پایان درمان توسط مشتری",
          key: 'client_end_of_therapy'
        },
        {
          title: "عدم مشارکت شریک مشتری",
          key: 'client_partner_not_participating'
        },
        {
          title: "مشکلات مالی و عدم بیمه",
          key: 'financial_problem'
        },
        {
          title: "ناراضی بودن مشتری از روانشناس",
          key: 'client_dissatisfied_by_therapist'
        },
        {
          title: "مسائل شخصی مشتری",
          key: 'client_personal_issues'
        },
        {
          title: "ادامه مشاوره حضوری",
          key: 'client_continuing_in_person'
        },
        {
          title: 'موارد دیگر',
          key: 'other'
        }
      ],
      stopSession: {
        reason: null,
        desc: null,
      },
      doctors: [],
      rerender: false,
      selectedUserId: null,
      assignDoctorModal: false,
      detachDoctorModal: false,
      doctorToRemove: null,
      searchFilters: [],
      options: {},
      requesting: false,
      title: 'مراجعان',
      path: this.$router.currentRoute.name,
      // loading: true,
      search: '',
      headers: [
        {
          text: 'کد کاربر',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'نام و نام خانوادگی',
          align: 'start',
          sortable: true,
          value: 'full_name',
        }, {
          text: 'ایمیل',
          align: 'start',
          sortable: false,
          value: 'email',
        }, {
          text: 'تلفن',
          align: 'start',
          sortable: false,
          value: 'cel',
        }, {
          text: 'جلسات خریداری شده',
          align: 'start',
          sortable: false,
          value: 'userPackageDetails.all',
        }, {
          text: 'جلسات برگزار شده',
          align: 'start',
          sortable: false,
          value: 'userPackageDetails.used',
        }, {
          text: 'جلسات باقی مانده',
          align: 'start',
          sortable: false,
          value: 'userPackageDetails.available',
        }, {
          text: 'درخواست ها',
          align: 'start',
          sortable: false,
          value: 'userPackageDetails.request',
        }, {
          text: 'روش آشنایی',
          align: 'start',
          sortable: false,
          value: 'familiar_by',
        }, {
          text: 'کاربر سیستم تست',
          align: 'center',
          sortable: true,
          value: 'psytest_register',
        },
        {
          text: 'دکتر مربوطه',
          align: 'center',
          sortable: true,
          value: 'assign_doctor',
        }
        , {
          text: 'تاریخ ثبت نام',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'از',
          align: 'start',
          sortable: true,
          value: 'refer',
        },
        {
          text: 'متا',
          align: 'start',
          sortable: true,
          value: 'register_from',
        },
        {
          text: 'عملیات',
          align: 'start',
          sortable: true,
          value: 'action',
        },
      ],
      desserts: [],
      clients: [],
      page: 1,
      total: 1,
      stopSessionModal: false,
    }
  },
  computed: {
    ...mapGetters({
      // clients: 'getAdminUsers',
      allDoctors: 'getAdminAllDoctors'

      // page: 'page',
      // total: 'total',
    }),
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },

  },
  created() {
    this.fillDoctors();
    this.fillPageState()

    // this.searchFilters = adminClients;

    // this.getAdminUsers();
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
          .then(() => {
            EventBus.$emit('notify', 'green', undefined, 'کد کاربر کپی شد');

          })
          .catch(err => {
            console.error('Error copying text to clipboard: ', err);
          });
    },
    stopSessionCycle() {
      if (this.$refs.stopSession.validate()) {
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('adminDetachDoctorFromUser', {
          doctor_id: this.doctorToRemove.doctorId,
          user_id: this.doctorToRemove.userId,
          detach_reason: this.stopSession.reason,
          description: this.stopSession.desc
        }).then(() => {
          this.getAdminUsers()
          EventBus.$emit('notify', 'green', undefined, 'ثبت شد');
        }).finally(() => {
          this.stopSessionModal = false
          EventBus.$emit('setLoading', false)
        })
      }

    },
    detachDoctor() {
      this.$store.dispatch('adminDetachDoctorFromUser', {
        doctor_id: this.doctorToRemove.doctorId,
        user_id: this.doctorToRemove.userId,
      }).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'تخصیص دکتر با موفقیت لغو گردید.');
        this.detachDoctorModal = false;
        this.doctorToRemove = null;
        this.getAdminUsers();
      })
    },
    openDetachCounselorModal(item) {
      this.detachDoctorModal = true;
      this.doctorToRemove = {
        userFullName: item.full_name,
        userId: item.id,
        doctorFullName: item.assignedDoctors[0].doctor.user.full_name,
        doctorId: item.assignedDoctors[0].doctor_id,
      };
    },
    fillDoctors() {
      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {

        let mappedDoctors = this.doctors.map((doctor) => {
          return {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
        });
        adminClients.find((item) => {
          if (item.key == 'doctor_ids') {
            item.options = mappedDoctors;
          }
        });
        this.searchFilters = adminClients;
        this.rerender = true;
      });
    },

    openModalAssignDoctor(userId) {
      this.selectedUserId = userId
      this.assignDoctorModal = true
    },
    openModalStopSession(item, userID) {
      this.selectedUserId = userID
      this.stopSessionModal = true
      this.doctorToRemove = {
        userFullName: item.full_name,
        userId: item.id,
        doctorId: item.assignedDoctors.length != 0 ? item.assignedDoctors[0].doctor_id : null,
      };
    },
    applyFilters(options) {
      this.resetPaginationInApplyFilterIfNeeded(options)
      this.options = options;
      this.getAdminUsers();
    },
    getAdminUsers() {
      if (this.path == 'clients') {
        // this.loading = true;
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('getClientsList', {
          page: this.getPageFromUrl(), options: {
            type: 'patient',
            ...this.options
          }
        }).then((data) => {
          if (data != undefined) {
            this.total = data.data.pagination.total_pages;
            this.clients = data.data.users;
          }

        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      } else if (this.path == 'nocycle') {
        // this.loading = true;
        // let options = {
        //     session_state: "session_requested",
        //     ...this.options
        // };
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('getClientsList', {
          page: this.page, options: {
            session_state: "session_requested",
            ...this.options
          }
        }).then((data) => {
          this.total = data.data.pagination.total_pages;
          this.clients = data.data.users;
          // this.setAdminClients(data);
          // this.loading = false;
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      } else if (this.path == 'notime') {
        // this.loading = true;
        this.title = 'تعیین وقت نشده ها';
        // let options = {
        //     "type": "patient",
        //     "time_not_set": true
        // };
        this.$store.dispatch('getClientsList', {
          page: this.page, options: {
            "type": "patient",
            "time_not_set": true,
            ...this.options
          }
        }).then((data) => {

          this.total = data.data.pagination.total_pages;
          this.clients = data.data.users;
          // this.loading = false;
        })
      } else if (this.path == 'notconfirmtime') {
        // this.loading = true;
        this.title = 'تایید زمان نشده ها',
            EventBus.$emit('setLoading', true);
        this.$store.dispatch('getNotconfirmtimeUsers', {page: this.page}).then((data) => {
          console.log('clients: ', data);
          this.total = data.pagination.total_pages;
          this.setAdminClients(data);
          // this.loading = false;
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }
    },

    getClientProfile(id) {
      this.$router.push('clients/' + `${id}`);
    },
    setAdminClients(data) {
      this.clients = [];
      data.data.forEach(client => {
        client['fullname'] = client.firstname + ' ' + client.lastname;
        this.clients.push(client);
      });
    },

  },
  watch: {
    page() {

    },
    stopSessionModal() {
      this.$refs.stopSession.reset()
    }
  }
}
</script>

<style scoped>

.menuItem {
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s;
}

.menuItem:hover {
  background: #dddddd;
  transition: all 0.3s;
}

.lead-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(0, 0, 230, 1);
  color: white;
  border-radius: 5px;
  z-index: 10;
}

.referral-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  left: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(0, 0, 150, 1);
  color: white;
  border-radius: 5px;
  z-index: 10;
}

.new-user-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(230, 0, 0, 1);
  color: white;
  border-radius: 5px;
  z-index: 10;
}
</style>
