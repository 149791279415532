<template>
    <v-container>
<!--        <client-start-session v-if="$store.getters.userHasRole('customer')"></client-start-session>-->
<!--        <counselor-start-session v-if="$store.getters.userHasRole('counselor')"></counselor-start-session>-->
    </v-container>
</template>

<script>
    // import ClientStartSession from "../../components/start-session/ClientStartSession";
    // import CounselorStartSession from "../../components/start-session/CounselorStartSession"

    export default {
        name: 'StartSession',
        components: {
            // ClientStartSession,
            // CounselorStartSession
        }
    };
</script>


