<template>
  <div class="mx-5 mt-5">
    <v-container fluid>
      <v-dialog v-model="modalAssignDoctor" width="500px">
        <v-card>
          <v-card-title>
            افزودن دکتر به کاربر
          </v-card-title>
          <v-card-text>
            <v-form ref="attachFrom">
              <v-autocomplete v-model="mentalDisorderGroup" label="گروه بیماری" :items="mentalDisorderGroups" dense
                              outlined></v-autocomplete>
              <v-autocomplete outlined dense label="بیماری" :items="mentalDisorders" v-model="mentalDisorderId"
                              item-text="title"
                              item-value="id"></v-autocomplete>
              <v-autocomplete outlined dense label="دکتر‌ها" :items="doctors" v-model="doctorId"
                              item-text="doctor.user.full_name" item-value="doctor.id"></v-autocomplete>
              <v-textarea v-model="description" label="توضیحات" outlined></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" elevation="0" @click="assignDoctor">
              افزودن
            </v-btn>
            <v-btn text color="primary" @click="modalAssignDoctor = false">
              لغو
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card class="pa-3">
        <v-card-title>
          تخصیص دکتر به کاربر
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
              hide-default-footer
              v-if="users"
              :headers="headers"
              :items="users"
              class=""
          >
            <template v-slot:item.action="{item}">
              <v-btn color="primary" fab x-small elevation="0" @click="openModalAssignDoctor(item)">
                <v-icon>mdi-sticker</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination class="mt-5" :length="pagination.total" v-model="pagination.page"></v-pagination>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      headers: [
        {
          text: 'کد',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'نام کاربر',
          align: 'start',
          sortable: false,
          value: 'full_name',
        },
        {
          text: 'تاریخ عضویت',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      pagination: {
        total: 1,
        page: 1,
      },
      mentalDisorderGroups: ['individual', 'couple', 'family', 'child'],
      mentalDisorderGroup: "",
      users: null,
      description: null,
      modalAssignDoctor: false,
      mentalDisorders: "",
      targetUser: null,
      doctors: "",
      mentalDisorderId: null,
      doctorId: null,
    }
  },
  methods: {
    openModalAssignDoctor(user) {
      this.modalAssignDoctor = true
      this.targetUser = user
    },
    getClients() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getClientsList', {
        page: this.pagination.page, options: {
          new_customer: true
        }
      }).then((resp) => {
        this.users = resp.data.users
        this.pagination = {
          total: resp.data.pagination.total_pages,
          page: resp.data.pagination.current_page
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    getMentalDisorders() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getMentalDisorders', {disorder_group: this.mentalDisorderGroup}).then((resp) => {
        this.mentalDisorders = resp.data
        console.log(this.mentalDisorders)
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    getMentalDisordersDoctor() {
      EventBus.$emit('setLoading', true)
      this.doctors = null
      this.doctorId = null
      this.$store.dispatch('getMentalDisordersDoctor', {id: this.mentalDisorderId}).then((resp) => {
        this.doctors = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    assignDoctor() {
      if (this.targetUser.id && this.doctorId && this.mentalDisorderId) {
        let option = {
          "user_id": this.targetUser.id,
          "doctor_id": this.doctorId,
          "description": this.description,
          "mental_disorder_id": this.mentalDisorderId
        }
        this.$store.dispatch('attachDoctor', option).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'دکتر به کاربر اضافه شد');
        }).then(() => {
          this.mentalDisorderId = null
          this.mentalDisorders = null
          this.doctorId = null
          this.doctors = null
          this.mentalDisorderGroup = null
          this.description = null
          this.modalAssignDoctor = false
          this.getClients()
        })
      }
    }
  },
  mounted() {
    this.getClients()

    // this.getMentalDisorders()
  },
  watch: {
    mentalDisorderId(val) {
      if (val) {
        this.getMentalDisordersDoctor()
      }
    },
    mentalDisorderGroup(val) {
      if (val) {
        this.mentalDisorderId = null
        this.mentalDisorders = null
        this.doctorId = null
        this.doctors = null
        this.getMentalDisorders()
      }
    },
    'pagination.page'() {
      this.getClients()
    }
  }
}
</script>

<style scoped>

</style>