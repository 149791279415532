<template>
  <div>
    <v-dialog v-model="editCouponDialog" v-if="itemToEdit != null" scrollable max-width="900">
      <v-card class="elevation-0">
        <v-card-text>
          <AdminEditCoupon :coupon="itemToEdit" @close="cancelEditing" @done="doneEditing"></AdminEditCoupon>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog" max-width="400">
      <v-card v-if="itemToEdit != null">
        <v-card-title>
          <template v-if="itemToEdit.state == 'available'">
            غیرفعال سازی کد تخفیف
          </template>
          <template v-else>
            فعالسازی کد تخفیف
          </template>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-5">
          آیا نسبت به عملیات مورد نظر اطمینان دارید؟
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error elevation-0" text @click="showDialog = false">
            خیر
          </v-btn>
          <v-btn class="primary elevation-0" text @click="toggleCoupon">
            بله
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card class="my-8">
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn v-if="checkRoleAndPermissions('admin.coupons.add')" color="primary" class="mt-5"
                 :to="{name: 'addNewCoupons'}">افزودن کد جدید
          </v-btn>
        </v-card-title>
        <v-card-text>
          <TableOptions :custom-query-parameter="{page:1}" :hasExport="true" :searchFilters="searchFilters"
                        @applyFilters="applyFilters"></TableOptions>
        </v-card-text>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="requests"
            :search="search"
        >
          <template v-slot:item.doctor="{item}">
            <template v-if="item.doctor!=null">
              {{ item.doctor.full_name }}
            </template>
          </template>
          <template v-slot:item.user="{item}">
            <template v-if="item.user!=null">
              {{ item.user.full_name }}
            </template>
          </template>
          <template v-slot:item.conditions="{item}">
            <template v-for="condition in item.conditions">
              <v-tooltip bottom :key="condition.id" small>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2" v-on="on"
                          v-bind="attrs">
                    {{ condition.type | toIcon }}
                  </v-icon>
                </template>
                <span>{{ condition.type | tooltip }}</span>
              </v-tooltip>
            </template>
            <!--<template v-if="item.package!=null">-->
            <!--{{item.package.title}}-->
            <!--</template>-->
          </template>
          <template v-slot:item.state="{item}">
            {{ item.state == 'available' ? 'فعال' : 'غیر فعال' }}
          </template>
          <template v-slot:item.discount_type="{item}">
            {{ item.discount_type | resolveCouponType }}
          </template>

          <template v-slot:item.max_usage="{item}">
            {{ (item.max_usage) == 0 ? 'نامحدود' : item.max_usage }}
          </template>

          <template v-slot:item.exp_date="{item}">
            {{ (item.exp_date) == null ? 'همیشگی' : item.exp_date }}
          </template>
          <template v-slot:item.action="{item}">
            <template v-if="checkRoleAndPermissions('admin.coupons.toggle')">
              <template v-if="item.state =='inactive'">
                <v-btn small fab text @click="showToggleDialog(item)">
                  <v-icon class="blue--text"> fas fa-eye</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn small fab text @click="showToggleDialog(item)">
                  <v-icon class="red--text"> fas fa-eye-slash</v-icon>
                </v-btn>
              </template>
            </template>
            <v-btn fab x-small text class="primary" @click="openEditDialog(item)"
                   v-if="checkRoleAndPermissions('admin.coupons.edit')">
              <v-icon small>fas fa-edit</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-card>
      <div class="text-center" v-if="!fiveSession">
        <v-pagination
            @input="handlerPagination(getAdminCoupons)"
            v-model="page"
            :length="total"
        ></v-pagination>
      </div>
    </v-container>
    <!--<v-row v-if="request != null">-->
    <!--<v-dialog-->
    <!--v-model="dialog"-->
    <!--max-width="450"-->
    <!--&gt;-->
    <!--<v-card class="d-flex flex-column justify-center align-center">-->
    <!--<v-card-title>درخواست {{ request.type }} از {{ request.user.full_name }}</v-card-title>-->

    <!--<v-card-text>-->
    <!--{{ request.description }}-->
    <!--</v-card-text>-->

    <!--<v-card-actions>-->
    <!--&lt;!&ndash;                        <v-spacer></v-spacer>&ndash;&gt;-->

    <!--<v-btn-->
    <!--dark-->
    <!--color="red darken-1"-->
    <!--@click="dialog = false"-->
    <!--&gt;-->
    <!--بستن-->
    <!--</v-btn>-->

    <!--<v-btn-->
    <!--dark-->
    <!--color="green darken-1"-->
    <!--@click="requestUpdate"-->
    <!--:loading="loading"-->
    <!--&gt;-->
    <!--تایید-->
    <!--</v-btn>-->
    <!--</v-card-actions>-->
    <!--</v-card>-->
    <!--</v-dialog>-->
    <!--</v-row>-->
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from 'vuex';
import {adminCouponFilters} from "../../searchFilters";
import TableOptions from '@/components/TableOptions.vue';
import AdminEditCoupon from '@/components/Admin/coupons/AdminEditCoupon.vue';
import {EventBus} from "../../event-bus";
import UrlQueryMixin from "@/Mixin/UrlQueryMixin";

export default {
  mixins: [UrlQueryMixin],
  components: {
    TableOptions,
    AdminEditCoupon
  },
  name: 'requests',
  props: {
    title: {
      default: 'کدهای تخفیف',
      type: String
    },
    fiveSession: Boolean
  },
  data() {

    return {
      showDialog: false,
      itemToEdit: null,
      editCouponDialog: false,
      options: {},
      requesting: false,
      searchFilters: [],
      total: 1,
      page: 1,
      loading: false,
      loadingBtn: false,
      dialog: false,
      search: '',
      headers: [
        //         amount_irr: 10000,
        // amount_usd: "100.00",
        // code: "7091880",
        // discount_type: "fixed",
        // doctor: null,
        // exp_date: "1387/02/26",
        // id: 1,
        // max_usage: 1,
        // package: null,
        // state: "available",
        // usage: 0,
        // user:'',
        {text: 'کد', value: 'code'},
        {text: 'مبلغ دلاری', value: 'amount_usd'},
        {text: 'مبلغ به تومان', value: 'amount_irr'},
        {text: 'نوع تخفیف', value: 'discount_type'},
        {text: 'دکتر', value: 'doctor'},
        {text: 'مراجع', value: 'user'},
        {text: 'شرایط', value: 'conditions'},
        {text: 'حداکثر استفاده', value: 'max_usage'},
        {text: 'تعداد استفاده', value: 'usage'},
        {text: 'تاریخ انقضا', value: 'exp_date'},
        {text: 'وضعیت', value: 'state'},
        {text: 'عملیات', value: 'action'},
      ],
      desserts: [],
      request: null,
      requests: []
    }
  },
  computed: {
    ...mapGetters({
      // requests: 'getAdminCoupons',
      // page: 'page',
      // total: 'total',
    })
  },
  created() {
    // this.getAdminCoupons();
    this.searchFilters = adminCouponFilters;
    this.fillPageState()
  },
  methods: {
    showToggleDialog(item) {
      this.itemToEdit = item;
      this.showDialog = true;
    },
    toggleCoupon() {
      let id = this.itemToEdit.id;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('adminToggleCoupon', id).then((resp) => {
        console.log(resp);
        this.showDialog = false;
        this.itemToEdit = null;
        this.getAdminCoupons();
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت انجام شد');

      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    doneEditing() {
      this.itemToEdit = null;
      this.editCouponDialog = false;
      this.getAdminCoupons()
    },
    cancelEditing() {
      this.itemToEdit = null;
      this.editCouponDialog = false;
    },
    openEditDialog(item) {
      this.itemToEdit = item;
      this.editCouponDialog = true;
    },
    applyFilters(options) {
      this.resetPaginationInApplyFilterIfNeeded(options)
      this.options = options;
      this.getAdminCoupons();
    },
    getAdminCoupons() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getAdminCoupons', {page: this.page, options: this.options}).then((data) => {
        this.requests = data.data.coupons;
        this.total = data.data.pagination.total_pages;

      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    // nextPage() {
    //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
    //     console.log('page', this.page)
    // },
    // previousPage() {
    //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
    //     console.log('page', this.page)
    // },
    // specificPage(page) {
    //     this.$store.commit('SET_SPECIFIC_PAGE', page);
    //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
    //     console.log('page', page)
    // },
    requestDetail(item) {
      this.dialog = true;
      this.request = item;
      // console.log('request', this.request)
    },
    requestUpdate() {
      this.loading = true;
      this.$store.dispatch('updateTicketState', this.request.id).then(() => {
        this.dialog = false;
        this.requests.splice(this.requests.indexOf(this.request), 1);
      }).finally(() => {
        this.loading = false;
      })

    }
  },
  watch: {
    editCouponDialog(newVal) {
      if (!newVal) {
        this.itemToEdit = null;
      }
    },
    page() {


      // this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
    }
  },
  filters: {
    resolveCouponType(input) {
      return input == 'fixed' ? 'ثابت' : 'درصدی';
    },
    tooltip(input) {
      let tooltip = '';
      switch (input) {
        case 'nopayments' :
          tooltip = 'پرداخت اول';
          break;
        case 'limitedpackages':
          tooltip = 'چند بسته ای';
          break;
      }
      return tooltip;
    },
    toIcon(input) {

      let icon = '';

      switch (input) {
        case 'nopayments' :
          icon = 'fas fa-gift';
          break;
        case 'limitedpackages':
          icon = 'fas fa-cubes';
          break;
      }
      return icon;
    }
  }
}


</script>

<style scoped>

</style>
