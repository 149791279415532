/*eslint-disable*/
import {ajaxGet, ajaxPost} from "@/utilities";
import moment from "moment-jalaali";

const state = {
    calendars: null,
    events: [],
}

const getters = {
    getCalenders: state => state.calendars,
    getEventsCalender: state => state.events
}

const actions = {
    getCalendars({rootState, commit}, payload) {
        return ajaxGet(rootState.baseUrl + "/admin/googlecalendar/list/local/pairs").then((resp) => {
            commit('SET_CALENDERS', resp.data)
        })
    },
    getEventsCalendar({rootState, commit}, payload) {
        let maxTime = moment(new Date()).add('1', 'years').format('YYYY-MM-DD\THH:mm:ss')
        return ajaxGet(rootState.baseUrl + `/admin/googlecalendar/event/list?calendar_id=${payload.calendar_id}&time_max=${maxTime}`).then((resp) => {
            commit('SET_EVENTS', resp.data)
        })
    },
    storeFreeTime({rootState, commit}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/googlecalendar/event`, payload)
    },
    attachTimeToUserRecurrence({rootState, commit}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/googlecalendar/events/recurring/reserved/create', payload)
    },
    detachUserFromTime({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + payload.url, payload.data)
    },
    deleteEvent({rootState}, payload) {
        console.log(payload)
        return ajaxPost(rootState.baseUrl + '/admin' + payload.url, payload.data)
    },
    makeSessionForEvent({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/googlecalendar/event/session/create', payload)
    },
    deleteSession({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/googlecalendar/events/session/delete', payload)
    },
    attachTimeToUserSingle({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/googlecalendar/events/reserved/create', payload)
    },
    doneSessionCalender({state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/googlecalendar/event/session/used', payload)
    },
}

const mutations = {
    SET_CALENDERS(state, payload) {
        state.calendars = payload
    },
    SET_EVENTS(state, payload) {
        state.events = []
        state.events.unshift(...payload)
    }
}


export default {state, getters, mutations, actions}