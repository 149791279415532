<template>
  <div>
    <v-container class="mt-10">
      <v-card>
        <v-card-title>
          کاربران
        </v-card-title>
        <v-card-text>
          <v-data-table disable-pagination hide-default-footer :headers="headers" :items="users" v-if="users">
            <template v-slot:item.assigned_doctors.created_at="{item}">
              {{ item.assigned_doctors.created_at | dateFilter }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import momentJalali from "moment-jalaali";
import {EventBus} from "@/event-bus";
/*eslint-disable*/
export default {
  data() {
    return {
      headers: [
        {
          text: 'کد ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {text: 'نام و نام خانوادگی', value: 'full_name', align: 'center'},
        {text: 'ایمیل', value: 'email', align: 'center'},
        {text: 'تاریخ تخصیص به دکتر ', value: 'assigned_doctors.created_at', align: 'center'},
        {text: 'تعداد باقی ماندی جلسات ', value: 'wallet.global_packages', align: 'center'},
      ],
      users: null,
    }
  },
  mounted() {
    this.getDoctorUsers()
  },
  methods: {
    getDoctorUsers() {
      EventBus.$emit('setLoading', true)
      return this.$store.dispatch('getUserDoctorList', {
        doctorId: this.$route.params.counselorId
      }).then((resp) => {
        this.users = resp.data.users
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  filters: {
    dateFilter(val) {
      if (!val) {
        return;
      }
      return momentJalali(val).format('jYYYY/jMM/jDD')
    }
  }
}
</script>

<style scoped>

</style>