<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="justify-space-between">
          <div>
            یادداشت‌های دکتر
          </div>
          <div>
            <v-btn small color="primary" elevation="0"
                   @click="notesWindow == 'notes' ? notesWindow = 'addNotes' : notesWindow = 'notes'">
              <template v-if="notesWindow == 'notes'">
                افزودن یادداشت
              </template>
              <template v-else>
                یادداشت‌ها
              </template>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-window v-model="notesWindow">
            <v-window-item value="notes">
              <div class="doctorNotes">
                <div v-if="notes.length != 0">
                  <div v-for="(note , i) in notes" :key="i" class="noteBox mb-5">
                    <div class="d-flex justify-space-between">
                      <div class="dateNote pt-1">
                        {{ note.created_at | dateFilter }}
                      </div>
                      <div class="accountManagerNote">
                        {{ note.account_manager.full_name }}
                        <template v-if="profile.id == note.account_manager.id ">
                          <v-btn x-small fab elevation="0" text @click="deleteNote(note.id)">
                            <v-icon size="15" color="red">mdi-delete</v-icon>
                          </v-btn>
                          <v-btn x-small fab elevation="0" text @click="openModalEditNote(note.id,note.description)">
                            <v-icon size="15" color="black">mdi-pencil</v-icon>
                          </v-btn>

                        </template>
                      </div>
                    </div>
                    <div class="noteContent" mt-2>
                      {{ note.description }}
                    </div>
                  </div>
                  <div class="text-center">
                    <v-btn color="primary" class="mb-2"
                           :to="{name:'counselorProfile',params:{counselorId:doctor_profile_id}}">
                      یادداشت‌های بیشتر
                    </v-btn>
                  </div>
                </div>
                <div v-else class="text-center">
                  یادداشتی وجود ندارد
                </div>

              </div>
            </v-window-item>
            <v-window-item value="addNotes">
              <div class="addNotesArea d-flex flex-column justify-space-between">
                <v-form ref="addNoteRef">
                  <v-textarea v-model="note" label="یادداشت" outlined :rules="requiredRules"></v-textarea>
                </v-form>
                <div>
                  <v-btn color="primary" @click="addNote" v-if="!target_note">
                    افزودن
                  </v-btn>
                  <v-btn color="primary" @click="editNote" v-else>
                    ویرایش
                  </v-btn>
                </div>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import momentJalali from "moment-jalaali";
import {mapGetters} from "vuex";

export default {
  props: {
    modal: Boolean,
    user_id: Number,
    doctor_profile_id: Number,
  },
  data() {
    return {
      notesWindow: 'notes',
      note: null,
      notes: [],
      dialog: false,
      target_note: null
    }
  },

  methods: {
    openModalEditNote(id, text) {
      this.notesWindow = 'addNotes'
      this.note = text
      this.target_note = id
    },
    editNote() {
      if (this.$refs.addNoteRef.validate()) {
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('editNoteClient', {
          "description": this.note,
          note_id: this.target_note
        }).then(() => {
          this.target_note = null
          this.$refs.addNoteRef.reset()
          this.notesWindow = 'notes'
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.getNotes()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    getNotes() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getNotesClient', {
        clientId: this.user_id,
      }).then((resp) => {
        this.notes = resp.data.user_crm_note.data
      }).finally(() => {
        EventBus.$emit('setLoading', false,)
      })
    },
    deleteNote(id) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('deleteNote', id).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به حذف شد');
        this.getNotes()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    addNote() {
      if (this.$refs.addNoteRef.validate()) {
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('addNoteClient', {
          description: this.note,
          user_id: this.user_id,
        }).then(() => {
          this.$refs.addNoteRef.reset()
          EventBus.$emit('setLoading', false)
          this.getNotes()
          this.notesWindow = 'notes'
          EventBus.$emit('notify', 'green', undefined, 'یادداشت اضافه شد');
        }).catch(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getUserInfo'
    }),
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  mounted() {
    this.dialog = this.modal
    this.getNotes()
  },
  watch: {
    'dialog'(val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  filters: {
    dateFilter(val) {
      if (val) {
        return momentJalali(val).format('jYYYY/jMM/jDD HH:mm')
      }
    }
  }
}
</script>

<style scoped>
.noteBox {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 20px;
}

.accountManagerNote {
  font-size: 12px;
}

.dateNote {
  font-size: 12px;
}

.doctorNotes {
  height: 300px;
  overflow: auto;
}

.addNotesArea {
  height: 300px;
}

.noteContent {
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 5px;
}
</style>