<template>
  <div>
    <AdminSessions mode="requestedSessions" location="crm" @updateWindow="updateWindow($event)"></AdminSessions>
  </div>
</template>

<script>
import AdminSessions from "@/components/sessions/AdminSessions";
export default {
  components: {AdminSessions}
}
</script>

<style scoped>

</style>