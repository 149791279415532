const messages = {
    en: {
        $vuetify: {
            calendar:{
                moreEvents:'13121'
            },
            dataIterator: {
                rowsPerPageText: 'Items per page:',
                pageText: '{0}-{1} of {2}',
            },
            emailLabel: 'Email',
            requiredInputRule: 'Required',
            passwordLabel: 'Password',
            phoneLabel: 'Phone',
            passwordConfirmationLabel: 'Confirm Password',
            emailSignUpCaption: 'This is the email you will use to login to your Simiaroom account',
            passwordSignUpCaption: 'Please enter a password for your account',
            signUpLabel: "Sign Up",
            loginLabel: 'Login',
            loginOrSignup: 'Login | Sign Up',
            nextLabel: 'Next',
            backLabel: 'Back',
            accountRecoveryCaption: 'Enter Your Email To Recover Your Account',
            accountRecovery: 'Recover your account',
            forgotPasswordText: 'Forgot Password?',
            setAPassword: 'Set a Password',
            navDashboard: 'Dashboard',
            navSessionsLabel: 'Sessions',
            navClientsLabel: 'Clients',
            navCounselorsLabel: "Counselors",
            closeDialogLabel: 'Close',
            userStepOne: 'Buy Package',
            userStepTwo: 'Enter Information',
            userStepThree: 'Submit Problem',
            userStepFour: 'Submit Times',
            userOrders: "Orders",
            navProducts: 'products',
            navCalendar: 'Calendar',
            navََََََََََََََAssignConsult: 'Assign Consult',
            navHomeLabel: 'Home',
            navAboutUsLabel: 'About us',
            navLoginSignupLabel: 'Login | Sign up',
            profileInformationTitle: 'Basic Information',
            profileFirstname: 'Name',
            profileLastname: 'Last name',
            profileGender: 'Gender',
            profileCivilStatus: 'Civil Status',
            profileEmail: 'Email',
            profileMobile: 'Mobile',
            profilePhone: 'Phone',
            profileBirthDate: 'Birth Date',
            profileAge: 'Age',
            profileEdu: 'Education',
            profileKnway: 'How did you hear about us',
            profileKnwayOther: 'How...?',
            profileTZ: 'Time Zone',
            profileLocationTitle: 'Location Information',
            profileTimeTitle: 'Time Information',
            profileCountry: 'Country',
            profileCity: 'City',
            profileJob: 'Job (optional)',
            profileExplanation: 'Explanation',
            profileSupportTitle: 'َََAdminَََ',
            profileSpeciality: 'Speciality',
            profileResume: 'Resume',
            timezones: 'Timezone',
            searchLabel: 'Search',
            dateLabel: 'Date',
            timeLabel: 'Time',
            productLabel: 'Product',
            priceLabel: 'Price',
            nextSessionLabel: 'Next Session',
            sessionsListLabel: 'Sessions List',
            consultTopicLabel: 'Consult Topic',
            problemStatementLabel: 'explain your problem...',

            // form error msg
            emailErrRequired: 'Email is required',
            emailErrValid: 'E-mail must be valid',
            passwordErrRequired: 'Password is required',
            fillIsRequired: 'Required',


            saveSuccessfullyMsg: 'Successfully Saved',
            saveErrorMsg: 'Something went wrong!',
            warningSelectDateMsg: 'Select your date, Please.',
            err500: 'Please check your internet connection or try again later',
            falseUsePass: 'Incorrect Username or Password',
            checkEmail: 'A verification link has been sent to your email account',
            notReservable: 'این زمان قابل رزرو نمی باشد',
            noTimeChosen: 'هیچ زمانی انتخاب نشده است',
            chooseCounselor: 'لطفا ابتدا مشاور را انتخاب نمایید',
            alertChangeTZ: 'شما در حال تغییر زمان محلی خود می باشید. دقت داشته باشید تمامی زمانهای جلسات بر اساس این زمان محلی نمایش داده میشود',
            packagesLZero: 'برای ثبت جلسه بیشتر، ابتدا بسته خریداری نمایید',
            dataTable: {
                itemsPerPageText: 'ایتم در صفحه',
                sortBy: 'Sort by'
            },

            noDataText: 'No data text',

            emptyCart: 'Your cart is empty!',
            errorCode_mobile: 'موبایل',
            errorCode_name: '',
            errorCode_family: '',
            errorCode_birth_date: ''

        },
    },

    fa: {
        $vuetify: {
            calendar:{
                moreEvents:'نمایش بیشتر'
            },
            timePicker: {
                am: "am",
                pm: "pm"
            },
            dataIterator: {
                rowsPerPageText: 'گزینه در صفحه:',
                pageText: '{0}-{1} از {2}',
            },
            emailLabel: 'ایمیل',
            requiredInputRule: 'الزامی',
            passwordLabel: 'کلمه عبور',
            phoneLabel: 'تلفن همراه',
            passwordConfirmationLabel: 'تکرار کلمه عبور',
            emailSignUpCaption: 'از این ایمیل برای ورود به حساب کاربری سیمیاروم خود استفاده خواهید کرد.',
            passwordSignUpCaption: 'لطفا برای حساب خود یک کلمه عبور وارد نمایید',
            signUpLabel: "ثبت نام",
            loginLabel: 'ورود',
            loginOrSignup: 'ورود | ثبت نام',
            nextLabel: 'ادامه',
            backLabel: 'بازگشت',
            accountRecoveryCaption: 'برای بازیابی حساب کاربری، ایمیل خود را وارد کنید.',
            accountRecovery: 'بازیابی حساب کاربری',
            forgotPasswordText: 'بازیابی رمز عبور',
            setAPassword: 'کلمه عبور خود را وارد کنید',
            navDashboard: 'داشبورد',
            navSessionsLabel: 'جلسات',
            navClientsLabel: 'مراجعان',
            navCounselorsLabel: "مشاوران",
            closeDialogLabel: 'بستن',
            userStepOne: 'خرید بسته',
            userStepTwo: 'تکمیل اطلاعات',
            userStepThree: 'شرح موضوع مشاوره',
            userStepFour: 'ثبت زمانهای ترجیحی',
            userOrders: "سفارشات",
            navProducts: 'خرید بسته',
            navCalendar: 'تقویم',
            navََََََََََََََAssignConsult: 'جزئیات جلسات مشاوره',
            navHomeLabel: 'خانه',
            navAboutUsLabel: 'درباره ما',
            navLoginSignupLabel: 'ورود | ثبت نام',
            profileInformationTitle: 'اطلاعات شخصی',
            profileFirstname: 'نام',
            profileLastname: 'نام خانوادگی',
            profileGender: 'جنسیت',
            profileCivilStatus: 'وضعیت تاهل',
            profileEmail: 'ایمیل',
            profileMobile: 'تلفن همراه',
            profilePhone: 'تلفن ثابت',
            profileBirthDate: 'تاریخ تولد',
            profileAge: 'سن',
            profileEdu: 'مدرک تحصیلی',
            profileKnway: 'نحوه آشنایی',
            profileKnwayOther: 'از چه طریقی با سیمیا آشنا شدید؟',
            profileTZ: 'محدوده زمانی',
            profileLocationTitle: 'اطلاعات مکانی',
            profileTimeTitle: 'اطلاعات زمانی',
            profileCountry: 'کشور',
            profileCity: 'شهر',
            profileJob: 'شغل (اختیاری)',
            profileExplanation: 'توضیحات',
            profileSupportTitle: 'عنوان پشتیبانی',
            profileSpeciality: 'نوع تخصص',
            profileResume: 'رزومه',
            timezones: 'زمان محلی',
            searchLabel: 'جستجو',
            dateLabel: 'تاریخ',
            timeLabel: 'ساعت',
            productLabel: 'محصول',
            priceLabel: 'قیمت',
            nextSessionLabel: 'جلسه پیش‌رو',
            sessionsListLabel: 'لیست جلسات',
            consultTopicLabel: 'موضوع مشاوره',
            problemStatementLabel: 'شرح مختصر مشکل...',

            // form error msg
            emailErrRequired: 'پر کردن  فیلد ایمیل الزامی است',
            emailErrValid: 'یک ایمیل معتبر وارد کنید',
            passwordErrRequired: 'پر کردن  فیلد پسورد الزامی است',
            fillIsRequired: 'پر کردن این فیلد الزامی است',

            saveSuccessfullyMsg: 'با موفقیت ثبت شد',
            saveErrorMsg: 'متاسفانه خطایی رخ داده است!',
            warningSelectDateMsg: 'لطفاروز مورد نظر خود را انتخاب کنید',
            err500: 'لطفااتصال خود به اینترنت را بررسی نموده، در صورت عدم رفع مشکل با پشتیبانی تماس بگیرید',
            falseUsePass: 'نام کاربری یا کلمه عبور اشتباه است',
            checkEmail: 'ثبت نام با موفقیت انجام شد، جهت تکمیل حساب ایمیل خود را چک کنید',
            notReservable: 'این زمان قابل رزرو نمی باشد',
            noTimeChosen: 'هیچ زمانی انتخاب نشده است',
            chooseCounselor: 'لطفا ابتدا مشاور را انتخاب نمایید',
            alertChangeTZ: 'شما در حال تغییر زمان محلی خود می باشید. دقت داشته باشید تمامی زمانهای جلسات بر اساس این زمان محلی نمایش داده میشود',

            dataTable: {
                itemsPerPageText: 'ایتم در صفحه',
                sortBy: 'مرتب سازی بر اساس'
            },
            dataFooter: {
                pageText: 'صفحه'
            },
            noDataText: 'دیتایی یافت نشد',
            emptyCart: 'سبد خرید شما خالی است!',
            packagesLZero: 'برای ثبت جلسه بیشتر، ابتدا بسته خریداری نمایید',
            errorCode_mobile: 'موبایل',
            errorCode_name: '',
            errorCode_family: '',
            errorCode_birth_date: ''

        },

    },

}

export default messages;