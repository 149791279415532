  <template>
  <div style="position: relative">
    <v-fade-transition>
      <ClientTabProfileAreaCRM class="fill-height"/>
    </v-fade-transition>
    <v-btn
        color="primary"
        fixed
        bottom
        left
        fab
        @click="DialogHandler({
        target:'NoteDialogHandler',
        status:true,
      })"
    >
      <v-icon>mdi-sticker</v-icon>
    </v-btn>
    <v-dialog
        v-model="noteDialog"
        fullscreen
        persistent
        scrollable
        transition="dialog-bottom-transition"
        max-width="300px"
    >
      <NoteDialogCrm v-if="noteDialog" @NoteDialogHandler="DialogHandler($event)"/>
    </v-dialog>

  </div>
</template>

<script>

import ClientTabProfileAreaCRM from "@/views/admin/crm/clientTabProfileAreaCRM";
import NoteDialogCrm from "@/components/crm/note/noteDialogCrm";

export default {
  components: {NoteDialogCrm, ClientTabProfileAreaCRM},
  data() {
    return {
      step: 1,
      noteDialog: false,
      doDateDialog: false,
    }
  },
  methods: {
    changeWindow(window = null) {
      this.step = window == null ? 2 : window
    },
    DialogHandler(val) {
      switch (val.target) {
        case 'NoteDialogHandler':
          this.noteDialog = val.status
          break;
        case 'DoDateDialogHandler':
          this.doDateDialog = val.status;
          break;
      }
    },
  },
  mounted() {
    this.step = 1;

    // EventBus.$on('changeWindow', (window) => {
    //   this.changeWindow(window)
    // })
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>

</style>