<template>
  <div>
    <h4>
      افزودن دکتر
    </h4>
    <v-divider class="my-5"></v-divider>
    <v-form ref="assignFilter">
      <v-autocomplete
          v-model="doctor"
          :items="doctorList"
          item-text="full_name"
          item-value="doctor_profile.id"
          :rules="requiredRules"
      ></v-autocomplete>
      <v-btn color="green" class="white--text font-weight-bold" small elevation="0" @click="assignDoctor">ثبت دکتر
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  props: {
    profile: Object,
    userId: {
      default: null,
    },
    taskId: Number,
  },
  data() {
    return {
      doctor: null,
      doctorList: null,
    }
  },
  mounted() {
    this.getDoctorList()
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  methods: {
    getDoctorList() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getDoctors').then((resp) => {
        this.doctorList = resp.data.doctors
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    assignDoctor() {
      if (this.$refs.assignFilter.validate()) {
        let payload = {
          user_id: this.userId || this.$route.params.clientId,
          doctor_id: this.doctor,
          status: "active"
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('assignDoctor', payload).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.$emit('callback')
          this.doctor = null
        }).finally(() => {
          EventBus.$emit('setLoading', false)
          this.$emit('closeModal')
        })
      }


    },

  }
}
</script>

<style scoped>

</style>