<template>
  <div>
    <v-dialog v-model="modal.visibility" width="500px">
      <v-card>
        <v-card-title class="red font-weight-bold white--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div class="mt-5">
            آیا از حذف این مورد اطمینان دارید؟
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn outlined @click="modal.visibility = false">
            بستن
          </v-btn>
          <v-btn color="red" class="white--text" @click="performingDelete">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modal: Object,
    title: String,
  },
  methods: {
    performingDelete() {
      this.$emit('delete')
    },
  }
}
</script>

<style scoped>

</style>