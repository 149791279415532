<template>
  <div>
    <div v-if="imageUrl">
      <img :src="imageUrl" width="100%" alt="" @click="modal = true">
      <v-dialog width="500px" v-model="modal">
        <v-card>
          <v-card-text class="pt-6" v-if="imageUrl">
            <img :src="imageUrl" width="100%" alt="">
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "ImageTelegramLoader",
  props: {
    message_id: Number,
    file_type: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      imageUrl: null,
      modal: false,
    }
  },
  methods: {
    getFileContent() {
      if (this.file_type == 'jpg') {
        Axios({
          method: 'POST',
          url: this.baseUrl + '/admin/telegram/load-telegram-file',
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          data: {
            file_message_id: this.message_id
          },
          responseType: 'blob',
        }).then((resp) => {
          let blob = new Blob([resp.data], {type: 'image/jpeg'}); // Adjust the type as per your image type

          // Create a URL for the Blob object
          this.imageUrl = URL.createObjectURL(blob);

        })
      }
    },
  },
  mounted() {
    this.getFileContent()
  }
}
</script>

<style scoped>

</style>