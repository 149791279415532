export const countriesCodes = [
    {shortCode: 'AD',title: 'ANDORRA',code: '+376'},
    {shortCode: 'AE',title: 'UNITED ARAB EMIRATES',code: '+971'},
    {shortCode: 'AF',title: 'AFGHANISTAN',code: '+93'},
    {shortCode: 'AG',title: 'ANTIGUA AND BARBUDA',code: '+1268'},
    {shortCode: 'AI',title: 'ANGUILLA',code: '+1264'},
    {shortCode: 'AL',title: 'ALBANIA',code: '+355'},
    {shortCode: 'AM',title: 'ARMENIA',code: '+374'},
    {shortCode: 'AN',title: 'NETHERLANDS ANTILLES',code: '+599'},
    {shortCode: 'AO',title: 'ANGOLA',code: '+244'},
    {shortCode: 'AQ',title: 'ANTARCTICA',code: '+672'},
    {shortCode: 'AR',title: 'ARGENTINA',code: '+54'},
    {shortCode: 'AS',title: 'AMERICAN SAMOA',code: '+1684'},
    {shortCode: 'AT',title: 'AUSTRIA',code: '+43'},
    {shortCode: 'AU',title: 'AUSTRALIA',code: '+61'},
    {shortCode: 'AW',title: 'ARUBA',code: '+297'},
    {shortCode: 'AZ',title: 'AZERBAIJAN',code: '+994'},
    {shortCode: 'BA',title: 'BOSNIA AND HERZEGOVINA',code: '+387'},
    {shortCode: 'BB',title: 'BARBADOS',code: '+1246'},
    {shortCode: 'BD',title: 'BANGLADESH',code: '+880'},
    {shortCode: 'BE',title: 'BELGIUM',code: '+32'},
    {shortCode: 'BF',title: 'BURKINA FASO',code: '+226'},
    {shortCode: 'BG',title: 'BULGARIA',code: '+359'},
    {shortCode: 'BH',title: 'BAHRAIN',code: '+973'},
    {shortCode: 'BI',title: 'BURUNDI',code: '+257'},
    {shortCode: 'BJ',title: 'BENIN',code: '+229'},
    {shortCode: 'BL',title: 'SAINT BARTHELEMY',code: '+590'},
    {shortCode: 'BM',title: 'BERMUDA',code: '+1441'},
    {shortCode: 'BN',title: 'BRUNEI DARUSSALAM',code: '+673'},
    {shortCode: 'BO',title: 'BOLIVIA',code: '+591'},
    {shortCode: 'BR',title: 'BRAZIL',code: '+55'},
    {shortCode: 'BS',title: 'BAHAMAS',code: '+1242'},
    {shortCode: 'BT',title: 'BHUTAN',code: '+975'},
    {shortCode: 'BW',title: 'BOTSWANA',code: '+267'},
    {shortCode: 'BY',title: 'BELARUS',code: '+375'},
    {shortCode: 'BZ',title: 'BELIZE',code: '+501'},
    {shortCode: 'CA',title: 'CANADA',code: '+1'},
    {shortCode: 'CC',title: 'COCOS (KEELING ISLANDS',code: '+61'},
    {shortCode: 'CD',title: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',code: '+243'},
    {shortCode: 'CF',title: 'CENTRAL AFRICAN REPUBLIC',code: '+236'},
    {shortCode: 'CG',title: 'CONGO',code: '+242'},
    {shortCode: 'CH',title: 'SWITZERLAND',code: '+41'},
    {shortCode: 'CI',title: 'COTE D IVOIRE',code: '+225'},
    {shortCode: 'CK',title: 'COOK ISLANDS',code: '+682'},
    {shortCode: 'CL',title: 'CHILE',code: '+56'},
    {shortCode: 'CM',title: 'CAMEROON',code: '+237'},
    {shortCode: 'CN',title: 'CHINA',code: '+86'},
    {shortCode: 'CO',title: 'COLOMBIA',code: '+57'},
    {shortCode: 'CR',title: 'COSTA RICA',code: '+506'},
    {shortCode: 'CU',title: 'CUBA',code: '+53'},
    {shortCode: 'CV',title: 'CAPE VERDE',code: '+238'},
    {shortCode: 'CX',title: 'CHRISTMAS ISLAND',code: '+61'},
    {shortCode: 'CY',title: 'CYPRUS',code: '+357'},
    {shortCode: 'CZ',title: 'CZECH REPUBLIC',code: '+420'},
    {shortCode: 'DE',title: 'GERMANY',code: '+49'},
    {shortCode: 'DJ',title: 'DJIBOUTI',code: '+253'},
    {shortCode: 'DK',title: 'DENMARK',code: '+45'},
    {shortCode: 'DM',title: 'DOMINICA',code: '+1767'},
    {shortCode: 'DO',title: 'DOMINICAN REPUBLIC',code: '+1809'},
    {shortCode: 'DZ',title: 'ALGERIA',code: '+213'},
    {shortCode: 'EC',title: 'ECUADOR',code: '+593'},
    {shortCode: 'EE',title: 'ESTONIA',code: '+372'},
    {shortCode: 'EG',title: 'EGYPT',code: '+20'},
    {shortCode: 'ER',title: 'ERITREA',code: '+291'},
    {shortCode: 'ES',title: 'SPAIN',code: '+34'},
    {shortCode: 'ET',title: 'ETHIOPIA',code: '+251'},
    {shortCode: 'FI',title: 'FINLAND',code: '+358'},
    {shortCode: 'FJ',title: 'FIJI',code: '+679'},
    {shortCode: 'FK',title: 'FALKLAND ISLANDS (MALVINAS',code: '+500'},
    {shortCode: 'FM',title: 'MICRONESIA, FEDERATED STATES OF',code: '+691'},
    {shortCode: 'FO',title: 'FAROE ISLANDS',code: '+298'},
    {shortCode: 'FR',title: 'FRANCE',code: '+33'},
    {shortCode: 'GA',title: 'GABON',code: '+241'},
    {shortCode: 'GB',title: 'UNITED KINGDOM',code: '+44'},
    {shortCode: 'GD',title: 'GRENADA',code: '+1473'},
    {shortCode: 'GE',title: 'GEORGIA',code: '+995'},
    {shortCode: 'GH',title: 'GHANA',code: '+233'},
    {shortCode: 'GI',title: 'GIBRALTAR',code: '+350'},
    {shortCode: 'GL',title: 'GREENLAND',code: '+299'},
    {shortCode: 'GM',title: 'GAMBIA',code: '+220'},
    {shortCode: 'GN',title: 'GUINEA',code: '+224'},
    {shortCode: 'GQ',title: 'EQUATORIAL GUINEA',code: '+240'},
    {shortCode: 'GR',title: 'GREECE',code: '+30'},
    {shortCode: 'GT',title: 'GUATEMALA',code: '+502'},
    {shortCode: 'GU',title: 'GUAM',code: '+1671'},
    {shortCode: 'GW',title: 'GUINEA-BISSAU',code: '+245'},
    {shortCode: 'GY',title: 'GUYANA',code: '+592'},
    {shortCode: 'HK',title: 'HONG KONG',code: '+852'},
    {shortCode: 'HN',title: 'HONDURAS',code: '+504'},
    {shortCode: 'HR',title: 'CROATIA',code: '+385'},
    {shortCode: 'HT',title: 'HAITI',code: '+509'},
    {shortCode: 'HU',title: 'HUNGARY',code: '+36'},
    {shortCode: 'ID',title: 'INDONESIA',code: '+62'},
    {shortCode: 'IE',title: 'IRELAND',code: '+353'},
    {shortCode: 'IM',title: 'ISLE OF MAN',code: '+44'},
    {shortCode: 'IN',title: 'INDIA',code: '+91'},
    {shortCode: 'IQ',title: 'IRAQ',code: '+964'},
    {shortCode: 'IR',title: 'IRAN, ISLAMIC REPUBLIC OF',code: '+98'},
    {shortCode: 'IS',title: 'ICELAND',code: '+354'},
    {shortCode: 'IT',title: 'ITALY',code: '+39'},
    {shortCode: 'JM',title: 'JAMAICA',code: '+1876'},
    {shortCode: 'JO',title: 'JORDAN',code: '+962'},
    {shortCode: 'JP',title: 'JAPAN',code: '+81'},
    {shortCode: 'KE',title: 'KENYA',code: '+254'},
    {shortCode: 'KG',title: 'KYRGYZSTAN',code: '+996'},
    {shortCode: 'KH',title: 'CAMBODIA',code: '+855'},
    {shortCode: 'KI',title: 'KIRIBATI',code: '+686'},
    {shortCode: 'KM',title: 'COMOROS',code: '+269'},
    {shortCode: 'KN',title: 'SAINT KITTS AND NEVIS',code: '+1869'},
    {shortCode: 'KP',title: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF',code: '+850'},
    {shortCode: 'KR',title: 'KOREA REPUBLIC OF',code: '+82'},
    {shortCode: 'KW',title: 'KUWAIT',code: '+965'},
    {shortCode: 'KY',title: 'CAYMAN ISLANDS',code: '+1345'},
    {shortCode: 'KZ',title: 'KAZAKSTAN',code: '+7'},
    {shortCode: 'LA',title: 'LAO PEOPLES DEMOCRATIC REPUBLIC',code: '+856'},
    {shortCode: 'LB',title: 'LEBANON',code: '+961'},
    {shortCode: 'LC',title: 'SAINT LUCIA',code: '+1758'},
    {shortCode: 'LI',title: 'LIECHTENSTEIN',code: '+423'},
    {shortCode: 'LK',title: 'SRI LANKA',code: '+94'},
    {shortCode: 'LR',title: 'LIBERIA',code: '+231'},
    {shortCode: 'LS',title: 'LESOTHO',code: '+266'},
    {shortCode: 'LT',title: 'LITHUANIA',code: '+370'},
    {shortCode: 'LU',title: 'LUXEMBOURG',code: '+352'},
    {shortCode: 'LV',title: 'LATVIA',code: '+371'},
    {shortCode: 'LY',title: 'LIBYAN ARAB JAMAHIRIYA',code: '+218'},
    {shortCode: 'MA',title: 'MOROCCO',code: '+212'},
    {shortCode: 'MC',title: 'MONACO',code: '+377'},
    {shortCode: 'MD',title: 'MOLDOVA, REPUBLIC OF',code: '+373'},
    {shortCode: 'ME',title: 'MONTENEGRO',code: '+382'},
    {shortCode: 'MF',title: 'SAINT MARTIN',code: '+1599'},
    {shortCode: 'MG',title: 'MADAGASCAR',code: '+261'},
    {shortCode: 'MH',title: 'MARSHALL ISLANDS',code: '+692'},
    {shortCode: 'MK',title: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',code: '+389'},
    {shortCode: 'ML',title: 'MALI',code: '+223'},
    {shortCode: 'MM',title: 'MYANMAR',code: '+95'},
    {shortCode: 'MN',title: 'MONGOLIA',code: '+976'},
    {shortCode: 'MO',title: 'MACAU',code: '+853'},
    {shortCode: 'MP',title: 'NORTHERN MARIANA ISLANDS',code: '+1670'},
    {shortCode: 'MR',title: 'MAURITANIA',code: '+222'},
    {shortCode: 'MS',title: 'MONTSERRAT',code: '+1664'},
    {shortCode: 'MT',title: 'MALTA',code: '+356'},
    {shortCode: 'MU',title: 'MAURITIUS',code: '+230'},
    {shortCode: 'MV',title: 'MALDIVES',code: '+960'},
    {shortCode: 'MW',title: 'MALAWI',code: '+265'},
    {shortCode: 'MX',title: 'MEXICO',code: '+52'},
    {shortCode: 'MY',title: 'MALAYSIA',code: '+60'},
    {shortCode: 'MZ',title: 'MOZAMBIQUE',code: '+258'},
    {shortCode: 'NA',title: 'NAMIBIA',code: '+264'},
    {shortCode: 'NC',title: 'NEW CALEDONIA',code: '+687'},
    {shortCode: 'NE',title: 'NIGER',code: '+227'},
    {shortCode: 'NG',title: 'NIGERIA',code: '+234'},
    {shortCode: 'NI',title: 'NICARAGUA',code: '+505'},
    {shortCode: 'NL',title: 'NETHERLANDS',code: '+31'},
    {shortCode: 'NO',title: 'NORWAY',code: '+47'},
    {shortCode: 'NP',title: 'NEPAL',code: '+977'},
    {shortCode: 'NR',title: 'NAURU',code: '+674'},
    {shortCode: 'NU',title: 'NIUE',code: '+683'},
    {shortCode: 'NZ',title: 'NEW ZEALAND',code: '+64'},
    {shortCode: 'OM',title: 'OMAN',code: '+968'},
    {shortCode: 'PA',title: 'PANAMA',code: '+507'},
    {shortCode: 'PE',title: 'PERU',code: '+51'},
    {shortCode: 'PF',title: 'FRENCH POLYNESIA',code: '+689'},
    {shortCode: 'PG',title: 'PAPUA NEW GUINEA',code: '+675'},
    {shortCode: 'PH',title: 'PHILIPPINES',code: '+63'},
    {shortCode: 'PK',title: 'PAKISTAN',code: '+92'},
    {shortCode: 'PL',title: 'POLAND',code: '+48'},
    {shortCode: 'PM',title: 'SAINT PIERRE AND MIQUELON',code: '+508'},
    {shortCode: 'PN',title: 'PITCAIRN',code: '+870'},
    {shortCode: 'PR',title: 'PUERTO RICO',code: '+1'},
    {shortCode: 'PT',title: 'PORTUGAL',code: '+351'},
    {shortCode: 'PW',title: 'PALAU',code: '+680'},
    {shortCode: 'PY',title: 'PARAGUAY',code: '+595'},
    {shortCode: 'QA',title: 'QATAR',code: '+974'},
    {shortCode: 'RO',title: 'ROMANIA',code: '+40'},
    {shortCode: 'RS',title: 'SERBIA',code: '+381'},
    {shortCode: 'RU',title: 'RUSSIAN FEDERATION',code: '+7'},
    {shortCode: 'RW',title: 'RWANDA',code: '+250'},
    {shortCode: 'SA',title: 'SAUDI ARABIA',code: '+966'},
    {shortCode: 'SB',title: 'SOLOMON ISLANDS',code: '+677'},
    {shortCode: 'SC',title: 'SEYCHELLES',code: '+248'},
    {shortCode: 'SD',title: 'SUDAN',code: '+249'},
    {shortCode: 'SE',title: 'SWEDEN',code: '+46'},
    {shortCode: 'SG',title: 'SINGAPORE',code: '+65'},
    {shortCode: 'SH',title: 'SAINT HELENA',code: '+290'},
    {shortCode: 'SI',title: 'SLOVENIA',code: '+386'},
    {shortCode: 'SK',title: 'SLOVAKIA',code: '+421'},
    {shortCode: 'SL',title: 'SIERRA LEONE',code: '+232'},
    {shortCode: 'SM',title: 'SAN MARINO',code: '+378'},
    {shortCode: 'SN',title: 'SENEGAL',code: '+221'},
    {shortCode: 'SO',title: 'SOMALIA',code: '+252'},
    {shortCode: 'SR',title: 'SURINAME',code: '+597'},
    {shortCode: 'ST',title: 'SAO TOME AND PRINCIPE',code: '+239'},
    {shortCode: 'SV',title: 'EL SALVADOR',code: '+503'},
    {shortCode: 'SY',title: 'SYRIAN ARAB REPUBLIC',code: '+963'},
    {shortCode: 'SZ',title: 'SWAZILAND',code: '+268'},
    {shortCode: 'TC',title: 'TURKS AND CAICOS ISLANDS',code: '+1649'},
    {shortCode: 'TD',title: 'CHAD',code: '+235'},
    {shortCode: 'TG',title: 'TOGO',code: '+228'},
    {shortCode: 'TH',title: 'THAILAND',code: '+66'},
    {shortCode: 'TJ',title: 'TAJIKISTAN',code: '+992'},
    {shortCode: 'TK',title: 'TOKELAU',code: '+690'},
    {shortCode: 'TL',title: 'TIMOR-LESTE',code: '+670'},
    {shortCode: 'TM',title: 'TURKMENISTAN',code: '+993'},
    {shortCode: 'TN',title: 'TUNISIA',code: '+216'},
    {shortCode: 'TO',title: 'TONGA',code: '+676'},
    {shortCode: 'TR',title: 'TURKEY',code: '+90'},
    {shortCode: 'TT',title: 'TRINIDAD AND TOBAGO',code: '+1868'},
    {shortCode: 'TV',title: 'TUVALU',code: '+688'},
    {shortCode: 'TW',title: 'TAIWAN, PROVINCE OF CHINA',code: '+886'},
    {shortCode: 'TZ',title: 'TANZANIA, UNITED REPUBLIC OF',code: '+255'},
    {shortCode: 'UA',title: 'UKRAINE',code: '+380'},
    {shortCode: 'UG',title: 'UGANDA',code: '+256'},
    {shortCode: 'US',title: 'UNITED STATES',code: '+1'},
    {shortCode: 'UY',title: 'URUGUAY',code: '+598'},
    {shortCode: 'UZ',title: 'UZBEKISTAN',code: '+998'},
    {shortCode: 'VA',title: 'HOLY SEE (VATICAN CITY STATE',code: '+39'},
    {shortCode: 'VC',title: 'SAINT VINCENT AND THE GRENADINES',code: '+1784'},
    {shortCode: 'VE',title: 'VENEZUELA',code: '+58'},
    {shortCode: 'VG',title: 'VIRGIN ISLANDS, BRITISH',code: '+1284'},
    {shortCode: 'VI',title: 'VIRGIN ISLANDS, U.S.',code: '+1340'},
    {shortCode: 'VN',title: 'VIET NAM',code: '+84'},
    {shortCode: 'VU',title: 'VANUATU',code: '+678'},
    {shortCode: 'WF',title: 'WALLIS AND FUTUNA',code: '+681'},
    {shortCode: 'WS',title: 'SAMOA',code: '+685'},
    {shortCode: 'XK',title: 'KOSOVO',code: '+381'},
    {shortCode: 'YE',title: 'YEMEN',code: '+967'},
    {shortCode: 'YT',title: 'MAYOTTE',code: '+262'},
    {shortCode: 'ZA',title: 'SOUTH AFRICA',code: '+27'},
    {shortCode: 'ZM',title: 'ZAMBIA',code: '+260'},
    {shortCode: 'ZW',title: 'ZIMBABWE',code: '+263'}
];
