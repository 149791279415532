<template>
  <div>
    <v-container class="mt-2 ">
      <v-card class="pa-3">
        <div class="mb-10">
          <!--          <TableOptions :hasExport="true" :searchFilters="searchFilters"-->
          <!--                        @applyFilters="applyFilters"></TableOptions>-->
        </div>
        <div style="width:  50%;" class="mx-auto">
          <v-autocomplete
              style="border-radius: 30px"
              :loading="userLoader"
              :items="users"
              item-value="id"
              item-text="full_name"
              clearable
              :search-input.sync="searchInput"
              v-model="userId" label="کاربر :"
              outlined
              @click:clear="clearUserId"
          >
            <template v-slot:item="{ item }">
              <div class="d-flex flex-column">
                <div>
                  {{ item.full_name }}
                </div>
                <div>
                  {{ item.email }}
                </div>
                <div style="direction: ltr;text-align: right">
                  {{ item.cel }}
                </div>
              </div>
            </template>
          </v-autocomplete>
        </div>
       <v-fade-transition mode="out-in">
         <template  v-if="messages.length != 0">
           <v-timeline
               align-center
               dense
           >
             <v-timeline-item :color="getMessageColor(message.status)" large v-for="(message , i ) in messages" :key="i">
               <template v-slot:icon>
                 <v-icon color="white" class="e">{{ getMessageIcon(message.status) }}</v-icon>
                 <!--              {{message.status}}-->
               </template>
               <!--            <v-expansion-panels>-->
               <!--              <v-expansion-panel>-->
               <!--                <v-expansion-panel-header>-->
               <!--                  vsdvs-->
               <!--                </v-expansion-panel-header>-->
               <!--                <v-expansion-panel-content>-->
               <!--                  <div c>-->
               <!--                    {{message.message}}-->
               <!--                  </div>-->
               <!--                </v-expansion-panel-content>-->
               <!--              </v-expansion-panel>-->
               <!--            </v-expansion-panels>-->
               <!--            <v-row>-->
               <!--              <v-col class="d-flex flex-column justify-center align-center" cols="0" lg="0">-->
               <!--              </v-col>-->
               <!--              <v-col cols="12" lg="12">-->
               <v-card class="pa-1">
                 <v-card-title style="font-size: 16px;font-weight: bolder">
                   {{ prepareMessageTitle(message.template) }}
                   {{ message.user.full_name }}
                 </v-card-title>
                 <v-card-text class="text-right">
                   {{ message.message }}
                 </v-card-text>
                 <v-divider class="grey lighten-2"/>
                 <v-card-actions class="pb-0 justify-space-between">
                   <div class="pa-0 grey--text text--darken-1"
                        style="font-weight:bolder;font-size:.9em;direction: ltr;text-align: right;">
                     {{ message.created_at | dateFormat }}
                   </div>
                 </v-card-actions>
               </v-card>
               <!--              </v-col>-->
               <!--            </v-row>-->
             </v-timeline-item>
           </v-timeline>
           <div class="text-center mb-3" v-if="localLoader">
             <v-progress-circular indeterminate></v-progress-circular>
           </div>
         </template>
         <template v-else>
           <div class="text-center">
             <v-icon>mdi-information-variant</v-icon>
             برای این کاربر پیامی در واتساپ ارسال نشده است
           </div>
         </template>
       </v-fade-transition>

      </v-card>
    </v-container>
  </div>
</template>

<script>
import TableOptions from "@/components/TableOptions";
import {whatsappLog} from "../../searchFilters";
import momentJalali from "moment-jalaali";
import {EventBus} from "@/event-bus";
/*eslint-disable*/
export default {
  components: {TableOptions},
  data() {
    return {
      searchFilters: whatsappLog,
      messages: [],
      page: 1,
      total: 1,
      apiLock: false,
      userLoader: false,
      users: [],
      searchInput: null,
      localLoader: false,
      usernameTimer: null,
      userId: null,

    }
  },
  mounted() {
    this.getAdminWhatsappHistory(true)
  },
  watch: {
    userId() {
      this.messages = []
      this.page = 1
      this.getAdminWhatsappHistory(false)
    },
    searchInput(val) {
      clearTimeout(this.usernameTimer)
      // if (this.userId != null) {
      //   return;
      // }
      this.usernameTimer = setTimeout(() => {
        if (val) {
          EventBus.$emit('setLoading', true)
          this.$store.dispatch('getClientsListAddPayment', {
            page: 1, options: {
              assigned_counselor: this.$route.params.counselorId,
              type: 'patient',
              patient_name: this.searchInput
            }
          }).then((resp) => {
            this.users = resp.data.users
          }).finally(() => {
            EventBus.$emit('setLoading', false)
          })
        } else {
          this.users = []
        }
      }, 1000)
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollEventListenerMethod);
  },
  methods: {
    scrollEventListenerMethod() {
      const screenHeight = window.innerHeight;
      const scrollValue = window.scrollY;
      const totalHeight = document.body.scrollHeight;
      const scrollPercentage = (scrollValue / (totalHeight - screenHeight)) * 100;
      if (scrollPercentage >= 80) {
        if (!this.apiLock && this.page != this.total) {
          this.page++;
          this.getAdminWhatsappHistory(false, true)
        }
      }
    },
    clearUserId() {
      this.userId = null
      this.getAdminWhatsappHistory(false)
    },
    prepareMessageTitle(template) {
      switch (template) {
        case 'session_set':
          return 'اطلاع رسانی ثبت جلسه برای'
        case 'xmin_session_reminder':
          return 'یادآوری جلسه'
        case "counselor_assigned":
          return 'اطلاع رسانی انتخاب مشاور برای'
      }
    },
    bindListener() {
      window.addEventListener("scroll", this.scrollEventListenerMethod);
    },
    getAdminWhatsappHistory(bindListener = false, localLoader = true) {
      if (!this.apiLock) {
        this.apiLock = true
        if (localLoader) {
          this.localLoader = true
        }
        let payload = {
          page: this.page
        }
        if (this.userId) {
          payload.user_id = this.userId
        }
        this.$store.dispatch('getAdminWhatsappHistory', payload).then((resp) => {
          this.messages.push(...resp.data.messages)
          this.total = resp.data.pagination.total_pages;
          if (bindListener) {
            this.bindListener()
          }
        }).finally(() => {
          this.apiLock = false
          this.localLoader = false
        })
      }
    },
    getMessageIcon(status) {
      return status === 'sent' ? 'mdi-check' : 'mdi-check-all'
    },
    getMessageColor(status) {
      return status === 'read' ? 'blue' : 'grey'
    },
  },
  filters: {
    dateFormat(val) {
      if (val) {
        return momentJalali(val).format('jYYYY/jMM/jDD HH:mm')
      }
    }
  }
}
</script>

<style scoped>

</style>