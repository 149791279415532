<template>
  <v-card>
    <v-card-title>
      افزودن سوال یا تست
    </v-card-title>



    <v-card-text>
      <v-form
          ref="addCycleForm"
      >

        <v-row>
          <v-col>
            <AlertsComponent></AlertsComponent>
          </v-col>
        </v-row>


        <v-row>

          <v-col cols="12" sm="3">
            <v-select
                :items="cycles"
                item-text="heading"
                item-value="id"
                v-model="question.cycle"
                @change="cycleChanged"
                :rules="requiredRules"
                label="دوره درمان"
                outlined
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3">

            <v-select
                :items="types"
                item-value="key"
                item-text="label"
                v-model="question.type"
                @change="cycleChanged"
                :rules="requiredRules"
                label="نوع"
                outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">


            <v-select
                :items="doctors"
                item-value="id"
                item-text="full_name"
                v-model="question.doctor"
                :loading="doctorLoading"
                label="دکتر"
                outlined
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3">

            <v-text-field
                v-model="quantity"
                :rules="requiredRules"
                label="تعداد"
                outlined
                type="number"
            ></v-text-field>
          </v-col>


          <v-divider></v-divider>
        </v-row>


        <h4>جزییات</h4>

        <v-col class="d-flex  flex-column" cols="12" sm="12">
          <div :key="i"
               style="border-radius: 12px"
               class="d-flex  elevation-1 pt-6 px-5  justify-start  align-start"
               v-for="i in items.length">
            <v-col cols="12" sm="4">
              <v-select
                  :items="states"
                  item-value="key"
                  item-text="label"
                  v-model="items[i-1].state"
                  label="وضعیت"
                  outlined

              ></v-select>
            </v-col>


            <v-col v-if="showDateTime(items[i-1])" cols="12" sm="4">

              <v-text-field
                  :value="items[i-1].date | toJalali"
                  outlined
                  label="تاریخ جلسه"
                  prepend-icon="event"
                  readonly
                  @click="items[i-1].showDate=true"
                  :rules="requiredRules"
              >
              </v-text-field>
              <AdminDatePicker @close="items[i-1].showDate=false"
                               :dialog="items[i-1].showDate"
                               v-model="items[i-1].date">
              </AdminDatePicker>
            </v-col>

            <v-col v-if="showDateTime(items[i-1])" cols="12" sm="4">

              <v-menu
                  ref="menu"
                  v-model="items[i-1].showTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="items[i-1].time"
                  transition="scale-transition"
                  max-width="250px"
                  min-width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="items[i-1].time"
                      label="ساعت جلسه"
                      prepend-icon="access_time"
                      readonly
                      v-bind="attrs"
                      outlined
                      :rules="requiredRules"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="items[i-1].showTime"
                    format="24hr"
                    width="250"
                    v-model="items[i-1].time"
                    @click:minute="$refs.menu[i-1].save(items[i-1].time)"
                ></v-time-picker>
              </v-menu>


            </v-col>


          </div>


        </v-col>


      </v-form>
    </v-card-text>
    <v-card-actions class="flex justify-end mb-10 pa-10">
      <v-btn @click="submit" large style="font-weight: bold" class="primary">ثبت</v-btn>
    </v-card-actions>



  </v-card>
</template>

<script>

import AdminDatePicker from "@/components/AdminDatePicker";
import moment from 'moment-jalaali';
import AlertsComponent from "@/components/AlertsComponent";
import {EventBus} from "@/event-bus";

export default {
  name: "AdminCreateQuestion",
  components: {AlertsComponent, AdminDatePicker},
  data() {
    return {
      doctors: [],

      quantity: 1,
      question: {
        cycle: null,
        type: 'question',
        doctor: null,
      },
      items: [
        {
          showTime: false,
          showDate: false,
          state: 'session_requested',
          date: null,
          time: null,
        }
      ],
      can_request_new_doctor: false,
      doctorLoading: false
    }
  },
  computed: {
    states() {
      if (this.question.doctor === null) {
        return [{key: 'session_requested', label: 'درخواست '}];
      }

      return [
        // {key: 'session_requested', label: 'درخواست '},
        {key: 'doctor_assigned', label: 'دکتر ثبت شده'},
        {key: 'date_fixed', label: 'تاریخ ثبت شده'},
        {key: 'used', label: 'استفاده شده'},
      ]
    },

    types() {
      return [
        {key: 'question', label: 'سوال'},
        {key: 'test', label: 'تست'},
      ];
    },

    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },


    cycles() {
      return this.$store.getters.getClientProfile.cycles;
    }
  },

  methods: {
    showDateTime(item) {
      return !['session_requested', 'doctor_assigned'].includes(item.state)
    },

    async submit() {


      let payload = {
        doctor_id: this.question.doctor,
        cycle_id: this.question.cycle,
        user_id: this.$store.getters.getClientProfile.id,
        session_count: this.quantity,
        indebt: false,
        type: 'question',
        details: this.items.map(i => {
          return {
            state: i.state,
            datetime: i.date ? `${i.date} ${i.time}` : null
          }
        })
      }

      try {
        let {data} = await this.$store.dispatch("adminClientRequestSubmit", payload)
        // console.log(data)
        EventBus.$emit('notify', 'green', undefined, data.message);
      } catch (e) {
        console.error(e)
      }

    },

    async cycleChanged() {
      try {
        this.doctorLoading = true
        let {data} = await this.$store.dispatch('adminClientsRequestDoctors', {
          cycle_id: this.question.cycle,
          user_id: this.$store.getters.getClientProfile.id,
          type: this.question.type,
          indebt: false,
        });
        this.can_request_new_doctor = data.can_request_new_doctor;
        this.doctors = data.results.doctors;

        if (this.can_request_new_doctor) {
          this.doctors.unshift({full_name: "دکترجدید", id: null})
        }


      } catch (e) {
        console.error(e)
        this.can_request_new_doctor = false
        this.doctors = [];
      } finally {
        this.doctorLoading = false
      }
    }
  },


  filters: {
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
  },

  watch: {
    quantity(value) {
      if (this.items.length < value) {
        this.items.push({
          showTime: false,
          showDate: false,
          state: 'session_requested',
          date: null,
          time: null,
        });
      }else{
         this.items.pop();
      }

    }
  }
}
</script>

<style scoped>

</style>