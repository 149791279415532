<template>
  <div>
    <div class="d-flex pa-2">
      <div>
        <v-btn color="primary" fab x-small elevation="0" @click="downloadFile">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>
      <div class="mt-2 mr-2">
        {{ file_name.replace('.dat', '') }}.{{ file_type }}
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import FileDownload from "js-file-download";

export default {
  name: "FileTelegramLoader",
  props: {
    message_id: Number,
    file_type: String,
    file_name: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      imageUrl: null,
      types: {
        'jpg': 'image/jpeg',
        'oga': 'audio/oga',
        'mp4': 'video/mp4',
        'pdf': 'application/pdf'
      }
    }
  },
  methods: {
    downloadFile() {
      Axios({
        method: 'POST',
        url: this.baseUrl + '/admin/telegram/load-telegram-file',
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: {
          file_message_id: this.message_id
        },
        responseType: 'blob',
      }).then((resp) => {
        // let blob = new Blob([resp.data], {type: this.types[this.file_type]}); // Adjust the type as per your image type

        // Create a URL for the Blob object
        FileDownload(resp.data, this.file_name.replace('.dat', '') + "." + this.file_type);


      })
    },
  },

}
</script>

<style scoped>

</style>