<template>
  <v-container>
    <v-dialog v-model="walletModal" max-width="800">
      <AdminUserWallet v-if="walletModal" @close="walletModal = false" @done='updateUserInfo'
                       :userId="$route.params.clientId"></AdminUserWallet>
    </v-dialog>
    <v-dialog width="500px" v-model="modalAddComment">
      <v-card>
        <v-card-title>
          افزودن یاداشت
        </v-card-title>
        <v-card-text>
          <v-form ref="addNoteForm">
            <v-textarea class="" :rules="requiredRules" v-model="note" outlined dense label="یادداشت">
            </v-textarea>
          </v-form>
          <v-btn color="primary" @click="addNotes">
            ثبت
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addCommentsDialog"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-container>
          <div style="position: absolute;bottom: 20px;left: 20px" @click="modalAddComment = true">
            <v-btn color="primary" elevation="0" fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <div>
              <h2 class="font-weight-bold mb-5 mt-2">یاداشت‌ها </h2>
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-btn fab text @click="addCommentsDialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <hr>
          <v-fade-transition mode="out-in">
            <div class="notes mt-5" v-if="!noteLoader">
              <div class="note" v-for="(note , i ) in notes" :key="i">
                <div class="noteTime">
                  {{ note.created_at | dateFilter }}  -  {{ note.account_manager.full_name }}
                </div>
                <div class="noteContent">
                  {{ note.description }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="mt-10 text-center">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
            </div>
          </v-fade-transition>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assignDoctorModal" width="500px">
      <v-card class="pa-4">
        <v-card-text>
          <AssignDoctorCRM @closeModal="()=> assignDoctorModal = false"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card class="elevation-2">
          <v-card-title>
            اطلاعات کاربر
            <v-spacer></v-spacer>
            <template v-if="userHasRole('admin')">
              <v-btn color="primary" elevation="0" fab small class="ml-1" @click="openModalNotes">
                <v-icon>mdi-note</v-icon>
              </v-btn>
              <v-btn color="primary" fab small elevation="0" v-if="checkRoleAndPermissions('admin.assign_doctor')"
                     @click="assignDoctorModal = true">
                <v-icon>
                  mdi-doctor
                </v-icon>
              </v-btn>
              <v-btn fab class="mx-1 primary" text small
                     v-if="checkRoleAndPermissions('admin.user.calendar.edit')"
                     :loading="!clientIsLoaded"
                     @click="$router.push({name:'adminClientCalendar',params:{lang:'fa',clientId:$route.params.clientId}})">
                <v-icon> fas fa-calendar-alt</v-icon>
              </v-btn>
              <v-btn fab class="mx-1 primary" text small
                     :loading="!clientIsLoaded"
                     v-if="checkRoleAndPermissions('admin.user.wallet.add')"
                     @click="openWalletModal">

                <v-icon> mdi-wallet-plus</v-icon>
              </v-btn>
              <template v-if="checkRoleAndPermissions('admin.user.changepassword')">
                <ChangePassword :userId="$route.params.clientId"></ChangePassword>
              </template>
            </template>
          </v-card-title>
          <v-card-title class="pa-0 ma-0">
            <v-spacer></v-spacer>
            <v-chip class="mx-1"
                    :class="{'white--text red' : walletItem.balance <0,'blue white--text':walletItem.balance>0}"
                    small
                    v-for="(walletItem,index) in wallet"
                    :key="walletItem"> {{ index|resolveCurrency }}
              {{ walletItem.balance }}
            </v-chip>
          </v-card-title>

          <v-card-text>
            <ClientProfileComponent></ClientProfileComponent>
          </v-card-text>
          <v-card-actions v-if="checkRoleAndPermissions('admin.user.update')">
            <v-btn class="success px-10" @click="saveProfile">ثبت</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ClientProfileComponent from '@/components/profiles/components/ClientProfileComponent.vue';
import {EventBus} from '../../event-bus.js'
import ChangePassword from '@/components/ChangePassword';
import {mapGetters} from 'vuex';
import AdminUserWallet from '@/components/Admin/user/AdminUserWallet';
import moment from 'moment-timezone';
import momentJalali from "moment-jalaali";
import {resolveCurrency} from "../../utilities";
import AssignDoctorCRM from "@/views/admin/crm/task/assignDoctorCRM";

export default {
  computed: {
    ...mapGetters(['userHasRole']),
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  components: {
    AssignDoctorCRM,
    ClientProfileComponent,
    ChangePassword,
    AdminUserWallet
  },
  created() {
    EventBus.$on('clientProfileUpdated', this.updateClientInfo);
    this.getNotes()
  },
  beforeDestroy() {
    EventBus.$off('clientProfileUpdated', this.updateClientInfo);
  },
  data() {
    return {
      wallet: [],
      walletModal: false,
      clientIsLoaded: false,
      profile: null,
      assignDoctorModal: false,
      modalNotes: false,
      addCommentsDialog: false,
      modalAddComment: false,
      notes: null,
      note: null,
      noteLoader:true,
    }
  },
  methods: {
    getNotes() {
      this.noteLoader = true
      this.$store.dispatch('getNotesClient', {
        clientId: this.$route.params.clientId
      }).then((resp) => {
        this.notes = resp.data.user_crm_note.data
      }).finally(() => {
        this.noteLoader = false
      })
    },
    addNotes() {
      if (this.$refs.addNoteForm.validate()) {
        this.$store.dispatch('addNoteClient', {
          "user_id": this.$route.params.clientId,
          "description": this.note
        }).then(() => {
          this.modalAddComment = false
          this.$refs.addNoteForm.reset()
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.getNotes()
        })
      }
    },
    openModalNotes() {
      this.addCommentsDialog = true
    },
    updateUserInfo() {
      this.walletModal = false;
      this.clientIsLoaded = false;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getUserProfile', {
        userid: this.$router.currentRoute.params.clientId
      }).then((data) => {
        this.client = data.data.client;
        this.client.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }).finally(() => {
        this.clientIsLoaded = true;
        EventBus.$emit('setLoading', false);
      });
    },
    openWalletModal() {
      this.walletModal = true;
    },
    updateClientInfo() {
      let client = this.$store.getters.getClientProfile;
      this.profile = client;
      this.clientIsLoaded = true;
    },

    saveProfile() {
      EventBus.$emit('saveProfileEvent');
    },
    resetProfile() {
      EventBus.$emit('resetProfileEvent');
    }
  },
  filters: {
    resolveCurrency(input) {
      return resolveCurrency(input);
    },
    dateFilter(val) {
      if (val) {
       return  momentJalali(val).format('jYYYY/jMM/jDD HH:mm')
      }
    }
  }


}
</script>

<style scoped>
.note {
  border: 1px solid #ededed;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.noteTime {
  font-size: 13px;
  color: grey;
}

.noteContent {
  margin-top: 5px;
}
</style>