<template>
  <v-container>
    <v-dialog max-width="700" v-model="showSettleFactorModal">
      <v-card>
        <v-card-title>تسویه فاکتور</v-card-title>
        <v-card-text class="font-weight-bold">
          آیا نسبت به انجام عملیات مورد نظر اطمینان کامل دارید؟
          نتیجه این عملیات این عملیات
          <v-chip small class="mx-1 red white--text font-weight-bold">غیر قابل بازگشت</v-chip>
          خواهد بود.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="green white--text" @click="showSettleFactorModal = false">رد</v-btn>
          <v-btn class="red white--text" @click="settleFactor">تایید</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddPaymentToCartDialog :show="showAddPaymentDialog"
                            :factor="factor"
                            v-if="factor!=null"
                            @added="addedPayment"
                            @closed="showAddPaymentDialog=false"></AddPaymentToCartDialog>
    <DeleteDialog title="حذف فاکتور" :modal="deleteFactorModal" @delete="()=>deleteFactor(this.$route.params.factorId)"/>
<!--    <DeleteDialog title="حذف رکورد پرداخت" :modal="deletePaymentModal" @delete="deletePayment"/>-->
    <v-card class="elevation-0">
      <v-card-text>
        <v-expansion-panels v-model="expand">
          <v-expansion-panel v-if="factor!=null">
            <v-expansion-panel-header>
              <v-row dense>
                <v-col class="text-center">
                  هزینه فاکتور :

                  {{ (Number(factor.final_amount).toLocaleString()) }}
                  {{ factor.currency }}

                </v-col>
                <v-col class="text-center">
                  باقی مانده :
                  {{ (Number(factor.remaining_amount).toLocaleString()) }}
                  <span>{{ factor.currency }}</span>
                </v-col>
                <v-col class="text-center">
                  {{ factor.discount }}
                </v-col>
                <v-col class="text-center">
                  <div style="direction: ltr!important;text-align: left">
                    {{ factor.date }}
                    {{ factor.time }}
                  </div>
                </v-col>
                <v-col class="text-center">
                  {{ factor.state | resolveCartState }}
                  <div>
                    <v-chip v-if="factor.canBePaid == true"
                            small
                    >
                      قابل پرداخت
                    </v-chip>
                  </div>
                </v-col>

              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>


              <v-row dense>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      بسته های خریداری شده
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-data-table
                          hide-default-footer
                          :headers="packageHeader"
                          :items="factor.packages"
                      >
                        <template v-slot></template>
                        <template v-slot:item.pivot.final_price="{item}">

                          {{ Number(item.pivot.final_price).toLocaleString() }}
                        </template>
                        <template v-slot:item.pivot.price="{item}">

                          {{ Number(item.pivot.price).toLocaleString() }}
                        </template>
                        <template v-slot:item.pivot.discount="{item}">

                          {{ Number(item.pivot.discount).toLocaleString() }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      <v-spacer></v-spacer>
                      <v-btn

                          v-if="factor.state !='paid' && checkRoleAndPermissions('admin.carts.transactions.addpayment')"
                          @click="showAddPaymentDialog=true" small class="blue white--text"
                          text>
                        افزودن پرداخت
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-data-table
                          hide-default-footer
                          :headers="paymentsHeaders"
                          :items="factor.payments"
                      >
                        <template v-slot:item.type="{item}">
                          {{ item.type | resolvePaymentType }}
                        </template>
                        <template v-slot:item.date="{item}">

                          {{ item.dateTime }}

                        </template>
                        <template v-slot:item.state="{item}">
                          <div>
                            {{ item.state | resolvePaymentState }}
                          </div>
                        </template>

                        <template v-slot:item.final_amount="{item}">
                          {{ Number(item.final_amount).toLocaleString() }}
                        </template>
                        <template v-slot:item.payment_method="{item}">
                                                    <span v-if="item.payment_method!=null">
                                                        {{ item.payment_method.title }}
                                                    </span>

                        </template>
<!--                        <template v-slot:item.action>-->
<!--                          <v-btn color="red" fab elevation="0" x-small class="white&#45;&#45;text" @click="deletePaymentModal.visibility = true">-->
<!--                            <v-icon>mdi-delete</v-icon>-->
<!--                          </v-btn>-->
<!--                        </template>-->
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions v-if="factor.state=='submit'">
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="factor.state == 'partially_paid'">

                <v-col class="text-left">
                  <v-spacer></v-spacer>
<!--                  <v-btn class="red white&#45;&#45;text font-weight-bold"-->
<!--                         @click="showSettleFactorModal = true">تسویه فاکتور-->
<!--                  </v-btn>-->
                </v-col>

              </v-row>

              <div class="text-left">
                <v-btn class="mt-5 white--text" elevation="0" color="red" @click="deleteFactorModal.visibility = true">
                  حذف فاکتور
                </v-btn>
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-card-text>


    </v-card>
  </v-container>
</template>
<script>
import {EventBus} from "../../event-bus";
import {adminCartFactorsFilters} from "../../searchFilters";
import AddPaymentToCartDialog from '@/components/Admin/financial/AddPaymentToCart';
import momentJalali from 'moment-jalaali';
import DeleteDialog from "@/components/Common/DeleteDialog";

export default {
  components: {
    DeleteDialog,
    AddPaymentToCartDialog
  },
  mounted: function () {
    // this.$tours['myTour'].start()
  },
  data() {
    return {
      deleteFactorModal: {
        visibility: false,
      },
      // deletePaymentModal: {
      //   visibility: false,
      // },
      showSettleFactorModal: false,
      showAddPaymentDialog: false,
      expand: 0,
      factorId: this.$route.params.factorId,
      factorHeaders: [
        {
          text: 'مراجعه کننده', align: 'right', value: 'user.full_name'
        },
        {
          text: 'ایمیل', align: 'left', value: 'user.email'
        },
        {
          text: 'موبایل', align: 'left', value: 'user.mobile'
        },
        {
          text: 'تاریخ', align: 'center', value: 'date'
        },
        {
          text: 'ساعت', align: 'center', value: 'time'
        },
        {
          text: 'بسته ها', align: 'right', value: 'packages'
        },
        {
          text: 'متد', align: 'center', value: 'methods'
        },
        {
          text: 'ارز', align: 'right', value: 'currency'
        },
        {
          text: 'مبلغ پرداختی', align: 'left', value: 'final_amount'
        },
        {
          text: 'کد تخفیف', align: 'left', value: 'coupon_code'
        },
        {
          text: 'وضعیت', align: 'center', value: 'state'
        },
      ],
      options: {},
      searchFilters: adminCartFactorsFilters,


      paymentsHeaders: [
        {text: 'مبلغ', value: 'final_amount'},
        {text: 'نوع', value: 'type'},
        {text: 'متد', value: 'payment_method'},
        {text: 'وضعیت', value: 'state'},
        {text: 'تاریخ', value: 'date'},
        {text: '', value: 'action'},

      ],
      headers: [
        {
          text: 'ارز',
          align: 'center',
          sortable: false,
          value: 'currency',
        },
        {text: 'مبلغ پرداختی', value: 'price', align: 'center'},
        {text: 'تخفیف', value: 'discount', align: 'center'},
        // {text: 'تخفیف محصولات', value: 'items_discount', align: 'center'},
        {text: 'تاریخ ', value: 'submitted_at', align: 'center'},
      ],
      packageHeader: [
        {text: 'عنوان', value: 'title', align: 'center'},
        {text: 'تعداد', value: 'pivot.quantity', align: 'center'},
        {text: 'تعداد جلسات', value: 'session_count', align: 'center'},
        {text: 'مبلغ', value: 'pivot.price', align: 'center'},
        {text: 'تخفیف', value: 'pivot.discount', align: 'center'},
        {text: 'مبلغ نهایی', value: 'pivot.final_price', align: 'center'},
      ],
      // page: 1,
      // total: 1,
      factor: null,
    }
  },
  computed: {},
  created() {
    this.getFactor();
  },
  methods: {
    goToOrders(){
        this.$router.push({name: 'orders'})
    },
    deleteFactor(factorId) {
      this.$store.dispatch('AdminRemoveFactor',{
        factorId
      }).then(() => {
        this.goToOrders();
      })
    },
   // deletePayment() {
    //   alert( 'sdvsdvs')
    // },
    settleFactor() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('adminSettleFactor', {
        factorId: this.factor.id
      }).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'عملیات با موفقیت انجام شد');
        this.getFactor();
      }).finally(() => {
        EventBus.$emit('setLoading', false);
        this.showSettleFactorModal = false;
      })
    },
    getClientProfile(id) {
      this.$router.push({name: 'clientDetail', params: {clientId: id}});
    },
    addedPayment() {
      this.showAddPaymentDialog = false;
      this.getFactor();
    },

    getFactor() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('adminGetSingleFactor', {factorId: this.factorId}).then((data) => {


        this.factor = data.data.factor;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });
    }
  },
  watch: {

    // page() {
    //     this.getFactor();
    //     // this.$vuetify.goTo(this.$refs.factors.offsetTop);
    // }
  },
  filters: {
    toJalali(input, type) {
      let format = 'jYYYY/jMM/jDD';
      if (type == 'dateTime') {
        format = 'jYYYY/jMM/jDD HH:mm:ss';
      }

      return momentJalali(input, 'YYYY-MM-DD HH:mm:ss').format(format)
    },
    resolvePaymentType(input) {
      if (input == 'online') {
        return 'آنلاین';
      } else if (input == 'wallet_debt_settlement') {
        return 'بابت بدهی کیف پول';
      } else if (input == 'offline') {
        return 'پرداخت آفلاین';
      } else if (input == 'wallet_payment') {
        return 'پرداخت از طریق کیف پول';
      }

    },
    resolveCartState(state) {
      let result;
      switch (state) {
        case 'submit' :
          result = 'پیش فاکتور';
          break;
        case 'paid':
          result = 'پرداخت شده';
          break;
      }
      return result;
    },
    resolvePaymentState(state) {
      let result;
      switch (state) {
        case 'awaiting' :
          result = 'در انتظار بازگشت از بانک'
          break;
        case 'failed':
          result = 'لغو شده';
          break;
        case 'paid':
          result = 'پرداخت شده';
          break;
        case 'pending':
          result = 'در انتظار';
          break;
        case 'rejected':
          result = 'رد شده';
          break;
      }
      return result;
    }
  }
}
</script>

<style>
/*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):not(:first-child) {*/
/*border-radius: 15px;*/
/*border-left: 8px solid #43378C;*/
/*}*/

/*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):first-child {*/
/*border-radius: 15px;*/
/*border-left: 8px solid #86C23B;*/
/*}*/
</style>

<style scoped>
/*.green-border {*/
/*border: 5px solid #86C23B;*/
/*}*/
.new-payment-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -15px;
  top: -3px;
  padding: 2px 3px;
  line-height: 12px;
  background-color: rgba(230, 0, 0, 1);
  color: white;
  border-radius: 5px;
  z-index: 2;
}
</style>
