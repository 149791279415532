<template>
  <div>
    <v-dialog v-model="verifyRequestModal" width="400" v-if="ChangingAlgorithm">
      <v-card>
        <v-card-title>
          {{ Request | RequestFilter }}
        </v-card-title>
        <v-card-text>
          از {{ Request | RequestFilter }} تشخیص بیماری
          {{ ChangingAlgorithm.mental_disorder_ai.title }}
          توسط
          {{ ChangingAlgorithm.ai }}
          اطمینان دارید؟
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" elevation="0" small @click="changeStatus">
            بله اطمینان دارم
          </v-btn>
          <v-btn color="red white--text" elevation="0" small>
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <v-card class="elevation-0">

        <v-card>
          <v-card-title>
            <b class="font-weight-bold">
              نتایج تشخیص
            </b>
            <TableOptions :hasExport="true" :searchFilters="searchFilters"
                          @applyFilters="applyFilters"></TableOptions>
          </v-card-title>

          <v-card-text>
            <div class="resultsArea" style="min-height: 500px;">
              <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="data"
              >
                <template v-slot:item.created_at="{item}">
                  {{ item.created_at | dateFilter }}
                </template>
                <template v-slot:item.mlp="{item}">
                  <div class="my-1">
                    {{ item.user_match_making_mental_disorder_ai[0].mental_disorder_ai.title }}
                  </div>
                  <template v-if="item.user_match_making_mental_disorder_ai[0].status==='accepted'">
                    <p class="font-weight-bold"
                       :class="item.user_match_making_mental_disorder_ai[0].status == 'accepted' ? 'green--text' : 'red--text'">
                      {{ item.user_match_making_mental_disorder_ai[0].status  | statusFilter }}
                    </p>
                  </template>
                  <template v-else-if="item.user_match_making_mental_disorder_ai[0].status==='rejected'">
                    <p class="font-weight-bold"
                       :class="item.user_match_making_mental_disorder_ai[0].status == 'accepted' ? 'green--text' : 'red--text'">
                      {{ item.user_match_making_mental_disorder_ai[0].status  | statusFilter }}
                    </p>
                  </template>
                  <template v-else-if="item.user_match_making_mental_disorder_ai[0].status == 'pending'">
                    <v-btn color="success" fab x-small elevation="3"
                           @click="startChangeStatusAlgorithm(item.user_match_making_mental_disorder_ai[0],'accept')">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn color="red white--text mr-2" fab x-small elevation="3"
                           @click="startChangeStatusAlgorithm(item.user_match_making_mental_disorder_ai[0],'reject')">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>

                </template>
                <template v-slot:item.knn="{item}">
                  <div class="my-1">
                    {{ item.user_match_making_mental_disorder_ai[1].mental_disorder_ai.title }}
                  </div>
                  <template v-if="item.user_match_making_mental_disorder_ai[1].status==='accepted'">

                    <p class="font-weight-bold"
                       :class="item.user_match_making_mental_disorder_ai[1].status == 'accepted' ? 'green--text' : 'red--text'">
                      {{ item.user_match_making_mental_disorder_ai[1].status  | statusFilter }}
                    </p>
                  </template>
                  <template v-else-if="item.user_match_making_mental_disorder_ai[1].status==='rejected'">

                    <p class="font-weight-bold"
                       :class="item.user_match_making_mental_disorder_ai[1].status == 'accepted' ? 'green--text' : 'red--text'">
                      {{ item.user_match_making_mental_disorder_ai[1].status  | statusFilter }}
                    </p>
                  </template>
                  <template v-else-if="item.user_match_making_mental_disorder_ai[1].status == 'pending'">
                    <v-btn color="success" fab x-small elevation="3"
                           @click="startChangeStatusAlgorithm(item.user_match_making_mental_disorder_ai[1],'accept')">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn color="red white--text mr-2" fab x-small elevation="3"
                           @click="startChangeStatusAlgorithm(item.user_match_making_mental_disorder_ai[1],'reject')">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-data-table>

            </div>
            <v-pagination v-model="pagination.page" :length="pagination.total"></v-pagination>
          </v-card-text>
        </v-card>

        <v-divider></v-divider>
        <v-card-text>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import {EventBus} from "@/event-bus";
import TableOptions from '@/components/TableOptions.vue';
import {adminMatchMakingResultsFilters} from "../../searchFilters";

export default {
  components: {
    TableOptions
  },
  data() {
    return {
      options: {},
      searchFilters: adminMatchMakingResultsFilters,
      headers: [
        {
          text: 'نام',
          align: 'start',
          sortable: true,
          value: 'user.full_name',


        },
        {
          text: 'ایمیل',
          align: 'right',
          sortable: true,
          value: 'user.email',
        },
        {
          text: 'تاریخ تست',
          align: 'center',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'الگوریتم MLP',
          align: 'center',
          sortable: true,
          value: 'mlp',
        },
        {
          text: 'الگورینتم KNN',
          align: 'center',
          sortable: true,
          value: 'knn',
        },
      ],
      data: [],
      verifyRequestModal: false,
      Request: null,
      ChangingAlgorithm: null,
      pagination: {
        page: 1,
        total: 1,
      }
    }
  },
  filters: {
    dateFilter(val) {
      return moment(val).format('Y/M/D');
      // return moment.(val, 'Y/M/D').format('Y/M/D')
    },
    statusFilter(val) {
      switch (val) {
        case "accepted":
          return 'تایید شده'
        case "rejected":
          return 'رد شده'
      }
    },
    RequestFilter(val) {
      switch (val) {
        case "accept":
          return 'تایید کردن'
        case "reject":
          return 'رد کردن'
      }
    }
  },
  methods: {
    applyFilters(options) {
      this.options = options;
      this.getListResults();
    },
    getAIResults(item, targetAI) {
      return item.user_match_making_mental_disorder_ai.find(alg => alg.ai === targetAI)
    },
    getListResults() {
      EventBus.$emit('setLoading', true)
      console.log(this.options)
      this.$store.dispatch('matchMakingResults', {page: this.pagination.page, options: this.options}).then((data) => {
        this.pagination.total = data.data.pagination.total_pages;
        this.data = data.data.matchMakingResult
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });


      // this.$store.dispatch('matchMakingResults', this.pagination).then((resp) => {
      //   console.log(resp.data.matchMakingResult)
      //   this.data = resp.data.matchMakingResult
      //   this.pagination = {
      //     page: resp.data.pagination.current_page,
      //     total: resp.data.pagination.total_pages
      //   }
      // }).finally(() => {
      //   EventBus.$emit('setLoading', false)
      // })
    },
    startChangeStatusAlgorithm(item, status) {
      this.ChangingAlgorithm = item
      this.verifyRequestModal = true
      this.Request = status
    },
    changeStatus() {
      let action = this.Request == 'accept' ? 'acceptAlgorithm' : 'rejectAlgorithm'
      this.verifyRequestModal = false
      EventBus.$emit('setLoading', true)
      this.$store.dispatch(action, {id: this.ChangingAlgorithm.id}).then(() => {
        this.getListResults()
      }).finally(() => {
        EventBus.$emit('setLoading', true)
      })
    }
  },
  mounted() {
    // this.getListResults()
  },
  watch: {
    'pagination.page'() {
      window.scrollTo({top: 0})
      this.getListResults()
    }
  }
}
</script>

<style scoped>
.result {
  border: 1px solid #ededed;
  padding: 10px;
}
</style>