<template>
  <div>
    <v-row>
      <template v-if="tasks.length > 0">
        <v-col v-for="(item , i ) in tasks" :key="i" cols="12" lg="3" class="mt-5">
          <v-card v-if="item.activity_list"  style="border-radius: 10px">
            <v-card-title>
              <div class="d-flex">
                <div>
                  <v-btn elevation="0" fab small>
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </div>
                <div class="mt-2 mr-3">
                  <h5>{{ item.activity_list.title_fa }}</h5>
                </div>
              </div>
            </v-card-title>
            <v-divider class="my-4"></v-divider>
            <v-card-text>
                <p class="">
                  <b class="black--text font-weight-bold">
                    تاریخ تخصیص :
                  </b>
                  <span dir="">{{ item.created_at | toJalali }}</span>
                </p>
                <p>
                  <b class="black--text font-weight-bold">
                    وضعیت :
                    <span>
                      <v-btn color="ededed" elevation="0" small x-small rounded>
                        {{ item.status | statusFilter }}
                      </v-btn>
                    </span>
                  </b>
                </p>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-btn color="primary" rounded elevation="0" width="100%"  @click="loadDetailsTasK(item)">
                    مشاهده
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="primary" rounded elevation="0" width="100%" outlined @click="loadDetailsTasK(item , true)">
                    تنظیم زمان
                  </v-btn>
                </v-col>
              </v-row>

            </v-card-actions>
          </v-card>
        </v-col>
      </template>
      <template class="fill-height" v-else>
        <div class="">
          <h3 class="mx-5 my-5">
            تسکی برای این کاربر ثبت نشده است
          </h3>
        </div>
      </template>
    </v-row>

  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import moment from "moment-jalaali";

export default {
  data() {
    return {
      tasks: [],
      selected: null,
    }
  },
  mounted() {
    this.getTaskList();
    EventBus.$on('getTaskList', () => {
      this.getTaskList();
    })
  },
  beforeDestroy() {
    EventBus.$off('getTaskList')
  },
  methods: {
    getTaskList() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getTaskUser', this.$route.params.clientId).then((resp) => {
        this.tasks = resp.data.tasks.data
        if (this.$route.query.taskId != null) {
          let target = this.tasks.filter(item => item.id == this.$route.query.taskId)
          this.loadDetailsTasK(target[0])
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    loadDetailsTasK(item, do_date = false) {
      this.$emit('changeWindow', item)
      if (do_date){
        EventBus.$emit('doDateDialog',true)
      }
    },
  },
  watch: {
    '$route.params.clientId'() {
      this.tasks = [];
      this.selected = null;
      this.getTaskList()
    }
  },
  filters: {
    statusFilter(input) {
      switch (input) {
        case 'todo':
          return 'برای انجام'
        case 'doing':
          return 'درحال انجام '
        case 'paused':
          return "نگه‌داشته شده"
        case 'done':
          return 'انجام شده'
        case 'archived':
          return 'آرشیو'
      }
    },
    toJalali(date) {
      return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
    },
  }

}
</script>

<style scoped>

</style>