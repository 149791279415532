<template>
  <div v-if="rerender">

    <v-dialog
        v-model="editState"
        v-if="itemToEdit!=null"
        max-width="800"
    >
      <changeState :location="location" @close="closeEditStateForm" @done="finishedEditingState"
                   :itemToEdit="itemToEdit" @doneWithoutReload="finishedEditingStateWithoutReload"></changeState>
    </v-dialog>
    <SetExtraSessionTimeModal @close="closeModal" :session="targetedSession" @afterSet="afterSet"
                              :show="modalExtraSession"/>
    <v-dialog
        v-model="showResetDialogSwitch"
        max-width="400px"
    >
      <v-card class="pa-5">
        <v-card-title>از حذف این مورد اطمینان دارید؟</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-text></v-card-text>
        <v-card-actions class="">
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="showResetDialogSwitch = false">
            خیر
          </v-btn>
          <v-btn class="red white--text elevation-0" @click="resetSession()">
            بله
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sessionReportModal" width="600px">
      <SessionReport></SessionReport>
    </v-dialog>
    <v-container fluid>
      <v-btn color="primary" @click="sessionReportModal = true" elevation="0" small>دریافت برنامه جلسات</v-btn>
      <!--<div v-if="this.path == 'dateSessions'">-->
      <!--<v-chip class="ma-2 px-8" v-for="el in dates" :key="el.id" @click="selectDate(el)"-->
      <!--:color="date == el.code ? 'primary': ''">-->
      <!--{{ el.title }}-->
      <!--</v-chip>-->
      <!--</div>-->

      <v-card class="my-8">
        <v-progress-linear
            indeterminate
            :active="requesting"
            color="blue "
        ></v-progress-linear>
        <v-card-text v-show="location == null">
          <TableOptions
              :custom-query-parameter="{page:1}"
              :hasExport="true"
              :searchFilters="searchFilters"
              @applyFilters="applyFilters"
              :hasSepidarExport="true"
              :action-required-permission="{
                                    download:'admin.export.sessions.default',
                                    downloadSepidar:'admin.export.sessions.sepidar'
                                  }"

          ></TableOptions>
        </v-card-text>
        <!--<v-card-title ref="adminSessions">-->
        <!--{{ title }}-->
        <!--<v-spacer></v-spacer>-->
        <!--<v-text-field-->
        <!--solo-->
        <!--v-model="search"-->
        <!--append-icon="mdi-magnify"-->
        <!--label="جستجو"-->
        <!--single-line-->
        <!--hide-details-->
        <!--&gt;</v-text-field>-->
        <!--</v-card-title>-->
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="sessions"
            :search="search"
        >
          <template v-slot:item="{item}">
            <tr
                :class="itemClass(item)"
            >

              <td>
                <v-chip style="overflow:visible;">
                  <a :href="'clients/'+item.user.id" style="text-decoration: none">
                    {{ item.user.full_name }}
                  </a>
                  <div v-if="item.user.doctorReferralsCount > 0">
                    <div target="_blank" :href="'clients/'+item.id" class="referral-badge"
                         style="text-decoration: none">refer
                    </div>
                  </div>

                </v-chip>

              </td>
              <td>
                <template v-if="item.state == 'date_fixed' || item.state == 'used'">
                  {{ item.fixed_date }}
                </template>
                <template v-else>
                  {{ item.request_date }}
                </template>
              </td>

              <td>
                <template v-if="item.state == 'date_fixed' || item.state == 'used'">
                  {{ item.fixed_time }}
                </template>
                <template v-else>
                  {{ item.request_time }}
                </template>
              </td>
              <td>
                <template v-if="item.doctor!=null">
                  {{ item.doctor.full_name }}
                </template>
              </td>
              <td style="position:relative">
                <span class="partially-paid" v-if="item.factorIsPartiallyPaid">پیش پرداخت</span>
                {{ resolveState(item.state) }}
              </td>
              <td>
                {{ item.type | resolveSessionType }}
              </td>
              <td>
                <div style="direction: ltr;text-align: right">
                  <template v-if="item.reason == 'extra_session_time'">
                    <v-chip x-small>
                      بابت تسویه
                    </v-chip>
                  </template>
                  {{ item.extra_duration }}

                </div>
              </td>
              <td>

                <template v-if=" $route.name != 'cancelSessionCrm'">
                  <getSessionLinks :item="item"></getSessionLinks>
                  <v-btn @click="editSessionState(item)" x-small text fab>
                    <v-icon small>fas fa-edit</v-icon>
                  </v-btn>
                  <v-btn x-small class="primary mx-1" text fab
                         :to="`assignment/user/${item.user.id}/cycle/${item.cycle.id}/request/${item.id}`">
                    <v-icon small>fas fa-tv</v-icon>
                  </v-btn>
                  <template v-if="item.state != 'used'">
                    <v-btn fab x-small class="red white--text elevation-0 mx-1"
                           @click="showResetDialog(item)">

                      <v-icon small class="">
                        fas fa-trash
                      </v-icon>
                    </v-btn>

                  </template>
                  <template
                      v-if="item.state == 'doctor_assigned' || item.state == 'date_fixed' || item.state == 'used'">
                    <v-btn fab class="elevation-0" x-small @click="showDetails(item)"
                           v-if="checkRoleAndPermissions('sessions.show')">
                      <v-icon small class="mx-2">
                        fas fa-search
                      </v-icon>
                    </v-btn>
                  </template>
                </template>
                <template v-else>
                  <v-icon color="red">mdi-close</v-icon>
                </template>

                <v-btn v-if="item.state == 'date_fixed' && item.whatsappMessages.length == 0"
                       @click="sendWhatsappNotify(item.id)" fab x-small
                       color="green white--text" class="mr-1" elevation="0">
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
                <template v-if="item.extra_used == 'not_used'">
                  <template v-if="item.state == 'used' || item.state == 'date_fixed' ">
                    <v-btn fab rounded x-small elevation="0" class="mr-2" @click="OpenModalSetExtraTimeOnSession(item)">
                      <v-icon>mdi-clock</v-icon>
                    </v-btn>
                  </template>
                </template>
                <template v-else>
                  <v-chip x-small class="mr-2">
                    استفاده شده جهت تسویه
                  </v-chip>
                </template>
              </td>
              <td>


                <v-tooltip right v-if="item.zoom_meeting.available">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        color="primary"
                        dark
                        x-small
                        v-bind="attrs"
                        v-on="on"
                    >
                      zoom
                    </v-chip>
                  </template>
                  <div class="text-left">account:{{ item.zoom_meeting.zoom_account }}</div>
                  <div class="text-left">password: {{ item.zoom_meeting.pwd }}</div>
                </v-tooltip>
              </td>
              <td>
                <template v-for="(message,i) in item.whatsappMessages">
                  <div :key="i">
                    <v-chip fab x-small text class="white">
                      <span>{{ getMessageTooltip(message) }}</span>
                      <v-icon :color="getMessageColor(message.status)">
                        {{ getMessageIcon(message.status) }}
                      </v-icon>
                    </v-chip>
                  </div>


                </template>
              </td>
            </tr>
          </template>


          <!--<template v-slot:item.actions="{ item }">-->
          <!---->

          <!--&lt;!&ndash;<div @click="getClientProfile(item.user.id)" style="cursor: pointer;">&ndash;&gt;-->
          <!--&lt;!&ndash;{{ item.user.full_name }}&ndash;&gt;-->
          <!--&lt;!&ndash;</div>&ndash;&gt;-->
          <!--</template>-->
        </v-data-table>
      </v-card>
      <div class="text-center" v-if="!fiveSession">
        <v-pagination
            @input="handlerPagination(getAdminSessions)"
            v-model="page"
            :length="total"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from 'vuex';
import moment from 'moment-jalaali';

import {EventBus} from "../../event-bus";
import {
  adminSessionFilters,
  dateSessionFilters,
  adminClientRequestedSessionFilters,
  adminClientUsedSessionFilters
} from "../../searchFilters";
import TableOptions from '@/components/TableOptions.vue';
import ChangeState from '@/components/ChangeState.vue';

import getSessionLinks from '@/components/getSessionLinks.vue';
import SessionReport from "@/components/Admin/sessions/SessionReport";
import UrlQueryMixin from "@/Mixin/UrlQueryMixin";
import SetExtraSessionTimeModal from "@/components/sessions/SetExtraSessionTimeModal";

export default {
  mixins: [UrlQueryMixin],
  components: {
    SetExtraSessionTimeModal,
    SessionReport,
    TableOptions,
    ChangeState,
    getSessionLinks
  },
  props: {
    mode: {
      default: null
    },
    title: {
      default: 'جلسات',
      type: String
    },
    fiveSession: Boolean,
    location: {
      default: null,
      required: false,
      type: String,
    }
  },
  data() {
    return {
      modalExtraSession: false,
      targetedSession: null,
      sessionReportModal: false,
      doctors: [],
      itemToEdit: null,
      editState: true,
      counselorStates: [
        'doctor_assigned',
        'used',
        'date_fixed'
      ],
      requesting: false,
      options: {},
      searchFilters: [],
      resetItem: null,
      showResetDialogSwitch: false,
      rerender: false,
      // loading: true,
      search: '',
      headers: [
        {
          text: 'نام و نام خانوادگی',
          align: 'start',
          sortable: false,
          value: 'user.full_name',
        },
        {text: 'تاریخ', value: 'fixed_date'},
        {text: 'ساعت', value: 'fixed_time'},
        {text: 'مشاور', value: 'doctor.full_name'},
        {text: 'وضعیت', value: 'state'},
        {text: 'نوع', value: 'type'},
        {text: 'کسر/اضافه', value: 'extra_duration'},
        {text: 'عملیات', value: 'actions'},
        {text: 'واتساپ', value: 'whatsapp'},
      ],
      desserts: [],
      date: moment().format("YYYY-MM-DD"),
      path: this.$router.currentRoute.name,
      dates: [
        {
          id: 1,
          title: 'امروز',
          code: moment().format("YYYY-MM-DD")
        },
        {
          id: 2,
          title: 'فردا',
          code: moment(new Date()).add(1, 'days').format("YYYY-MM-DD")
        },

      ],
      page: 1,
      total: 1,
      sessions: []
    }
  },
  filters: {
    resolveSessionType(input) {
      let result = '';
      switch (input) {
        case 'session':
          result = 'جلسه'
          break;
        case 'question':
          result = 'سوال'
          break;
        case 'test':
          result = 'تست'
          break;
      }
      return result;

    }
  },
  computed: {
    ...mapGetters({
      allDoctors: 'getAdminAllDoctors'
      // sessions: 'getAdminSessions',
      // page: 'page',
      // total: 'total',
    }),


  },
  watch: {
    editState(newVal) {
      if (!newVal) {
        this.closeEditStateForm();
      }
    },
    '$route': function () {
      this.getAdminSessions();
    },
    page() {
      // this.getAdminSessions();
      // this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
    }
  },
  created() {
    this.fillDoctors();
    this.fillPageState()

  },
  methods: {
    itemClass(item) {
      if (item.reason === 'required_session_time') {
        return 'red black--text lighten-2';
      } else if (item.state === 'used') {
        return 'green black--text darken-1';
      } else if (item.state === 'session_requested') {
        return 'orange black--text darken-1';
      } else if (item.state === 'doctor_assigned') {
        return 'orange black--text lighten-2';
      } else if (item.state === 'date_fixed') {
        return 'blue black--text lighten-3';
      } else if (item.state === 'canceled') {
        return 'grey black--text lighten-1';
      } else {
        return ''; // default class if none of the conditions match
      }
    },
    closeModal() {
      this.modalExtraSession = false
    },
    afterSet() {
      this.getAdminSessions()
    },
    OpenModalSetExtraTimeOnSession(session) {
      this.modalExtraSession = true
      this.targetedSession = session
    },
    sendWhatsappNotify(session_id) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('sendSessionNotifyWhatsapp', {
        session_id
      }).then(() => {
        this.getAdminSessions();
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت ارسال شد');
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    getMessageIcon(status) {
      return status === 'sent' ? 'mdi-check' : 'mdi-check-all'
    },
    getMessageColor(status) {
      return status === 'read' ? 'green' : status === 'failed' ? 'red' : 'grey'
    },
    getMessageTooltip(message) {
      switch (message.template) {
        case 'session_set':
          return 'SET'
        case 'pre_session_set':
          return 'SET'
        case 'xmin_session_reminder':
          return 'REMIND'
        default :
          return 'S'
      }
    },
    fillDoctors() {
      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {
        let mappedDoctors = this.doctors.map((doctor) => {
          return {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
        });
        adminSessionFilters.find((item) => {
          if (item.key == 'doctor_ids') {
            item.options = mappedDoctors;
          }
        });
        if (this.$route.name == 'sessions') {
          this.searchFilters = adminSessionFilters;
        } else if (this.$route.name == 'clientDetail') {
          if (this.mode == 'usedSessions') {
            this.searchFilters = adminClientUsedSessionFilters;
          } else if (this.mode == 'requestedSessions') {
            this.searchFilters = adminClientRequestedSessionFilters;
          }

        } else if (this.$route.name == 'dateSessions') {
          this.searchFilters = dateSessionFilters;
        } else if (this.$route.name == "CrmUserInfo") {
          this.searchFilters = adminClientRequestedSessionFilters;
        }
        this.rerender = true;
      });
    },
    finishedEditingState() {
      EventBus.$emit('notify', 'green', undefined, 'با موفقیت تغییر یافت');
      this.closeEditStateForm();
      this.getAdminSessions();
    },

    finishedEditingStateWithoutReload() {
      EventBus.$emit('notify', 'green', undefined, 'با موفقیت تغییر یافت');
      this.closeEditStateForm();
    },

    closeEditStateForm() {
      this.itemToEdit = null;
      this.editState = false;
    },
    editSessionState(session) {
      this.itemToEdit = session;
      this.editState = true;
    },
    resolveState(state) {
      switch (state) {
        case 'used':
          return 'برگزار شده';
          break;
        case 'session_requested':
          return 'درخواست جلسه';
          break;
        case 'doctor_assigned':
          return 'تخصیص یافته';
          break;
        case 'date_fixed':
          return 'زمان ست شده';
        case 'canceled':
          return 'لغو شده';
          break;
      }
    },
    hasCounselor(session) {
      return this.counselorStates.includes(session.state);
    },
    applyFilters(options) {
      this.resetPaginationInApplyFilterIfNeeded(options)
      this.options = options;
      this.getAdminSessions();
    },
    showDetails(item) {

      this.$router.push({name: 'adminSessionDetails', params: {sessionId: item.id}});
    },
    resetSession() {
      this.$store.dispatch('adminResetSession', this.resetItem.id).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد');
        this.showResetDialogSwitch = false;
        this.getAdminSessions();
      })
    },
    showResetDialog(item) {
      this.resetItem = item;
      this.showResetDialogSwitch = true;
    },
    getAdminSessions() {
      EventBus.$emit('setLoading', true);
      if (this.$router.currentRoute.name == 'sessions') {
        // this.loading = true;
        // let options = {
        //     'state': 'date_fixed|used',
        //     "orderBy": "session_date:desc"
        // };
        this.requesting = true;

        this.$store.dispatch('getAdminSessions', {page: this.page, options: this.options}).then((data) => {
          // console.log('Admin sessions: ', data);
          // // this.loading = false;
          // this.setAdminSessions(data.data);
          this.sessions = data.data.sessions;
          this.total = data.data.pagination.total_pages;
          this.requesting = false;
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
        this.requesting = false;
      } else if (this.$router.currentRoute.name == 'dateSessions') {
        // this.loading = true;
        let options = {};
        this.requesting = true;
        this.$store.dispatch('getAdminSessions', {
          options: {
            from_date: moment().format("YYYY-MM-DD"),
            to_date: moment().add(1, 'days').format("YYYY-MM-DD"),
            ...this.options
          },
          page: this.page
        }).then((data) => {
          this.requesting = false;
          // console.log('Admin sessions based on date: ', data);
          // this.setAdminSessions(data.data);
          // this.total = data.paginate.totalPages;
          // this.loading = false;
          this.sessions = data.data.sessions;
          this.total = data.data.pagination.total_pages;

          this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);

        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      } else if (this.$router.currentRoute.name == 'clientDetail' || this.$route.name == 'CrmUserInfo' || this.$route.name == "cancelSessionCrm") {
        // this.loading = true;
        this.requesting = true;
        this.$store.dispatch('getAdminSessions', {
          page: this.page,
          options: {
            user_id: this.$route.params.clientId,
            ...this.options,
          }
        }).then((data) => {
          this.requesting = false;
          // console.log('Admin client sessions: ', data);
          // this.setAdminSessions(data.data);
          // this.total = data.paginate.totalPages;
          if (!this.options.hasOwnProperty('wantsExcel')) {
            if (data.data.sessions.length == 0) {
              this.$emit('updateWindow', 1)
            }
            this.sessions = data.data.sessions;
            this.total = data.data.pagination.total_pages;
          } else {
            delete this.options.wantsExcel;
          }

        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }
    },
    // nextPage() {
    //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});
    //     console.log('page', this.page)
    // },
    // previousPage() {
    //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});
    //     console.log('page', this.page)
    // },
    // specificPage(page) {
    //     this.$store.commit('SET_SPECIFIC_PAGE', page);
    //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});
    //     console.log('page', page)
    // },
    selectDate(el) {
      this.date = el.code;
      this.$router.push(`${el.code}`);
      // this.loading = true;
      this.getAdminSessions();
    },
    setAdminSessions(data) {
      this.sessions = [];
      data.forEach(session => {
        this.sessions.push(session);
        session['date'] = session.jdatetime.split(' ')[0];
        session['time'] = session.jdatetime.split(' ')[1];
        session['session'] = session.sessionCount + ' از ' + session.orderCount;
      });
    },
    getClientProfile(id) {
      this.$router.push({name: 'clientDetail', params: {clientId: id}});
    },
  }
}
</script>

<style>
.partially-paid {
  font-size: .6em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(230, 0, 0, 1);
  color: white;
  border-radius: 5px;
  z-index: 1;
}

.referral-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  left: -20px;
  top: -5px;
  padding: 2px 3px;
  background-color: rgba(0, 0, 150, 1);
  color: white;
  border-radius: 5px;
  z-index: 20;
}
</style>
