<template>
  <div>
    <AssignAccountManager @callback="callBackAfterAssign" :modal="attachAcc" :doctor-id="selectedDocotr"/>
    <v-card>
      <v-card-title class="font-weight-bold">دکتر‌ها</v-card-title>


      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" lg="5">
            <v-text-field clearable v-model="searchParam" outlined label="جست و جو" rounded
                          prepend-inner-icon="mdi-magnify"></v-text-field>
          </v-col>
        </v-row>

        <v-list dense>
          <v-list-item-group
              color="primary"

          >
            <v-row v-if="renderedDoctor.length != 0">
              <template v-for="(item, i) in renderedDoctor">
                <v-fade-transition :key="i">
                  <v-col cols="12" lg="3" md="4">
                    <v-list-item
                        dense
                        v-ripple="false"
                        class="elevation-1 pt-1  "
                        :class="item.dates_and_times_count == 0 ? 'red lighten-2 ' : 'green lighten-2' "
                        style="border-radius: 4px"
                        :to="{name:'counselorProfile',params: {counselorId:item.doctor_profile.id}}"
                    >
                      <v-list-item-content class="pa-0 ma-0 ">
                        <div class="d-flex">
                          <div>
                            <img :src="item.avatar ||  require('../../assets/MALE.730d6452.svg')"
                                 style="border-radius: 50%"
                                 :style="!item.avatar && 'border:2px solid #39b787'"
                                 width="60px" alt="">
                          </div>
                          <div class="d-flex flex-column flex-grow-1 justify-space-around mr-4 ">
                            <div class="d-flex justify-space-between">
                              <div>
                                <div class="doctorName">
                                  {{ item.name + " " + item.family }}
                                  <br>
                                  <!--                              {{ item.user.full_name }}-->
                                </div>
                                <div style="font-size: 15px;font-weight: normal !important;">
                                  <template
                                      v-if="item.hasOwnProperty('account_managers') && item.account_managers.length != 0">
                                    {{ item.account_managers[0].full_name }}
                                  </template>
                                </div>
                              </div>
                              <div class="d-flex">
                                <!--                                <v-btn fab x-small elevation="0">-->
                                <!--                                  <v-icon>mdi-calendar</v-icon>-->
                                <!--
                                                           </v-btn>-->
                                <v-btn
                                    x-small
                                    fab
                                    elevation="0"
                                    @click.prevent="openModalAssignDoctor(item.doctor_profile.id)"
                                >
                                  <v-icon>
                                    mdi-paperclip
                                  </v-icon>
                                </v-btn>


                                <v-btn
                                    class="mr-2"
                                    x-small
                                    fab
                                    elevation="0"
                                    @click.prevent="selected_doctor = item.id"
                                    :style="item.user_crm_note.length ?'border:3px dotted #fb8c00;':'border:2px solid transparent;'"
                                >
                                  <v-icon :color="item.user_crm_note.length == 0 ? 'grey' : 'warning'">
                                    mdi-note
                                  </v-icon>
                                </v-btn>
                                <DoctorNoteModal v-if="item.id == selected_doctor"
                                                 :modal="{visibility:item.id == selected_doctor}"
                                                 @close="closeItem"
                                                 :doctor_profile_id="item.doctor_profile.id" :user_id="item.id"
                                                 :notes="item.user_crm_note"/>

                                <v-btn x-small fab elevation="0"
                                       :to="{name:'counselorCalendar',params: {counselorId:item.doctor_profile.id}}"
                                       color="white mr-2"
                                       style="font-size: 14px">
                                  {{ item.dates_and_times_count }}
                                </v-btn>

                                <v-btn
                                    class="mr-1"
                                    x-small
                                    fab
                                    elevation="0"
                                    :to="{name:'counselorPatients',params: {counselorId:item.doctor_profile.id}}"
                                >
                                  <v-icon>
                                    mdi-account-group
                                  </v-icon>
                                </v-btn>


                                <!--                                <v-btn :to="`counselors/${item.doctor_profile.id}/user-list`" fab elevation="0" x-small class="mr-2">-->
                                <!--                                  <v-icon>mdi-account-multiple</v-icon>-->
                                <!--                                </v-btn>-->

                              </div>
                            </div>
                          </div>
                          <div class="mt-3 mr-2">
                            <!--                            <v-btn fab elevation="0" color="primary" small-->
                            <!--                                   :to="{name:'counselorCalendar',params:{counselorId:item.user.doctor_profile.id}}">-->
                            <!--                              <v-icon class="mt-1">mdi-calendar</v-icon>-->
                            <!--                            </v-btn>-->
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-fade-transition>
              </template>
            </v-row>
            <div class="text-center " v-else style="font-size: 15px">
              <template v-if="searchParam">
                دکتر با این نام وجود ندارد
              </template>
              <template v-else>
                دکتری برای شما وجود ندارد
              </template>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AssignAccountManager from "@/components/Common/AssignAccountManager";
import DoctorNoteModal from "@/components/Admin/DoctorNoteModal";

/*eslint-disable*/
export default {
  name: "doctorsListDashboard",
  components: {DoctorNoteModal, AssignAccountManager},
  data() {
    return {
      attachAcc: {
        visibility: false,
      },
      searchParam: null,
      selectedDocotr: null,
      doctors: [],
      selected_doctor: null,
    }
  },
  computed: {
    renderedDoctor() {
      if (this.searchParam) {
        return this.doctors.filter((item) => {
          let full_name = item.name + " " + item.family
          if (item.account_managers.length != 0) {
            if (full_name.includes(this.searchParam) || item.account_managers[0].full_name.includes(this.searchParam)) {
              return item
            }
          } else if (full_name.includes(this.searchParam)) {
            return item
          }

          // return full_name.includes(this.searchParam)
        })
      }
      return this.doctors
    }
  },
  created() {
    this.getMyDoctor()
  },
  methods: {
    closeItem() {
      this.selected_doctor = null
      console.log('here in close')
    },
    callBackAfterAssign() {
      this.getMyDoctor()
    },
    openModalAssignDoctor(id) {
      event.preventDefault();
      this.attachAcc.visibility = true
      this.selectedDocotr = id
    },
    getMyDoctor() {
      this.$store.dispatch('getAccountManagersDashboard').then((resp) => {
        this.doctors = resp.data.doctors
      })
    }
  },
  watch: {}

}
</script>

<style scoped>
.doctorName {
  font-size: 18px;
  font-weight: bolder;
}

.doctorWithoutTime {
  /*background: linear-gradient(to right, rgba(200, 0, 0, 0.7), rgba(200, 0, 0, 1));*/
  /*background: linear-gradient(to top, #ff0844 0%, #ffb199 100%);*/
  /*background: linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43));*/
  /*background: linear-gradient(109.6deg, rgba(113, 14, 51, 0.83) 15.2%, rgba(217, 43, 23, 0.95) 96.8%);*/
}

</style>
