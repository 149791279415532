<template>
    <v-container>
        <v-row>
            <v-col>

                <v-card
                        class="elevation-2"
                >
                    <CounselorProfileComponent></CounselorProfileComponent>

                    <v-card-actions class="mt-12">
                        <v-btn class="success px-10" v-if="checkRoleAndPermissions('admin.doctor.update')" @click="saveProfile">ثبت</v-btn>
                        <!--                        <v-btn v-show="this.$router.currentRoute.name == 'profile'" class="grey lighten-2 px-10" @click="resetProfile">Reset</v-btn>-->
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CounselorProfileComponent from '@/components/profiles/components/CounselorProfileComponent.vue';
    import {EventBus} from '../../event-bus.js'

    export default {
        components: {
            CounselorProfileComponent
        },
        methods: {
            saveProfile() {
                EventBus.$emit('saveProfileEvent');
            },
            resetProfile() {
                EventBus.$emit('resetProfileEvent');
            }
        }


    }
</script>

<style scoped>

</style>