<template>
    <v-dialog width="340" v-model="showDialog">
        <v-card>
            <v-card-text>
                <div class="text-center">
                    <v-chip
                            :small="$vuetify.breakpoint.smAndDown"
                            class="mb-2 smooth-transition"
                            style="display: inline-block"
                            @click="isJalali = false"
                    >
                        تقویم میلادی
                    </v-chip>
                    <div style="display: inline-block">
                        <v-switch
                                :dense="$vuetify.breakpoint.smAndDown"
                                class="ma-3 mx-1 "
                                v-model="isJalali"
                                hide-details
                                color="primary"
                        >
                        </v-switch>
                    </div>
                    <v-chip
                            :small="$vuetify.breakpoint.smAndDown"
                            @click="isJalali = true"
                            class="mb-2 smooth-transition"
                            style="display: inline-block"
                    >
                        تقویم شمسی
                    </v-chip>
                </div>

                <AdvancedDatePicker v-if="isJalali" v-model="date"
                                    :styles="'font-family: IranSans;padding:0 5px;'"
                >
                    <template v-slot:prev-icon>
                        <v-btn fab x-small class="elevation-0">
                            <v-icon fab small class="grey--text">
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:next-icon>
                        <v-btn fab x-small class="elevation-0">
                            <v-icon fab class="grey--text">
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:small-modal>


                    </template>
                </AdvancedDatePicker>

                <v-date-picker v-else v-model="date"
                               first-day-of-week="6"
                               class="elevation-0"
                               :width="'100%'"
                ></v-date-picker>
            </v-card-text>
            <v-card-actions v-if="showActions">
                <v-btn class="error">لغو</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="success">تایید</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import AdvancedDatePicker from '@/components/datepicker/AdvancedDatePicker'
    import momentJalali from 'moment-jalaali';

    export default {
        methods: {
            convertToMiladi(date) {
                return this.isJalali ? momentJalali(date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD') : date;
            }
        },
        components: {
            AdvancedDatePicker
        },
        props: {
          setShamsiDefaultValue:{
            default:false
          },
            showActions: {
                default: false
            },
            value: {
                default: null
            },
            dialog: {
                default: false
            }
        },
        data() {
            return {
                lock: false,
                showDialog: false,
                isJalali: true,
                date: null
            }
        },
        watch: {
            isJalali(newVal) {
                if (this.date != null) {
                    this.lock = true;
                    if (newVal) {
                        this.date = momentJalali(this.date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
                    } else {
                        this.date = momentJalali(this.date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD')
                    }
                }
            },
            dialog(newVal) {
                this.showDialog = newVal;
            },
            showDialog(newVal) {
                if (!newVal) {
                    this.$emit('close');
                }
            },
            date(newVal) {
                if (!this.lock) {
                    if (newVal != null && !this.showActions) {
                        this.$emit("input", this.convertToMiladi(this.date));
                        this.$emit('close');
                    }
                } else {
                    this.lock = false;
                }
            }
        },
      created() {
        if(this.setShamsiDefaultValue) {
          this.date = momentJalali(this.value,'YYYY-MM-DD').format('jYYYY-jMM-jDD')
        }
      }
    }
</script>

<style scoped>

</style>