/*eslint-disable*/
import axios from 'axios';
import {ajaxPost} from '../../utilities.js';
import LS from '../../LocalStorateAPI';

const auth = {
    state: {
        profile: {},
        role: localStorage.getItem('role'),
        roles: LS.get('roles'),
        permissions: LS.get('permissions'),
        loggedIn: LS.get('loggedIn'),
        // token: LS.get('token'),
        token: localStorage.getItem('token'),
        isLoggedIn: false
    },
    mutations: {
        setUser(state, data) {
            state.role = data.role;
            state.roles = data.roles;
            state.permissions = data.permissions;

            state.isLoggedIn = true;
            LS.set('isLoggedIn', true);
            LS.set('role', data.role);

            LS.set('roles', data.roles);
            LS.set('permissions', data.permissions);
        },

        userLoggedIn(state) {

        },
        setToken(state, token) {
            state.token = token.token;
            LS.set('token', token.token);
            axios.defaults.headers.common['Authorization'] = token.token;
        },
        setLoggedIn(state) {
            state.loggedIn = true;
            LS.set('loggedIn', true);
        }
    },
    actions: {
        login({commit, rootState}, payload) {

            return ajaxPost(rootState.baseUrl + "/login", payload, false).then(({data}) => {
                commit('setToken', {token: 'Bearer ' + data.token});
                return Promise.resolve();
            }).catch(() => {
                return Promise.reject();
            })
        }
    },
    getters: {
        getAccessToken: state => state.token,
        checkPermission: (state) => (permission) => {

            if (Array.isArray(permission)) {
                if (state.roles.includes('system_admin')) {
                    return true
                }
                let intersect = state.permissions.filter(x => permission.indexOf(x) !== -1)
                return intersect.length;
            }

            if (permission != "" && permission != null && permission != undefined) {
                if (state.roles.includes('system_admin')) {
                    return true
                }

                if (state.permissions.findIndex(item => item == permission) >= 0) {
                    return true
                }
                return false
            } else {
                return true;
            }
        }

    }
}
export default auth;
