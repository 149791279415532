<template>
    <v-card>

        <v-tabs

                v-model="model"
                centered
        >
            <v-tab
                    :href="`#tab-1`"
            >
                لیست جلسات
            </v-tab>
            <v-tab
                    :href="`#tab-2`"
            >
                لیست درخواست ها
            </v-tab>
            <v-tab
                    :href="`#tab-3`"
                    v-if="checkRoleAndPermissions('admin.user.purchasedpackages')"
            >
                بسته های خریداری شده
            </v-tab>
        </v-tabs>

        <v-tabs-items touchless v-model="model">
            <v-tab-item
                    :value="`tab-1`"
            >
                <v-card flat>
                    <v-card-text>
                        <AdminSessions mode="usedSessions"></AdminSessions>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                    :value="`tab-2`"
            >
                <v-card flat>
                    <v-card-text>
                        <AdminSessions mode="requestedSessions"></AdminSessions>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                    :value="`tab-3`"
            >
                <v-card flat>
                    <v-card-text>
                        <AdminClientPurchasedPackages :userId="userId"></AdminClientPurchasedPackages>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

    </v-card>
</template>

<script>
/*eslint-disable*/
    import AdminSessions from "../../components/sessions/AdminSessions";
    import AdminClientPurchasedPackages from "@/components/Admin/user/AdminClientPurchasedPackages";
    import {adminClientUsedSessionFilters} from "../../searchFilters";

    export default {
        components: {AdminSessions, AdminClientPurchasedPackages},
        created() {
            this.clientUsedFilters = adminClientUsedSessionFilters;
        },
        data() {
            return {
                userId: this.$route.params.clientId,
                clientUsedFilters: [],
                model: 'tab-1',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            }
        },
    }
</script>

<style scoped>

</style>
<!--<template>-->
<!--<div v-if="rerender">-->
<!--&lt;!&ndash;<v-dialog&ndash;&gt;-->
<!--&lt;!&ndash;v-model="editState"&ndash;&gt;-->
<!--&lt;!&ndash;v-if="itemToEdit!=null"&ndash;&gt;-->
<!--&lt;!&ndash;max-width="800"&ndash;&gt;-->
<!--&lt;!&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;<changeState @close="closeEditStateForm" @done="finishedEditingState"&ndash;&gt;-->
<!--&lt;!&ndash;:itemToEdit="itemToEdit"></changeState>&ndash;&gt;-->
<!--&lt;!&ndash;</v-dialog>&ndash;&gt;-->
<!--<v-dialog-->
<!--v-model="showResetDialogSwitch"-->
<!--max-width="400px"-->
<!--&gt;-->
<!--<v-card class="pa-5">-->
<!--<v-card-title>از حذف این مورد اطمینان دارید؟</v-card-title>-->
<!--<v-card-text>-->
<!--</v-card-text>-->
<!--<v-card-text></v-card-text>-->
<!--<v-card-actions class="">-->
<!--<v-spacer></v-spacer>-->

<!--<v-btn color="primary" @click="showResetDialogSwitch = false">-->
<!--خیر-->
<!--</v-btn>-->
<!--<v-btn class="red white&#45;&#45;text elevation-0" @click="resetSession()">-->
<!--بله-->
<!--</v-btn>-->
<!--</v-card-actions>-->
<!--</v-card>-->
<!--</v-dialog>-->
<!--<v-container>-->
<!--&lt;!&ndash;<div v-if="this.path == 'dateSessions'">&ndash;&gt;-->
<!--&lt;!&ndash;<v-chip class="ma-2 px-8" v-for="el in dates" :key="el.id" @click="selectDate(el)"&ndash;&gt;-->
<!--&lt;!&ndash;:color="date == el.code ? 'primary': ''">&ndash;&gt;-->
<!--&lt;!&ndash;{{ el.title }}&ndash;&gt;-->
<!--&lt;!&ndash;</v-chip>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->

<!--<v-card class="my-8">-->
<!--<v-progress-linear-->
<!--indeterminate-->
<!--:active="requesting"-->
<!--color="blue "-->
<!--&gt;</v-progress-linear>-->
<!--<v-card-text>-->
<!--<TableOptions :hasExport="true" :searchFilters="searchFilters"-->
<!--@applyFilters="applyFilters"></TableOptions>-->
<!--</v-card-text>-->
<!--&lt;!&ndash;<v-card-title ref="adminSessions">&ndash;&gt;-->
<!--&lt;!&ndash;{{ title }}&ndash;&gt;-->
<!--&lt;!&ndash;<v-spacer></v-spacer>&ndash;&gt;-->
<!--&lt;!&ndash;<v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;solo&ndash;&gt;-->
<!--&lt;!&ndash;v-model="search"&ndash;&gt;-->
<!--&lt;!&ndash;append-icon="mdi-magnify"&ndash;&gt;-->
<!--&lt;!&ndash;label="جستجو"&ndash;&gt;-->
<!--&lt;!&ndash;single-line&ndash;&gt;-->
<!--&lt;!&ndash;hide-details&ndash;&gt;-->
<!--&lt;!&ndash;&gt;</v-text-field>&ndash;&gt;-->
<!--&lt;!&ndash;</v-card-title>&ndash;&gt;-->

<!--<v-data-table-->
<!--hide-default-footer-->
<!--:headers="headers"-->
<!--:items="sessions"-->
<!--:search="search"-->
<!--&gt;-->
<!--<template v-slot:item="{item}">-->
<!--<tr-->
<!--:class="{-->
<!--'green black&#45;&#45;text darken-1' : item.state == 'used',-->
<!--'orange black&#45;&#45;text darken-1' : item.state == 'session_requested',-->
<!--'orange black&#45;&#45;text lighten-2' : item.state == 'doctor_assigned',-->
<!--'blue black&#45;&#45;text lighten-3' : item.state == 'date_fixed'-->
<!--}"-->
<!--&gt;-->
<!--<td>-->
<!--<v-chip @click="getClientProfile(item.user.id)">-->
<!--{{item.user.full_name}}-->
<!--</v-chip>-->

<!--</td>-->
<!--<td>-->
<!--<template v-if="item.state == 'date_fixed' || item.state == 'used'">-->
<!--{{item.fixed_date}}-->
<!--</template>-->
<!--<template v-else>-->
<!--{{item.request_date}}-->
<!--</template>-->
<!--</td>-->

<!--<td>-->
<!--<template v-if="item.state == 'date_fixed' || item.state == 'used'">-->
<!--{{item.fixed_time}}-->
<!--</template>-->
<!--<template v-else>-->
<!--{{item.request_time}}-->
<!--</template>-->
<!--</td>-->
<!--<td>-->
<!--<template v-if="hasCounselor(item)">-->
<!--{{item.doctor.full_name}}-->
<!--</template>-->
<!--</td>-->
<!--<td>-->
<!--{{resolveState(item.state)}}-->
<!--</td>-->
<!--<td>-->
<!--<v-btn @click="editSessionState(item)" x-small text fab>-->
<!--<v-icon small>fas fa-edit</v-icon>-->
<!--</v-btn>-->
<!--<v-btn x-small class="primary mx-1" text fab-->

<!--:to="`assignment/user/${item.user.id}/cycle/${item.cycle.id}/request/${item.id}`">-->
<!--<v-icon small>fas fa-tv</v-icon>-->
<!--</v-btn>-->
<!--<template v-if="item.state != 'used'">-->
<!--<v-btn fab x-small class="red white&#45;&#45;text elevation-0 mx-1"-->
<!--@click="showResetDialog(item)">-->

<!--<v-icon small class="">-->
<!--fas fa-trash-->
<!--</v-icon>-->
<!--</v-btn>-->
<!--</template>-->
<!--<template-->
<!--v-if="item.state == 'doctor_assigned' || item.state == 'date_fixed' || item.state == 'used'">-->
<!--<v-btn fab class="elevation-0" small @click="showDetails(item)">-->
<!--<v-icon small class="mx-2">-->
<!--fas fa-search-->
<!--</v-icon>-->
<!--</v-btn>-->
<!--</template>-->
<!--</td>-->
<!--</tr>-->
<!--</template>-->

<!--&lt;!&ndash;<template v-slot:item.actions="{ item }">&ndash;&gt;-->
<!--&lt;!&ndash;&ndash;&gt;-->

<!--&lt;!&ndash;&lt;!&ndash;<div @click="getClientProfile(item.user.id)" style="cursor: pointer;">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;{{ item.user.full_name }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</template>&ndash;&gt;-->
<!--</v-data-table>-->
<!--</v-card>-->
<!--<div class="text-center" v-if="!fiveSession">-->
<!--<v-pagination-->
<!--v-model="page"-->
<!--:length="total"-->
<!--&gt;</v-pagination>-->
<!--</div>-->
<!--</v-container>-->
<!--</div>-->
<!--</template>-->

<!--<script>-->
<!--/*eslint-disable*/-->
<!--import {mapGetters} from 'vuex';-->
<!--import moment from 'moment-jalaali';-->

<!--import {EventBus} from "../../event-bus";-->
<!--import {adminSessionFilters, dateSessionFilters, clientDetailSessionsFilters} from "../../searchFilters";-->
<!--import TableOptions from '@/components/TableOptions.vue';-->
<!--import ChangeState from '@/components/ChangeState.vue';-->

<!--export default {-->
<!--components: {-->
<!--TableOptions,-->
<!--ChangeState-->
<!--},-->
<!--props: {-->
<!--title: {-->
<!--default: 'جلسات',-->
<!--type: String-->
<!--},-->
<!--fiveSession: Boolean-->
<!--},-->
<!--data() {-->
<!--return {-->
<!--doctors: [],-->
<!--itemToEdit: null,-->
<!--editState: true,-->
<!--counselorStates: [-->
<!--'doctor_assigned',-->
<!--'used',-->
<!--'date_fixed'-->
<!--],-->
<!--requesting: false,-->
<!--options: {},-->
<!--searchFilters: [],-->
<!--resetItem: null,-->
<!--showResetDialogSwitch: false,-->
<!--rerender: false,-->
<!--// loading: true,-->
<!--search: '',-->
<!--headers: [-->
<!--{-->
<!--text: 'نام و نام خانوادگی',-->
<!--align: 'start',-->
<!--sortable: false,-->
<!--value: 'user.full_name',-->
<!--},-->
<!--{text: 'تاریخ', value: 'fixed_date'},-->
<!--{text: 'ساعت', value: 'fixed_time'},-->
<!--{text: 'مشاور', value: 'doctor.full_name'},-->
<!--{text: 'وضعیت', value: 'state'},-->
<!--{text: 'عملیات', value: 'actions'},-->
<!--],-->
<!--desserts: [],-->
<!--date: moment().format("YYYY-MM-DD"),-->
<!--path: this.$router.currentRoute.name,-->
<!--dates: [-->
<!--{-->
<!--id: 1,-->
<!--title: 'امروز',-->
<!--code: moment().format("YYYY-MM-DD")-->
<!--},-->
<!--{-->
<!--id: 2,-->
<!--title: 'فردا',-->
<!--code: moment(new Date()).add(1, 'days').format("YYYY-MM-DD")-->
<!--},-->

<!--],-->
<!--page: 1,-->
<!--total: 1,-->
<!--sessions: []-->
<!--}-->
<!--},-->
<!--computed: {-->
<!--...mapGetters({-->
<!--allDoctors: 'getAdminAllDoctors'-->
<!--// sessions: 'getAdminSessions',-->
<!--// page: 'page',-->
<!--// total: 'total',-->
<!--})-->
<!--},-->
<!--watch: {-->
<!--editState(newVal) {-->
<!--if (!newVal) {-->
<!--this.closeEditStateForm();-->
<!--}-->
<!--},-->
<!--'$route': function () {-->
<!--this.getAdminSessions();-->
<!--},-->
<!--page() {-->
<!--this.getAdminSessions();-->
<!--this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);-->
<!--}-->
<!--},-->
<!--created() {-->
<!--this.fillDoctors();-->
<!--},-->
<!--methods: {-->
<!--fillDoctors(){-->
<!--let promise = new Promise((resolve, reject) => {-->
<!--if (!this.allDoctors.length) {-->
<!--this.$store.dispatch("getAllDoctors").then(res => {-->
<!--this.doctors = res.data.doctors;-->
<!--resolve();-->
<!--}).catch(err => {-->
<!--console.log(err);-->
<!--});-->
<!--} else {-->

<!--this.doctors = this.allDoctors;-->
<!--resolve();-->
<!--}-->
<!--})-->

<!--promise.then(() => {-->
<!--let mappedDoctors = this.doctors.map((doctor) => {-->
<!--return {-->
<!--title: doctor.full_name, value: doctor.doctor_profile.id-->
<!--};-->
<!--});-->
<!--adminSessionFilters.find((item) => {-->
<!--if (item.key == 'doctor_ids') {-->
<!--item.options = mappedDoctors;-->
<!--}-->
<!--});-->
<!--if (this.$route.name == 'sessions') {-->
<!--this.searchFilters = adminSessionFilters;-->
<!--} else if (this.$route.name == 'clientDetail') {-->
<!--this.searchFilters = clientDetailSessionsFilters;-->
<!--} else if (this.$route.name == 'dateSessions') {-->
<!--this.searchFilters = dateSessionFilters;-->
<!--}-->
<!--this.rerender = true;-->
<!--});-->
<!--},-->
<!--finishedEditingState() {-->
<!--EventBus.$emit('notify', 'green', undefined, 'با موفقیت تغییر یافت');-->
<!--this.closeEditStateForm();-->
<!--this.getAdminSessions();-->
<!--},-->
<!--closeEditStateForm() {-->
<!--this.itemToEdit = null;-->
<!--this.editState = false;-->
<!--},-->
<!--editSessionState(session) {-->
<!--this.itemToEdit = session;-->
<!--this.editState = true;-->
<!--},-->
<!--resolveState(state) {-->
<!--switch (state) {-->
<!--case 'used':-->
<!--return 'برگزار شده';-->
<!--break;-->
<!--case 'session_requested':-->
<!--return 'درخواست جلسه';-->
<!--break;-->
<!--case 'doctor_assigned':-->
<!--return 'تخصیص یافته';-->
<!--break;-->
<!--case 'date_fixed':-->
<!--return 'زمان ست شده';-->
<!--break;-->
<!--}-->
<!--},-->
<!--hasCounselor(session) {-->
<!--return this.counselorStates.includes(session.state);-->
<!--},-->
<!--applyFilters(options) {-->
<!--this.page = 1;-->
<!--this.options = options;-->
<!--this.getAdminSessions();-->
<!--},-->
<!--showDetails(item) {-->

<!--this.$router.push({name: 'adminSessionDetails', params: {sessionId: item.id}});-->
<!--},-->
<!--resetSession() {-->
<!--this.$store.dispatch('adminResetSession', this.resetItem.id).then(() => {-->
<!--EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد');-->
<!--this.showResetDialogSwitch = false;-->
<!--this.getAdminSessions();-->
<!--})-->
<!--},-->
<!--showResetDialog(item) {-->
<!--this.resetItem = item;-->
<!--this.showResetDialogSwitch = true;-->
<!--},-->

<!--getAdminSessions() {-->
<!--EventBus.$emit('setLoading', true);-->
<!--if (this.$router.currentRoute.name == 'sessions') {-->
<!--// this.loading = true;-->
<!--// let options = {-->
<!--//     'state': 'date_fixed|used',-->
<!--//     "orderBy": "session_date:desc"-->
<!--// };-->
<!--this.requesting = true;-->

<!--this.$store.dispatch('getAdminSessions', {page: this.page, options: this.options}).then((data) => {-->
<!--// console.log('Admin sessions: ', data);-->
<!--// // this.loading = false;-->
<!--// this.setAdminSessions(data.data);-->
<!--this.sessions = data.data.sessions;-->
<!--this.total = data.data.pagination.total_pages;-->
<!--this.requesting = false;-->
<!--}).finally(() => {-->
<!--EventBus.$emit('setLoading', false);-->
<!--})-->
<!--this.requesting = false;-->
<!--} else if (this.$router.currentRoute.name == 'dateSessions') {-->
<!--// this.loading = true;-->
<!--let options = {};-->
<!--this.requesting = true;-->
<!--this.$store.dispatch('getAdminSessions', {-->
<!--options: {-->
<!--from_date: moment().format("YYYY-MM-DD"),-->
<!--to_date: moment().add(1, 'days').format("YYYY-MM-DD"),-->
<!--...this.options-->
<!--},-->
<!--page: this.page-->
<!--}).then((data) => {-->
<!--this.requesting = false;-->
<!--// console.log('Admin sessions based on date: ', data);-->
<!--// this.setAdminSessions(data.data);-->
<!--// this.total = data.paginate.totalPages;-->
<!--// this.loading = false;-->
<!--this.sessions = data.data.sessions;-->
<!--this.total = data.data.pagination.total_pages;-->
<!--}).finally(() => {-->
<!--EventBus.$emit('setLoading', false);-->
<!--})-->
<!--} else if (this.$router.currentRoute.name == 'clientDetail') {-->
<!--// this.loading = true;-->
<!--this.requesting = true;-->
<!--this.$store.dispatch('getAdminSessions', {-->
<!--page: this.page,-->
<!--options: {-->
<!--user_id: this.$route.params.clientId,-->
<!--...this.options,-->
<!--}-->
<!--}).then((data) => {-->
<!--this.requesting = false;-->

<!--// console.log('Admin client sessions: ', data);-->
<!--// this.setAdminSessions(data.data);-->
<!--// this.total = data.paginate.totalPages;-->
<!--if (!this.options.hasOwnProperty('wantsExcel')) {-->
<!--this.sessions = data.data.sessions;-->
<!--this.total = data.data.pagination.total_pages;-->
<!--} else {-->
<!--delete this.options.wantsExcel;-->
<!--}-->

<!--}).finally(() => {-->
<!--EventBus.$emit('setLoading', false);-->
<!--})-->
<!--}-->
<!--},-->
<!--// nextPage() {-->
<!--//     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});-->
<!--//     console.log('page', this.page)-->
<!--// },-->
<!--// previousPage() {-->
<!--//     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});-->
<!--//     console.log('page', this.page)-->
<!--// },-->
<!--// specificPage(page) {-->
<!--//     this.$store.commit('SET_SPECIFIC_PAGE', page);-->
<!--//     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});-->
<!--//     console.log('page', page)-->
<!--// },-->
<!--selectDate(el) {-->
<!--this.date = el.code;-->
<!--this.$router.push(`${el.code}`);-->
<!--// this.loading = true;-->
<!--this.getAdminSessions();-->
<!--},-->
<!--setAdminSessions(data) {-->
<!--this.sessions = [];-->
<!--data.forEach(session => {-->
<!--this.sessions.push(session);-->
<!--session['date'] = session.jdatetime.split(' ')[0];-->
<!--session['time'] = session.jdatetime.split(' ')[1];-->
<!--session['session'] = session.sessionCount + ' از ' + session.orderCount;-->
<!--});-->
<!--},-->
<!--getClientProfile(id) {-->
<!--this.$router.push({name: 'clientDetail', params: {clientId: id}});-->
<!--},-->
<!--}-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->
