<template>
  <div>
    <v-container fluid class="mt-5">
      <v-dialog v-model="actionTestModal" width="500px">
        <v-card>
          <v-card-title>
            <template v-if="testActionType == 'add'">
              افزودن تست
            </template>
            <template v-else>
              ویرایش تست
            </template>
          </v-card-title>
          <v-card-text>
            <v-form ref="testForm" @submit.prevent="testActionType == 'add' ? storeTest() : editTest() ">
              <v-text-field :rules="requiredRules" v-model="targetTest.name" outlined dense label="نام"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="testActionType == 'add' ? storeTest() : editTest() " color="primary">
              <template v-if="testActionType == 'add'">
                افزودن
              </template>
              <template v-else>
                ویرایش
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-card-title class="justify-space-between">
          <div>
            مدیریت تست‌ها
          </div>
          <div>
            <v-btn color="primary" elevation="0" @click="actionTestModal = true;testActionType = 'add'">
              افزودن تست
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
              v-if="tests"
              hide-default-footer
              :items="tests"
              :headers="headers"
              class="elevation-1"
              disable-pagination
          >
            <template v-slot:item.status="{item}">
              <template v-if="item.status == 'enable'">
                فعال
              </template>
              <template v-else>
                غیر فعال
              </template>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn color="primary" fab x-small elevation="0" @click="openEditModal(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="green" fab x-small elevation="0" class="mr-2"
                     :to="{name:'matchMakingQuestions',params:{testId:item.id}}">
                <v-icon color="white">mdi-forum</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      headers: [
        {
          text: 'کد تست',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {text: 'نام تست', value: 'name'},
        {text: 'وضعیت', value: 'status'},
        {text: '', value: 'actions'},
      ],
      tests: null,
      targetTest: {},
      actionTestModal: false,
      testActionType: null,
    }
  },
  methods: {
    getTests() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getMatchMakingTest').then((resp) => {
        this.tests = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    storeTest() {
      if (this.$refs.testForm.validate()) {
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('storeMatchMakingTest', this.targetTest).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.actionTestModal = false
          this.$refs.testForm.reset()
          this.getTests()
        }).finally(() => {
          EventBus.$emit('setLoading', true)
        })
      }
    },
    openEditModal(item) {
      this.targetTest = JSON.parse(JSON.stringify(item))
      this.actionTestModal = true
      this.testActionType = 'edit'
    },
    editTest() {
      if (this.$refs.testForm.validate()) {
        let payload = {
          name: this.targetTest.name,
          test_id: this.targetTest.id
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('editMatchMakingTest', payload).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.actionTestModal = false
          this.getTests()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  mounted() {
    this.getTests()
  }
}
</script>

<style scoped>

</style>