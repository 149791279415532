<template>
  <div>
    <AdminFinancialAddNewInvoice></AdminFinancialAddNewInvoice>
  </div>
</template>

<script>
import AdminFinancialAddNewInvoice from "@/views/admin/AdminFinancialAddNewInvoice";

export default {
  components: {
    AdminFinancialAddNewInvoice,
  },
  data() {
    return {
      clients: []
    }
  },
  computed: {

  },
  methods: {
    // getAllClients() {
    //   this.$store.dispatch("getAllPatients").then(res => {
    //     this.clients = res.data.clients;
    //   });
    // },
  },
  created() {
    // this.getAllClients();
  }
}
</script>

<style scoped>

</style>