<template>
  <div>
    <v-dialog v-model="roleEditDialog" v-if="targetUser" width="500px">
      <v-card>
        <v-card-title>
          ویرایش نقش‌های کاربر
          {{ targetUser.full_name }}
        </v-card-title>
        <v-card-text>
          <template v-if="roles">
            <div class="roleArea mt-2">
              <v-checkbox v-model="selectedRoles" v-for="(role , i) in roles" dense :label="role.name" :value="role.id"
                          :key="i"></v-checkbox>
            </div>
          </template>
          <div class="text-center mt-5" v-else>
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" elevation="0" @click="attachRoleUser">
            ویرایش
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-card-title>
          مدیریت کاربران
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              v-if="users"
              :items="users"
              disable-pagination
              hide-default-footer
              :items-per-page="5"
              class="elevation-1"
          >
            <template v-slot:item.roles="{item}">
              <v-chip v-for="(role , i) in item.roles" :key="i" small class="mx-1">
                {{ role.name }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn color="primary" x-small elevation="0" @click="editModalOpen(item)">
                ویرایش نقش
              </v-btn>
            </template>
          </v-data-table>

        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      headers: [
        {text: 'کد ', value: 'id'},
        {text: 'نام کاربر ', value: 'full_name'},
        {text: 'نقش‌ها ', value: 'roles'},
        {
          text: 'عملیات',
          value: 'actions'
        }
      ],
      users: null,
      roleEditDialog: false,
      targetUser: null,
      roles: null,
      selectedRoles: [],
    }
  },
  mounted() {
    this.getAdmins()
    this.getRoles()
  },
  methods: {
    editModalOpen(target) {
      this.targetUser = target
      this.prepareSelectedRoles()
      this.roleEditDialog = true
    },
    getAdmins() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getAdminsWithRoles').then((resp) => {
        this.users = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    prepareSelectedRoles() {
      this.selectedRoles = []
      this.targetUser.roles.forEach((role) => {
        this.selectedRoles.push(role.id)
      })
    },
    getRoles() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getRoles').then((resp) => {
        this.roles = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    attachRoleUser() {
      let payload = {
        user_id: this.targetUser.id,
        role_ids: this.selectedRoles
      }
      this.$store.dispatch('attachRoleToUser', payload).then(() => {
        this.roleEditDialog = false
        this.getAdmins()
      })
    }
  }
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: -10px !important;
  padding: 0px;
}

.roleArea {
  padding-top: 20px;
  overflow-x: auto;
  height: 200px;
}
</style>