<template>
  <div>
    <UserReport/>
  </div>
</template>

<script>
    import UserReport from "@/components/Admin/reports/UserReportComponent";

    export default {
        components: {
          UserReport,
        }

    }
</script>

<style scoped>

</style>