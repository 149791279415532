/*eslint-disable*/
import Vue from 'vue'
import Vuex from 'vuex'
import {ajaxPost, ajaxGet, ajaxDownload} from '../../utilities.js';
import LS from '../../LocalStorateAPI';
import {EventBus} from "../../event-bus";
import Axios from "axios";
import counselorProfile from "../../components/profiles/counselorProfile";
import FileDownload from "js-file-download";

Vue.use(Vuex);

const state = {
    adminDashboard: {},
    adminSessions: [],
    adminUsers: [],
    adminOrders: [],
    adminRequests: [],
    counselorProfile: JSON.parse(localStorage.getItem('counselorProfile')),
    clientProfile: {},
    notifications: [],
    filters: {
        'financial_date': 'payment_date',
        'financial_final_amount': 'amount',
    },
    adminAllDoctors: LS.get('allDoctors'),
    adminAllClients: LS.get('adminAllClients'),
    currencies: [
        {title: 'دلار', value: 'dollar'},
        {title: 'تومان', value: 'rial'},
        {title: 'دلار استرالیا', value: 'aud'},
        {title: 'دلار کانادا', value: 'cad'},
        {title: 'پوند', value: 'gbp'},
        {title: 'یورو', value: 'euro'},
    ],
    editingDoctorId: null,
};
const mutations = {
    SET_EDITING_DOCTOR(state, payload) {
        state.editingDoctorId = payload
    },
    SET_ALL_DOCTORS(state, res) {
        state.adminAllDoctors = res.data.doctors;
        LS.set('allDoctors', state.adminAllDoctors);
    },
    SET_ALL_Clients(state, res) {
        state.adminAllClients = res.data.clients;
        LS.set('adminAllClients', state.adminAllClients);
    },
    setAdminDashboard(state, data) {
        state.adminDashboard = data;
        localStorage.setItem('adminDashboard', data);
    },
    setAdminSessions(state, sessions) {
        state.adminSessions = [];
        sessions.forEach(session => {
            state.adminSessions.push(session);
            session['date'] = session.jdatetime.split(' ')[0];
            session['time'] = session.jdatetime.split(' ')[1];
            session['session'] = session.sessionCount + ' از ' + session.orderCount;
        });
    },
    setAdminUsers(state, clients) {
        state.adminUsers = [];
        clients.forEach(client => {
            state.adminUsers.push(client);
            client['fullname'] = client.firstname + ' ' + client.lastname;
        });
    },
    setAdminOrders(state, orders) {
        state.adminOrders = [];
        orders.forEach(order => {
            state.adminOrders.push(order);
            order['customer'] = order.user.firstname + ' ' + order.user.lastname;
            order['date'] = order.jdatetime.split(' ')[0];
            order['time'] = order.jdatetime.split(' ')[1];
        });
    },
    setAdminRequests(state, requests) {
        state.adminRequests = [];
        requests.forEach(request => {
            state.adminRequests.push(request);
            request['customer'] = request.customer.firstname + ' ' + request.customer.lastname;
            request['counselor'] = request.counselor.firstname + ' ' + request.counselor.lastname;
            request['date'] = request.datetime.split(' ')[0];
            request['time'] = request.datetime.split(' ')[1];
            switch (request.type) {
                case "sessionTime_change":
                    request['typeTitle'] = 'تغییر زمان جلسه';
                    break;
                case "counselor_change":
                    request['typeTitle'] = 'تغییر مشاور';
                    break;

            }
        })
    },
    setCouselorProfile(state, profile) {
        state.counselorProfile = profile;
        LS.set('counselorProfile', profile);
        console.log('here', profile)
    },
    setClientProfile(state, profile) {
        state.clientProfile = profile;
    },
    setNavbarNotifications(state, notifications) {
        state.notifications = notifications
    }

};
const actions = {
    setEditingDoctorId({commit}, payload) {
        commit('SET_EDITING_DOCTOR', payload)
    },
    adminSearchContactsAJAX({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/contacts/search`, data).then((resp) => {
            return resp;
        })
    },

    adminGetContacts({rootState}) {
        return ajaxGet(rootState.baseUrl + '/admin/contacts').then((resp) => {
            return resp;
        })
    },
    adminClientGetContacts({rootState}, data) {
        return ajaxGet(`${rootState.baseUrl}/admin/clients/${data.id}/contacts`).then((resp) => {
            return resp;
        })
    },
    adminClientGetConversationMessages({rootState}, {clientId, id, page}) {
        return ajaxGet(rootState.baseUrl + `/admin/clients/${clientId}/conversations/${id}/getbyuser?page=${page}`).then((resp) => {
            console.log(resp);
            return resp;
        })
    },
    adminGetConversationMessages({rootState, commit}, {id, page}) {
        return ajaxGet(rootState.baseUrl + `/admin/conversations/${id}/getbyuser?page=${page}`).then((resp) => {
            commit('UPDATE_NEW_MESSAGES', resp.data.newMessages);
            return resp;
        })
    },
    adminSendConversationMessage({rootState}, {id, payload}) {
        return ajaxPost(rootState.baseUrl + `/admin/conversations/${id}/messages`, payload).then((resp) => {
            return resp;
        })
    },

    getAdminSessionLinks({rootState}, data) {
        return ajaxGet(rootState.baseUrl + `/admin/sessions/${data.id}/getlinks`).then((data) => {
            return data;
        })
    },
    getAdminClientPurchasedPackages({rootState}, data) {
        return ajaxGet(rootState.baseUrl + `/admin/clients/${data.id}/purchasedpackages?page=${data.page}`, data.payload).then((data) => {
            return data;
        })
    },
    adminChangeCounselorPassword({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/doctors/${data.id}/changepassword`, data.payload).then((data) => {
            return data;
        })
    },
    adminAddClientNewCycle({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/clients/${data.id}/addcycle`, data.payload).then((data) => {
            return data;
        })
    },
    changePaymentDate({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/financial/${data.paymentId}/changedate`, data.payload).then((data) => {
            return data;
        })
    },
    adminUserWalletRequest({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/wallet/${data.userId}`, data.payload).then((data) => {
            return data;
        })
    },
    adminRequestRefund({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/financial/refund/${data.id}`, data.payload).then((data) => {
            return data;
        })
    },

    getAdminRefundRequests: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/financial/refunds?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    adminAcceptRefundRequest: ({commit, state, rootState}, paymentId) => {
        return ajaxGet(rootState.baseUrl + `/admin/financial/refund/${paymentId}/accept`, true).then((data) => {
            return data;
        })
    },
    adminRejectRefundRequest: ({commit, state, rootState}, paymentId) => {
        return ajaxGet(rootState.baseUrl + `/admin/financial/refund/${paymentId}/reject`, true).then((data) => {
            return data;
        })
    },
    adminchangeClientPassword({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/clients/${data.id}/changepassword`, data.payload).then((data) => {
            return data;
        })
    },
    getAdminCoupons({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/searchCoupons?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },

    getAdminPackages({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/packages/search?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    adminTogglePackage({rootState}, id) {
        return ajaxGet(rootState.baseUrl + `/admin/packages/${id}/toggle`).then((data) => {
            return data;
        })
    },
    adminToggleCoupon({rootState}, id) {
        return ajaxGet(rootState.baseUrl + `/admin/coupons/${id}/toggle`).then((data) => {
            return data;
        })
    },
    adminToggleGiftCode({rootState}, id) {
        return ajaxGet(rootState.baseUrl + `/admin/giftcodes/${id}/toggle`).then((data) => {
            return data;
        })
    },
    adminSelectMultipleSessionRequestTime: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + `/admin/clients/sessions/request/${data.id}/setMultipleTimes`, data.payload).then((data) => {
            return data;
        })
    },
    saveNewPrices({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/packages/${payload.id}/prices`, {prices: payload.prices}).then((data) => {
            return data;
        })
    },
    addAdminPackages({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/packages", data).then((data) => {
            return data;
        })
    },
    AdminEditPackages({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/packages/${data.id}`, data.payload).then((data) => {
            return data;
        })
    },
    AdminChangePackageOrder({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/packages/${data.id}/editsequence`, data.payload).then((data) => {
            return data;
        })
    },
    addAdminCoupons({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/coupons", data).then((data) => {
            return data;
        })
    },
    AdminEditCoupon({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/coupons/${data.id}`, data.payload).then((data) => {
            return data;
        })
    },
    adminAddPayment({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/transactions/add", data).then((data) => {
            return data;
        })
    },


    getCycle: ({commit, state, rootState}, {userId, cycleId, sessionId, doctorId}) => {
        let url = rootState.baseUrl + `/admin/sessions/${userId}/${cycleId}/${sessionId}`;
        if (doctorId != "") {
            url += `/${doctorId}`;
        }
        return ajaxGet(url).then((resp) => {
            return resp.data;
        });
    },
    getMentalDisorders({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/mentaldisorder/list?disorder_group=${payload.disorder_group}`)
    },
    getMentalDisordersDoctor({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/mentaldisorder/doctors/sort?mental_disorder_id=${payload.id}`)
    },
    attachDoctor({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/suggest/user/doctor`, payload)
    },
    getUsersList({commit, rootState}, data) {
        // console.log(data);
        return ajaxGet(rootState.baseUrl + "/admin/users/list" + data).then((resp) => {
            return resp.data;
        });
        // return new Promise((resolve, reject) => {
        //     axios({
        //         url: rootState.baseUrl + "/admin/users/list" + data,
        //         method: "GET",
        //         // data,
        //         headers: {
        //             Authorization: "Bearer " + (localStorage.getItem("token") || "")
        //         }
        //     })
        //         .then(resp => {
        //
        //             if (resp.data.statusCode && resp.data.statusCode != 200) {
        //                 reject(resp);
        //             }
        //
        //             resolve(resp.data);
        //
        //         })
        //         .catch(err => {
        //             if (err.response.status == 401) {
        //                 console.log('user is not logged in ');
        //                 this.dispatch('logout');
        //             }
        //             reject(err);
        //         });
        // });
    },
    getAdminDashboard: ({commit, state, rootState}) => {
        return ajaxGet(rootState.baseUrl + "/admin/dashboard", true).then((data) => {
            return data;
        })
    },
    getMarketingDashboard({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/marketing-dashboard", data).then((data) => {
            return data;
        })
    },
    getAdminUsers: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/admin/clients?page=" + `${data.page}`, true).then((data) => {
            return data;
        })
    },
    getAdminDoctors: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/admin/doctors?page=" + `${data.page}`, true).then((data) => {
            return data;
        })
    },
    getAdminNotifications: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + `/notifications?page=${data.page}&status=${data.status}&type=${data.type}&perPage=15`, true).then((data) => {
            return data;
        })
    },
    getAdminNavbarNotifications: ({commit, state, rootState}) => {
        return ajaxGet(rootState.baseUrl + "/notifications", true).then((resp) => {
            commit('setNavbarNotifications', resp.data.notifications)
        })
    },
    getUserProfile: ({commit, state, rootState}, {userid}) => {
        return new Promise((resolve, reject) => {
            ajaxGet(rootState.baseUrl + "/admin/clients/" + `${userid}`, true).then((data) => {
                commit('setClientProfile', data.data.client);
                resolve(data);
            })
        })
    },
    getRequestUserProfile: ({commit, state, rootState}, {userid}) => {
        return ajaxGet(rootState.baseUrl + "/admin/clients/" + `${userid}`, true).then((data) => {
            return (data);
        })
    },
    getAllDoctors({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + "/admin/doctors/all", true).then((data) => {
            commit('SET_ALL_DOCTORS', data);
            return (data);
        })
    },
    getAllPatients({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + "/admin/clients/all", true).then((data) => {
            // commit('SET_ALL_Clients', data);
            return (data);
        })
    },
    getAllPackages({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + "/admin/packages/all", true).then((data) => {
            return (data);
        })
    },
    getAllPackagesInCurrency: ({commit, state, rootState}, payload) => {
        return ajaxPost(rootState.baseUrl + `/admin/packages/all`, payload).then((data) => {
            return data;
        })
    },
    getAllCoupons({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + "/admin/allCoupons", true).then((data) => {
            return (data);
        })
    },
    getAllPaymentMethods({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/paymentmethods/search", payload).then((data) => {
            return (data);
        })
    },
    deleteMetaMethod({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/paymentmethodmeta/${payload.paymentId}/${payload.metaId}/delete`, true).then((data) => {
            return (data);
        })
    },
    addMetaMethod({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/paymentmethodmeta/${payload.methodId}`, payload).then((data) => {
            return data;
        })
    },
    editMetaMethod({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/paymentmethodmeta/${payload.paymentId}/${payload.metaId}/update`, payload).then((data) => {
            return data;
        })
    },

    editPaymentMethod({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/paymentmethods/${payload.paymentId}`, payload).then((data) => {
            return data;
        })
    },


    adminSelectSessionRequestTime: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + `/admin/clients/sessions/request/${data.id}`, data.payload).then((data) => {
            return data;
        })
    },

    adminAddNewPaymentMethod({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/paymentmethods", payload).then((data) => {
            return (data);
        })
    },
    getCounselorProfile: ({commit, state, rootState}, {userid}) => {
        return ajaxGet(rootState.baseUrl + "/admin/doctors/" + `${userid}`, true).then((data) => {
            return (data);
        })
    },
    setReservedTime: ({commit, state, rootState}, payload) => {
        return ajaxPost(rootState.baseUrl + "/admin/doctors/" + `${payload.doctor_id}/reserved-time`, payload).then((data) => {
            return (data);
        })
    },

    getPreSessionTime: ({commit, state, rootState}) => {
        return ajaxGet(rootState.baseUrl + "/admin/presession/get-presession-calendar", true).then((data) => {
            return (data);
        })
    },
    getPreSessionAgents: ({commit, state, rootState}) => {
        return ajaxGet(rootState.baseUrl + "/admin/presession/get-agents", true).then((data) => {
            return (data);
        })
    },
    AdminSaveCounselorProfile: ({commit, state, rootState}, {data, userid}) => {
        return ajaxPost(rootState.baseUrl + "/admin/doctors/" + `${userid}`, data).then((data) => {
            return (data);
        })
    },
    saveUserProfile: ({commit, state, rootState}, {userId, data}) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/clients/${userId}`, data, true).then(({data}) => {
                resolve(data);
            })
        })
    },
    getAdminClientSessions: ({commit, state, rootState}, data) => {

        return ajaxGet(rootState.baseUrl + `/admin/clients/${data.userid}/sessions?page=${data.page}`, true).then((data) => {
            return data;
        })
    },

    adminGetClientCycleDoctors: ({commit, state, rootState}, {cycleId, userId, indebt = false, page = 1}) => {

        return ajaxPost(rootState.baseUrl + `/admin/clients/cycles/${cycleId}/${userId}/sessionRequestDoctors?page=${page}`, {
            indebt
        }).then((data) => {
            return data;
        })
    },

    adminSendSessionRequest({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/sessions/request", payload, true).then((data) => {
            return data;
        })
    },

    adminClientsRequestDoctors({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/request/doctors", payload, true).then((data) => {
            return data;
        })
    },

    adminClientRequestSubmit({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/request/submit", payload, true).then((data) => {
            return data;
        })
    },

    adminSessionChangeState({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/sessions/changestate", payload, true).then((data) => {
            return data;
        })
    },
    adminSessionBulkChangeState({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/sessions/changestate-sessions", payload, true).then((data) => {
            return data;
        })
    },
    getAdminClientOrders: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/transactions?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    getAdminClientSurvey: ({commit, state, rootState}, {clientId}) => {
        return ajaxGet(`${rootState.baseUrl}/admin/clients/${clientId}/preferences`).then((data) => {
            return data;
        })
    },
    getAdminSubscribedUsers: ({commit, state, rootState}, payload) => {
        return ajaxPost(`${rootState.baseUrl}/admin/marketing/users?page=${payload.page}`, payload.option).then((data) => {
            return data;
        })
    },
    saveCounselorTimebooks: ({commit, state, rootState}, consultInfo) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/doctors/${consultInfo.id}/times`, consultInfo, true).then(data => {
                resolve(data);
            })
        })
    },
    getAdminSubscribedUsersExport({commit, state, rootState}) {
        return ajaxDownload(rootState.baseUrl + `/admin/marketing/users/export`, 'xlsx').then((data) => {
            return (data);
        })
    },
    adminCounselorRemoveTimes: ({commit, state, rootState}, data) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/doctors/${data.id}/times/delete`, data.payload, true).then(data => {
                resolve(data);
            })
        })
    },
    adminCounselorsRemoveTimes: ({commit, state, rootState}, data) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/doctors/times/delete`, data.payload, true).then(data => {
                resolve(data);
            })
        })
    },
    adminClientsaveTimebooks: ({commit, state, rootState}, consultInfo) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/clients/${consultInfo.id}/times`, consultInfo, true).then(data => {
                resolve(data);
            })
        })
    },
    adminClientRemoveTimes: ({commit, state, rootState}, data) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/clients/${data.id}/times/delete`, data.payload, true).then(data => {
                resolve(data);
            })
        })
    },

    addNewCounselor: ({commit, state, rootState}, payload) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + '/admin/doctors', payload, true).then(data => {
                resolve(data);
            })
        })
    },

    addNewUser: ({commit, state, rootState}, payload) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + '/admin/clients', payload).then(data => {
                resolve(data);
            })
        })
    },

    getAdminSessions: ({commit, state, rootState}, data) => {
        let perPage = data.hasOwnProperty('perPage') ? data.perPage : 10;
        return ajaxPost(rootState.baseUrl + `/admin/sessions?page=${data.page}&perPage=${perPage}`, data.options).then((data) => {
            return data;
        })
    },

    getAdminDoctorSessionsPayment: ({commit, state, rootState}, data) => {
        let perPage = data.hasOwnProperty('perPage') ? data.perPage : 10;
        return ajaxPost(rootState.baseUrl + `/admin/doctor-payment-sessions?page=${data.page}&perPage=${perPage}`, data.options).then((data) => {
            return data;
        })
    },

    getAdminDoctorFees: ({commit, state,rootState}, payload) => {
        return ajaxPost(rootState.baseUrl + "/admin/doctors-fee/search", payload,true)
    },
    archiveAdminDoctorFees: ({commit, state,rootState}, payload) => {
        return ajaxPost(rootState.baseUrl + "/admin/doctors-fee/archive", payload,true)
    },

    adminResetSession: ({commit, state, rootState}, id) => {
        return ajaxGet(rootState.baseUrl + `/admin/sessions/${id}/reset`, true).then((data) => {
            return data;
        })
    },
    adminGetSession: ({commit, state, rootState}, id) => {
        return ajaxGet(rootState.baseUrl + `/admin/sessions/${id}`, true).then((data) => {
            return data;
        })
    },

    getAdminSessionsWithDate: ({commit, state, rootState}, data) => {
        // return new Promise((resolve, reject) => {
        //     ajaxGet(rootState.baseUrl + "/admin/sessions/list/?date=" + `${date}`, true).then((data) => {
        //         console.log('admin sessions : ', data );
        //         commit('SET_PAGINATE', data.paginate, { root: true });
        //         commit('setAdminSessions', data.data);
        //         resolve(data);
        //     })
        // })

        return ajaxGet(rootState.baseUrl + "/admin/sessions/list/?date=" + `${data.date}` + "&page=" + `${data.page}`, true).then((data) => {
            return data;
        })
    },
    getAdminOrders: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/transactions?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    getAdminPaymentRequests: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/financial/requests?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    getAdminFinancialRecords: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/financial/records?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    adminRejectPaymentRequest: ({commit, state, rootState}, paymentId) => {
        return ajaxGet(rootState.baseUrl + `/admin/financial/requests/${paymentId}/reject`, true).then((data) => {
            return data;
        })
    },
    adminAcceptPaymentRequest: ({commit, state, rootState}, paymentId) => {
        return ajaxGet(rootState.baseUrl + `/admin/financial/requests/${paymentId}/accept`, true).then((data) => {
            return data;
        })
    },
    getClientsList: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/clients/search?perPage=10&page=" + `${data.page}`, data.options, true).then((data) => data)
    },
    getClientsListAddPayment: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/clients/search?perPage=300&page=" + `${data.page}`, data.options, true).then((data) => data)
    },
    getClientsListCalendar({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/search-for-calendar?perPage=300&page=" + `${data.page}`, data.options, true).then((data) => data)
    },
    resolveUserDebt({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/clients/sessions/createExtraSession", data).then((data) => data)
    },
    getClientsListInvoice: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/clients/search?perPage=100", data.options, true).then((data) => data)
    },
    getDoctorsList: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/admin/doctors/search-doctors?perPage=10&page=" + `${data.page}`, data.options, true).then((data) => data)
    },
    getNotimeUsers: ({commit, state, rootState}, data) => {
        // return new Promise((resolve, reject) => {
        //     ajaxGet(rootState.baseUrl + "/admin/users/notime", true).then((data) => {
        //         console.log('admin notime users : ', data );
        //         commit('SET_PAGINATE', data.paginate, { root: true });
        //         commit('setAdminUsers', data.data);
        //         resolve(data);
        //     })
        // })

        return ajaxGet(rootState.baseUrl + "/admin/users/notime?page=" + `${data.page}`, true).then((data) => {
            return data;
        })
    },
    getNotconfirmtimeUsers: ({commit, state, rootState}, data) => {
        // return new Promise((resolve, reject) => {
        //     ajaxGet(rootState.baseUrl + "/admin/users/notconfirmtime", true).then((data) => {
        //         console.log('admin notconfirmtime users : ', data );
        //         commit('SET_PAGINATE', data.paginate, { root: true });
        //         commit('setAdminUsers', data.data);
        //         resolve(data);
        //     })
        // })

        return ajaxGet(rootState.baseUrl + "/admin/users/notconfirmtime?page=" + `${data.page}`, true).then((data) => {
            return data;
        })
    },
    getAdminRequests: ({commit, state, rootState}, data) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + `/admin/tickets?page=${data.page}`, data.options).then((data) => {
                // console.log('admin request users : ', data);
                // commit('SET_PAGINATE', data.paginate, {root: true});
                // commit('setAdminRequests', data.data);
                resolve(data);
            })
        })
    },
    search: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/admin/users/search/" + data, true).then((data) => {
            console.log('search: ', data);
            return data;
        })
    },
    updateTicketState: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/admin/tickets/" + data, true).then((data) => {
            return data;
        })
    },

    adminSaveProfile({commit, state, rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/profile", data).then((data) => {
            return data;
        })
    },

    adminCounselorGetResume({commit, state, rootState}, doctorId) {
        return ajaxGet(rootState.baseUrl + `/admin/doctors/${doctorId}/resume`, true).then((data) => {
            return data;
        })
    },
    adminCounselorAddToResume({commit, state, rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/doctors/${data.doctorId}/resume`, data.payload).then((data) => {
            return data;
        })
    },
    adminCounselorEditResumeItem({commit, state, rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/doctors/${data.doctorId}/updateResume/${data.itemId}`, data.payload).then((data) => {
            return data;
        })
    },
    adminCounselorRemoveFromResume({commit, state, rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/doctors/${payload.doctorId}/deleteResume/${payload.itemId}`).then((data) => {
            return data;
        })
    },
    adminSepidarExports({commit, state, rootState}, {page, type}) {
        return ajaxGet(rootState.baseUrl + `/admin/reports?page=${page}&type=${type}`).then((data) => {
            return data;
        })
    },
    adminSepidarExportsSingle({commit, state, rootState}, reportId) {
        return ajaxGet(rootState.baseUrl + `/admin/reports/${reportId}`).then((data) => {
            return data;
        })
    },
    adminGetUsersChart({rootState}, options) {
        return ajaxPost(rootState.baseUrl + `/admin/charts/users`, options).then((resp) => {
            return resp;
        })
    },
    adminAddPaymentToCart({rootState}, options) {
        return ajaxPost(rootState.baseUrl + `/admin/financial/requests/${options.cart_id}/addpayment`, options.payload).then((resp) => {
            return resp;
        })
    },
    adminGetFactors({rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/admin/cartTransactions?page=" + `${data.page}`, data.options).then((data) => {
            return data;
        })
    },
    adminGetSingleFactor({rootState}, data) {
        return ajaxGet(rootState.baseUrl + `/admin/cartTransactions/${data.factorId}`).then((data) => {
            return data;
        })
    },
    getAdminLog({commit, state, rootState}, {page}) {
        return ajaxGet(rootState.baseUrl + `/admin/logs?page=${page}`).then((data) => {
            return data;
        })
    },
    getAllCurrencies({commit, state, rootState}, {page}) {
        return ajaxGet(rootState.baseUrl + `/admin/currencies?page=${page}`).then((data) => {
            return data;
        })
    },
    adminEditConversionRate({rootState}, {currencyId, payload}) {
        return ajaxPost(rootState.baseUrl + `/admin/currencies/${currencyId}`, payload).then((data) => {
            return data;
        })
    },
    adminUpdateGiftCode({rootState}, {giftCodeId, payload}) {
        return ajaxPost(rootState.baseUrl + `/admin/giftcodes/${giftCodeId}`, payload).then((data) => {
            return data;
        })
    },
    adminAddGiftCode({rootState}, {payload}) {
        return ajaxPost(rootState.baseUrl + `/admin/giftcodes`, payload).then((data) => {
            return data;
        })
    },
    adminSettleFactor({commit, state, rootState}, {factorId}) {
        return ajaxGet(rootState.baseUrl + `/admin/financial/settlefactor/${factorId}`).then((data) => {
            return data;
        })
    },
    getAdminGiftCodes({commit, state, rootState}, {page = 1}) {
        return ajaxGet(rootState.baseUrl + `/admin/giftcodes?page=${page}`).then((data) => {
            return data;
        })
    },

    getAdminPackageConditions({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + `/admin/packages/conditions/list`).then((data) => {
            return data;
        })
    },

    getAccountManagersDashboard({rootState}) {
        return ajaxGet(rootState.baseUrl + `/admin/account-managers-dashboard`)
    },
    getTestStatistics({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/psytest/statistics?from_date=${payload.from_date}&to_date=${payload.to_date}`)
    },
    readNotification({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/readNotifications`, payload)
    },
    // ,
    // adminSepidarUserExports({commit, state, rootState}, reportId) {
    //     return ajaxGet(rootState.baseUrl + `/admin/reports/${reportId}`).then((data) => {
    //         return data;
    //     })
    // },
    // adminSepidarSessionExports({commit, state, rootState}, reportId) {
    //     return ajaxGet(rootState.baseUrl + `/admin/reports/${reportId}`).then((data) => {
    //         return data;
    //     })
    // },
    adminSendInvoice({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/paypal/invoice`, payload);
    },
    setSessionTimeCalendar({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/clients/sessions/session/create', payload)
    },
    deleteReservation({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/doctors/${payload.doctor_id}/cancel-reserved-time`, payload)
    },
    getAccountManager({rootState}) {
        return ajaxGet(rootState.baseUrl + `/admin/account-managers`)
    },
    assignAccountManagerToDoctor({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/account-managers/${payload.accountManagerId}/assign-doctor`, payload)
    },
    getAdminWhatsappHistory({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/whatsapp-messages?page=${payload.page}`, payload)
    },
    adminDetachDoctorFromUser({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/taskmanager/detach/user/doctor`, payload)
    },
    AdminRemoveFactor({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/cartTransactions/${payload.factorId}/delete`)
    },
    ChangeSessionDateTime({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/clients/sessions/change-fixed-time`, payload)
    },
    CancelSession({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/clients/sessions/${payload.session_id}/cancel`, payload)
    },
    getUserMonitoringReport({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/crm-clients-monitoring?page=${payload.page}`, payload)
    },
    getPreSessionReport({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/marketing-presession-report?page=${payload.page}`, payload.filters)
    },
    getMatchHistory({rootState}, userId) {
        return ajaxGet(rootState.baseUrl + `/admin/suggestedTherapists/` + userId)
    },
    getAssignedTask({rootState}, page) {
        return ajaxGet(rootState.baseUrl + `/admin/assignedTasks?page=${page}`)
    },
    doneTask({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/assignedTasks/${payload.task_id}`, payload)
    },
    addTask({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/assignedTasks`, payload)
    },
    getUserTelegramConversations({rootState}, userId) {
        return ajaxPost(rootState.baseUrl + `/admin/telegram/user/${userId}/conversations`, {})
    },
    getUserTelegramConversationsMessages({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/telegram/user/${payload.userId}/conversation/${payload.conversationId}/messages?page=${payload.page}`, {})
    },
    getShopifyWebhook({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/shopify-transactions?page=${payload.page}`, payload.filter).then((resp) => {
            return resp
        })
    },
    reviewShopifyWebhook({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/shopify-transactions/${payload.transaction_id}/review`, {}).then((resp) => {
            return resp
        })
    },
    sendSessionNotifyWhatsapp({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/clients/sessions/${payload.session_id}/resend-whatsapp`, {})
    },
    getTelegramFile({rootState}, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                method: 'POST',
                url: 'http://127.0.0.1:8000/api/v1/admin/telegram/load-telegram-file',
                withCredentials: true,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                data: {
                    file_message_id: 164
                },
                responseType: 'blob',
            })
        })
    },
    getUserDoctorList({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/doctors/${payload.doctorId}/user-list`, payload)
    },
    setExtraTimeOnSession({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/clients/sessions/${payload.session_id}/set-extra-session`, payload)
    },

    adminCreateDoctorFeeRecordAjax({rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/admin/doctors-fee`, data).then((resp) => {
            return resp;
        })
    },
};
const getters = {
    getEditingDoctorId: state => state.editingDoctorId,
    getNotifications: state => state.notifications,
    getAdminAllDoctors: state => {
        return state.adminAllDoctors != null ? state.adminAllDoctors : [];
    },
    getAdminAllClients: state => {
        return state.adminAllClients != null ? state.adminAllClients : [];
    },
    getAdminDashboard: state => state.adminDashboard,
    getAdminSessions: state => state.adminSessions,
    getAdminUsers: state => state.adminUsers,
    getAdminOrders: state => state.adminOrders,
    getAdminRequests: state => state.adminRequests,
    getCounselorProfile: state => state.counselorProfile,
    getClientProfile: state => state.clientProfile,
    getCurrencies: (state) => state.currencies,
    resolveFilter: (state) => (filter) => {
        if (state.filters.hasOwnProperty(filter)) return state.filters[filter];
        return null;
    },
    resolveOrder: () => order => {
        return order[0] ? 'desc' : 'asc';
    },
    reverseResolveOrder: () => order => {
        return order == 'desc' ? true : false;
    },

    reverseResolveFilter: (state, getters) => (filter, prefix) => {

        let keys = Object.keys(state.filters).filter((item) => {
            if (item.startsWith(prefix)) {
                return item;
            }
        });

        let [value, order] = filter.split(':');

        let finalOrder = getters.reverseResolveOrder(order);
        let x;
        x = keys.find((item) => {
            if (state.filters[item] == value) {
                return item;
            }
        });


        return {
            order: finalOrder,
            header: x == undefined ? null : x.replace(prefix + '_', '')
        };
    }
};
export default {
    state, getters, mutations, actions
}
