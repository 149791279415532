<template>
  <div>
    <v-dialog v-model="modal.visibility" width="500">
      <v-card>
        <v-card-title>
          افزودن اکانت منجیر
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-select v-model="accountManagerId" :items="accountManagers" item-value="id" item-text="full_name">

            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" outlined @click="modal.visibility =false">
            بستن
          </v-btn>
          <v-btn color="primary" elevation="0" @click="assignDoctorToAccountManager">
            ثبت
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  props: {
    modal: Object,
    doctorId: Number,
  },
  mounted() {
    this.getAccountManager()
  },
  data() {
    return {
      accountManagers: null,
      accountManagerId: null,
    }
  },
  methods: {
    assignDoctorToAccountManager() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('assignAccountManagerToDoctor', {
        accountManagerId: this.accountManagerId,
        doctor_id: this.doctorId
      }).then(() => {
        this.modal.visibility = false
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
      }).finally(() => {
        EventBus.$emit('setLoading', false)
        this.$nextTick().then(() => {
          this.$emit('callback')
        })
      })
    },
    getAccountManager() {
      this.$store.dispatch('getAccountManager').then((resp) => {
        this.accountManagers = resp.data.accountManagers
        console.log(this.accountManagers)
      })
    }
  }
}
</script>

<style scoped>

</style>