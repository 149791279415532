<template>
  <div class="fill-height">
    <div class="notificationArea">
      <div class="d-flex justify-space-between">
        <h1>
          اعلان‌ها
        </h1>
        <v-spacer></v-spacer>


         <v-select
            v-model="selectedFilters"
            style="flex-grow: 0; margin-top: -5px"
            height=5px
            :items="logLevelList"
            item-text ="title"
            item-value ="value"
            chips
            label="وضعیت"
            hide-details
             multiple
            single-line
            outlined
            dense
            small-chips
         >

        </v-select>

 <!--        <v-spacer></v-spacer>-->

        <div>



          <v-btn small elevation="0" class="mx-3 " :class="{'blue white--text':archived}" @click="archived = !archived">
            آرشیو
          </v-btn>

          <v-fade-transition>
            <v-btn class="mx-2"
                   :key="2" v-if="selectedNotification.length != 0"
                   color="primary" elevation="0" small
                   @click="readNotification">
              خوانده‌ام
            </v-btn>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-btn v-if="selectedNotification.length == 0" :key="1" color="primary" outlined elevation="0" small
                   @click="selectedAll">
              انتخاب‌همه
              <v-icon small>mdi-check</v-icon>
            </v-btn>
            <v-btn v-else :key="1" color="primary" outlined elevation="0" small @click="selectedNotification = []">
              غیرفعال‌کردن همه
              <v-icon small>mdi-minus</v-icon>
            </v-btn>

          </v-fade-transition>



        </div>
      </div>
      <div style="background: #ededed;height: 1px;width: 100%"></div>
      <div class="mt-5">
        <div class="notifications ">
          <template v-for="(notification,i) in notifications">

            <template v-if="i!==0">
              <template v-if="notifications[i-1].diff_time !== notification.diff_time">
                <div class="mt-4" :key="notification.id+'-time'">{{ notification.diff_time }}</div>
              </template>
            </template>
            <template v-else>
              <div class="mt-4" :key="notification.id+'-time'">{{ notification.diff_time }}</div>
            </template>

            <div class="notificationBox mr-3 mt-1  mb-3 d-flex flex-column justify-center" :key="notification.id"
            :class="notification.data.level+'-border'"
            >

              <div class="d-flex">
                <div class="d-flex flex-column justify-center">
                  <img src="../../assets/640px-Info-icon-blue.svg.png" width="20px" alt="">
                </div>
                <div class="mr-5 d-flex justify-center flex-column">
                  <div class="titleNotification font-weight-bold">
                  </div>
                  <div class="descNotification">
                    {{ notification.message }}
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex justify-center flex-column mr-2">
                  <input type="checkbox" v-model="selectedNotification" :value="notification.id" class="pa-5"
                         style="width: 25px;height: 25px;border-radius: 15px !important;"/>
                </div>
              </div>
            </div>
          </template>

        </div>
      </div>
    </div>
    <div class="text-center ma-5">
      <v-progress-circular indeterminate v-if="localLoader"></v-progress-circular>
      <template v-if="page == total && notifications.length != 0 && !localLoader">
        پایان اعلان‌ها
      </template>
      <!--      <v-pagination class="mt-5" :length="total" v-model="page"></v-pagination>-->
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  created() {
    this.getNotifications(false, true)

  },
  data() {
    return {
      archived: false,
      page: 1,
      total: 1,
      notifications: [],
      selectedNotification: [],
      apiLock: false,
      localLoader: false,
      selectedFilters : ["warning"],
      logLevelList : [
          {id : 0 , title : "اخطار" , value : "warning"},
          {id : 1 , title : "خطا" , value : "error"},
          {id : 2 , title : "اطلاعات" , value : "info"},
          // {id : 0 , title : "خبر" , value : "notice"},
       ]
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollFunction);
  },
  methods: {
    onScrollFunction(){
        const screenHeight = window.innerHeight;
        const scrollValue = window.scrollY;
        const totalHeight = document.body.scrollHeight;
        const scrollPercentage = (scrollValue / (totalHeight - screenHeight)) * 100;
        if (scrollPercentage >= 80) {
          if (!this.apiLock && this.page != this.total) {
            this.page++;
            this.getNotifications(true)
          }
        }
    },
    removeItem(item) {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    },
    bindListener() {
      window.addEventListener("scroll", this.onScrollFunction);
    },
    getNotifications(localLoader = false, listener = false) {
      if (!this.apiLock) {
        this.apiLock = true
        this.localLoader = localLoader
        EventBus.$emit('setLoading', true)

         this.$store.dispatch('getAdminNotifications', {
          status: this.archived ? 'read' : 'unread',
          page: this.page,
           type :this.selectedFilters.join('|')

        }).then((resp) => {
          this.notifications.push(...resp.data.notifications)
          this.total = resp.data.pagination.total_pages;
          if (listener) {
            this.bindListener()
          }
          this.apiLock = false
          EventBus.$emit('setLoading', false)
        }).finally(() => {
          this.localLoader = false
        })
      }
    },
    selectedAll() {
      this.selectedNotification = []
      this.selectedNotification = this.notifications.map((item) => {
        return item.id
      })
    },
    readNotification() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('readNotification', {
        ids: this.selectedNotification
      }).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'اعلان‌ها خوانده شد');
        this.page = 1
        this.notifications = []
        this.getNotifications()
      }).finally(() => {
        this.selectedNotification = []
        EventBus.$emit('setLoading', false)
      })
    }
  },
  watch: {
    archived() {
      this.page = 1;
      this.notifications = []
      this.getNotifications();
    },
    page() {
      this.selectedNotification = []
      this.getNotifications()
    },
    selectedFilters () {
      this.page = 1;

      this.notifications = []
      this.getNotifications();
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 800px) {
  .notificationArea {
    width: 90% !important;

    /*background: #eee;*/
  }
}
.notificationArea {
  width: 60%;
  margin: 0px auto;
  margin-top: 50px;
  /*background: #eee;*/
}

.notificationBox {
  border-right: 8px solid #2f86eb;
  border-radius: 5px;
  background: white;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 5px 15px -5px;
  /*height: 100px;*/
}

.info-border {
  border-right: 8px solid #2f86eb;
}

.warning-border {
  border-right: 8px solid #fcc603;
}
.error-border {
  border-right: 8px solid #e30000;
}
.success-border {
  border-right: 8px solid #20d500;
}
</style>


