<template>
  <div>
    <v-dialog v-model="showAddDialog" max-width="700" scrollable
              v-if="checkRoleAndPermissions('admin.paymentmethods.add')">
      <v-card>
        <v-card-title>افزودن متد پرداختی</v-card-title>
        <v-card-text>
          <v-form ref="addPaymentForm">
            <v-row class="justify-center center align-center text-center justify-center"
                   style="position: relative">
              <v-col cols="12" lg="12" class="text-center">
                <img

                    style="object-fit: cover;width:100%;"
                    :src="newImage"
                    alt="avatar"
                >
              </v-col>
              <v-col cols="12" sm="6" class="addImg">
                <input ref="imgInput" class="d-none" type="file" @change="onFileSelected"
                       accept="image/gif, image/jpeg, image/png">
                <v-btn small class="mx-2" fab dark color="primary" @click="$refs.imgInput.click()">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="6" sm="3">
                <v-select label="نوع پرداخت" :items="['online','offline']" :rules="requiredRules"
                          v-model="newPayment.type"
                          outlined></v-select>
              </v-col>
              <v-col cols="6" sm="3">
                <v-select label="وضعیت نمایش به کاربر" item-text="title" item-value="value" :items="user_visibility"
                          :rules="requiredRules"
                          v-model="newPayment.user_visibility"
                          outlined></v-select>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="newPayment.title" label="عنوان" :rules="requiredRules"
                              placeholder="مثال: حساب بانکی دلار استرالیا" outlined></v-text-field>
              </v-col>
              <!--              <v-col cols="6" sm="3">-->
              <!--                <v-select-->
              <!--                    v-model="newPayment.currency"-->
              <!--                    :items="Custom_currency"-->
              <!--                    label="ارز"-->
              <!--                    item-text="title"-->
              <!--                    item-value="value"-->
              <!--                    outlined-->
              <!--                    :rules="requiredRules"-->
              <!--                ></v-select>-->
              <!--              </v-col>-->
              <v-col cols="6" sm="5">
                <v-select :items="currencies_code" item-value="value" item-text="title" v-model="newPayment.code"
                          :rules="requiredRules" label="کد"
                          placeholder="مثال: USD" outlined></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="newPayment.description" label="توضیحات" outlined></v-textarea>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error elevation-0" text @click="showAddDialog = false">
            بستن
          </v-btn>
          <v-btn class="primary elevation-0" text @click="addNewMethod">
            افزودن
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="editDialog" width="700">
      <v-card>
        <v-card-title>
          ویرایش متد پرداخت
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text v-if="editPaymentMethod != null">
          <v-text-field v-model="editPaymentMethod.title" outlined label="عنوان" readonly dense></v-text-field>
          <p class="font-weight-bold">متا:</p>
          <v-row v-for="(meta , i) in editPaymentMethod.metas" :key="i">
            <v-col cols="12" lg="5">
              <v-text-field v-model="meta.attribute" outlined label="عنوان" dense></v-text-field>
            </v-col>
            <v-col cols="12" lg="5">
              <v-text-field v-model="meta.value" outlined label="مقدار" dense></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-btn fab color="warning" x-small @click="deleteMeta(meta.id)">
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-btn fab color="green" x-small @click="editMeta(meta.id)" class="mr-2">
                <v-icon color="white">
                  mdi-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <p class="font-weight-bold">افزودن متا:</p>
          <v-form ref="addMetaForm" @keydown.enter="addMeta">
            <v-row>
              <v-col cols="12" lg="5">
                <v-text-field v-model="newAttribute" :rules="requiredRules" outlined label="عنوان" dense></v-text-field>
              </v-col>
              <v-col cols="12" lg="5">
                <v-text-field v-model="newValue" :rules="requiredRules" outlined label="مقدار" dense></v-text-field>
              </v-col>
              <v-col cols="12" lg="2">
                <v-btn fab color="primary" x-small @click="addMeta">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container>
      <v-card class="my-8">
        <v-card-title>
          متد های پرداخت
          <TableOptions :searchFilters="searchFilters" @applyFilters="getAllPaymentMethods"></TableOptions>
          <v-spacer></v-spacer>
          <v-btn @click="showAddDialog = true" small class="primary" text
                 v-if="checkRoleAndPermissions('admin.paymentmethods.add')">
            افزودن متد جدید
            <v-icon class="mx-1" small>fas fa-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
              hide-default-footer
              :headers="headers"
              :items="paymentMethods"
              :items-per-page="-1"
          >
            <template v-slot:item.user_visibility="{ item }">
              <template v-if="item.type == 'offline' && checkRoleAndPermissions('admin.paymentmethods.read')">
                <template v-if="item.user_visibility =='visible'">
                  <v-btn small fab elevation="0" text @click="editingPaymentMethodData(item.id,item.state,'invisible')">
                    <v-icon class="blue--text"> fas fa-eye</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn small elevation="0" color="transparent" fab
                         @click="editingPaymentMethodData(item.id,item.state,'visible')">
                    <v-icon class="red--text"> fas fa-eye-slash</v-icon>
                  </v-btn>
                </template>
              </template>
            </template>
            <template v-slot:item.action="{item}">
              <template v-if="item.type == 'offline'  && checkRoleAndPermissions('admin.paymentmethods.read')">
                <v-btn color="primary" fab x-small elevation="0" @click="editingPaymentMethod(item.id)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </template>
            <template v-slot:item.state="{item}">
              <template v-if="item.type == 'offline' && checkRoleAndPermissions('admin.paymentmethods.read')">
                <v-switch color="primary" inset dense v-model="item.state"
                          :value="item.state == 'active' ? false : true "
                          @change="editingPaymentMethodData(item.id,item.state,item.user_visibility)"></v-switch>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>


<script>
/*eslint-disable*/

import {EventBus} from "../../event-bus";
import {mapGetters} from 'vuex';
import TableOptions from "@/components/TableOptions";
import {adminPackageFilters, paymentMethodFilters} from "@/searchFilters";

export default {
  components: {TableOptions},
  data() {
    return {
      newImage: null,
      searchFilters: [],
      Custom_currency: [
        {title: 'دلار', value: 'dollar'},
        {title: 'تومان', value: 'rial'},
      ],
      currencies_code: [
        {title: 'دلار', value: 'dollar'},
        {title: 'تومان', value: 'rial'},
        {title: 'دلار استرالیا', value: 'aud'},
        {title: 'دلار کانادا', value: 'cad'},
        {title: 'پوند', value: 'gbp'},
        {title: 'یورو', value: 'euro'},
      ],
      user_visibility: [
        {title: 'فعال', value: 'visible'},
        {title: 'غیر‌فعال', value: 'invisible'},
      ],
      // currencies: [
      //     {title: 'دلار', value: 'dollar'},
      //     {title: 'تومان', value: 'rial'}
      // ],
      newPayment: {
        type: '',
        title: '',
        code: '',
        description: '',
        currency: '',

      },
      showAddDialog: false,
      paymentMethods: [],
      headers: [
        {text: 'متد پرداختی', value: 'title'},
        {text: 'نوع', value: 'type'},
        {text: 'وضعیت نمایش به کاربر', value: 'user_visibility'},
        {text: 'فعال/غیر‌فعال', value: 'state'},
        {text: 'عملیات', value: 'action'},
      ],
      editDialog: false,
      editPaymentMethod: null,
      newAttribute: null,
      newValue: null,

    }
  },
  computed: {
    ...mapGetters({
      currencies: 'getCurrencies'
    }),
    // imgUrl() {
    //     if (!this.userInfo.hasOwnProperty('avatar') && this.newImage == '') {
    //         return require('./../../../assets/avatar-default.png');
    //     } else if (this.newImage) {
    //         return this.newImage;
    //     } else {
    //         return this.userInfo.avatar;
    //     }
    // },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  created() {
    this.getAllPaymentMethods();
    this.searchFilters = paymentMethodFilters
  },
  methods: {
    onFileSelected(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]).then(resp => {
        this.newImage = resp;
        this.userInfo.avatar = resp;
      });
    },
    createImage(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = e => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    addNewMethod() {

      if (this.$refs.addPaymentForm.validate()) {
        let data = this.newPayment;
        console.log(data, '212112')
        if (data.code == 'rial') {
          data.currency = 'rial'
        } else {
          data.currency = 'dollar'
        }
        if (this.newImage != null) data.image = this.newImage;
        this.$store.dispatch('adminAddNewPaymentMethod', data).then(() => {
          this.getAllPaymentMethods();
          this.$refs.addPaymentForm.reset()
          this.showAddDialog = false;
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
        });
      }
    },
    getAllPaymentMethods(filters = []) {
      this.paymentMethodsLoading = true;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getAllPaymentMethods', filters).then((resp) => {
        this.paymentMethods = resp.data;
        if (this.editPaymentMethod != null) {
          this.editPaymentMethod = this.paymentMethods.find(item => item.id == this.editPaymentMethod.id)
        }
      }).finally(() => {
        this.paymentMethodsLoading = false;
        EventBus.$emit('setLoading', false);
      })
    },
    editingPaymentMethod(paymentID) {
      this.editPaymentMethod = this.paymentMethods.find(item => item.id == paymentID)
      this.editDialog = true
    },
    deleteMeta(metaId) {
      let payload = {
        metaId: metaId,
        paymentId: this.editPaymentMethod.id
      }
      this.$store.dispatch('deleteMetaMethod', payload).then(() => {
        this.getAllPaymentMethods()
      })

    },
    addMeta() {
      if (this.$refs.addMetaForm.validate()) {
        let payload = {
          attribute: this.newAttribute,
          value: this.newValue,
          methodId: this.editPaymentMethod.id
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('addMetaMethod', payload).then(() => {
          this.getAllPaymentMethods();
          this.$refs.addMetaForm.reset()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    editMeta(metaId) {
      let metaValue = this.editPaymentMethod.metas.find(item => item.id == metaId)
      let payload = {
        paymentId: this.editPaymentMethod.id,
        metaId: metaValue.id,
        attribute: metaValue.attribute,
        value: metaValue.value
      }
      if (metaValue.attribute && metaValue.value) {
        this.$store.dispatch('editMetaMethod', payload).then(() => {
          this.getAllPaymentMethods();
        })
      }
    },
    editingPaymentMethodData(id, state, user_visibility) {
      let payload = {
        state: state ? 'active' : "inactive",
        user_visibility: user_visibility,
        paymentId: id,
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('editPaymentMethod', payload).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
        this.getAllPaymentMethods();
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }


  }
}
</script>

<style scoped>

</style>
