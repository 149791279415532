<template>
    <div>
        <AdminFinancialAddNewPayment :clients="clients" ></AdminFinancialAddNewPayment>
    </div>
</template>

<script>
/*eslint-disable*/
    import AdminFinancialAddNewPayment from '@/views/admin/AdminFinancialAddNewPayment'
    import {mapGetters} from 'vuex';

    export default {
        components: {
            AdminFinancialAddNewPayment
        },
        data() {
            return {
                clients: []
            }
        },
        computed: {
            ...mapGetters({
                allClients: 'getAdminAllClients'
            })
        },
        methods: {
            getAllClients() {
                this.$store.dispatch("getAllPatients").then(res => {
                    this.clients = res.data.clients;
                });
            },
        },
        created() {

            // this.getAllClients();
        }
    }
</script>

<style scoped>

</style>