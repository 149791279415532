<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <TableOptions :hasExport="true" :searchFilters="searchFilters"
                          @applyFilters="getSubscribedUsers"></TableOptions>
            <v-data-table
                hide-default-footer
                :headers="headers"
                :items="items"
            >
              <template v-slot:top>
                <v-toolbar
                    flat
                >
                  <!--                  <v-toolbar-title>My CRUD</v-toolbar-title>-->
                  <!--                  <v-divider-->
                  <!--                      class="mx-4"-->
                  <!--                      inset-->
                  <!--                      vertical-->
                  <!--                  ></v-divider>-->
                  <v-spacer></v-spacer>
                  <v-btn class="primary white--text" depressed small outlined
                         @click="downloadAll"
                  >
                    دانلود اکسل
                    <v-icon small class="mx-1">mdi-download</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-pagination
              v-model="page"
              :length="total"

          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
/*eslint-disable*/
import {marketing_survey} from "@/searchFilters";
import TableOptions from "@/components/TableOptions";

export default {
  data() {
    return {
      searchFilters: marketing_survey,
      headers: [
        {text: 'موضوع', value: "area"},
        {text: 'جنسیت', value: "sex"},
        {text: 'سن', value: "age"},
        {text: 'نام', value: "name"},
        {text: 'شماره', value: "tell"},
        {text: 'ایمیل', value: "email"},
        {text: 'تاریخ', value: "date"},
        {text: 'کشور', value: "country"},
        {text: 'از', value: "from"},
        {text: 'ref', value: "reffer"},
      ],
      items: [],
      total: 1,
      page: 1,
      filters: null,
      loading: false
    }
  },
  components: {TableOptions},
  created() {
    // this.getSubscribedUsers();

  },
  methods: {
    goToTop() {
      this.$vuetify.goTo(0);
    },
    downloadAll() {
      this.$store.dispatch('getAdminSubscribedUsersExport');
    },
    getSubscribedUsers(filters) {
      this.filters = filters
      this.loading = true;
      this.goToTop();
      this.$store.dispatch('getAdminSubscribedUsers', {page: this.page, option: filters}).then((resp) => {
        this.items = resp.data.users;
        this.total = resp.data.pagination.total;
      }).finally(() => {
        this.loading = false;

      });
    }
  },
  watch: {
    page() {
      this.getSubscribedUsers(this.filters);
    }
  }
}
</script>

<style scoped>

</style>
