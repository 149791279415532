<template>

  <v-container>
    <v-dialog v-model="showWarningDialog" width="500">
      <v-card>
        <v-card-title>
          توجه
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          شما هیچ بسته مشاوره ای اضافه نکرده اید، آیا نسبت به عملیات مورد نظر اطمینان دارید؟
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" @click="showWarningDialog=false">لغو</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="success" @click="sendInvoice">
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>

      <v-card-title>
        ارسال پیش‌فاکتور PayPal
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="addPayment">
          <v-row>
            <v-col cols="12" md="4" lg="3" sm="6">
              <v-select
                  v-model="payment.currency"
                  :items="currencies"
                  label="ارز"
                  item-text="title"
                  item-value="value"
                  outlined
                  :rules="requiredRules"
              ></v-select>
            </v-col>


            <!--            <v-col cols="12" md="4" lg="3" sm="6">-->
            <!--              <v-text-field-->
            <!--                  v-if="isFocused"-->
            <!--                  outlined-->
            <!--                  label="مبلغ پرداختی"-->
            <!--                  :rules="requiredRules"-->
            <!--                  @blur="isFocused=false"-->
            <!--                  v-model="payment.final_amount"-->
            <!--              >-->
            <!--              </v-text-field>-->
            <!--              <v-text-field-->
            <!--                  v-else-if="!isFocused"-->
            <!--                  outlined-->
            <!--                  label="مبلغ پرداختی"-->
            <!--                  :rules="requiredRules"-->
            <!--                  @focus="isFocused=true"-->
            <!--                  :value="payment.final_amount | toLocaleOrFixed(payment)"-->
            <!--                  style="font-weight: bold"-->
            <!--              >-->
            <!--              </v-text-field>-->
            <!--            </v-col>-->

            <v-col cols="12" md="4" lg="3" sm="6" style="position: relative">
              <!--              <v-autocomplete-->
              <!--                  v-model="payment.user_id"-->
              <!--                  :items="clients"-->
              <!--                  :loading="clientsLoading"-->
              <!--                  hide-no-data-->
              <!--                  hide-selected-->
              <!--                  item-text="full_name"-->
              <!--                  item-value="id"-->
              <!--                  :filter="filterUsers"-->
              <!--                  outlined-->
              <!--                  label="کاربر"-->
              <!--                  :rules="requiredRules"-->
              <!--              >-->
              <!--                <template v-slot:item="{item}">-->
              <!--                  <div>-->
              <!--                    <v-list-item-title>{{ item.name }} {{ item.family }}</v-list-item-title>-->
              <!--                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--                <template v-slot:selection="{attr, on, item, selected}">-->
              <!--                  &lt;!&ndash;<v-list-item-title>{{item.name}} {{item.family}}</v-list-item-title>&ndash;&gt;-->
              <!--                  &lt;!&ndash;<v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>&ndash;&gt;-->
              <!--                  <v-chip-->
              <!--                      v-bind="attr"-->
              <!--                      :input-value="selected"-->
              <!--                      color="blue-grey"-->
              <!--                      class="white&#45;&#45;text"-->
              <!--                      v-on="on"-->
              <!--                  >-->
              <!--                                            <span v-if='!emptyOrSpace(item.full_name)'>-->
              <!--                                                {{ item.full_name }}-->
              <!--                                            </span>-->
              <!--                    <span v-else>-->
              <!--                                            {{ item.email }}-->
              <!--                                        </span>-->
              <!--                  </v-chip>-->
              <!--                </template>-->
              <!--              </v-autocomplete>-->

              <div style="position:relative;" @mouseleave="dropDown['users'] = false">
                <v-text-field outlined label="کاربر" v-model="user" @keypress="searchClientsList"></v-text-field>
                <div class="dropdown" v-if="dropDown['users']">
                  <template v-if="clients.length != 0">
                    <div class="userItem" v-for="(client , i ) in clients" :key="i" @click="selectedClient(client)">
                      <div>
                        {{ client.full_name }}
                      </div>
                      <div class="emailUser">
                        {{ client.email }}
                      </div>
                    </div>
                  </template>
                  <div v-else>
                    کاربر با این نام موجود نیست
                  </div>
                </div>
              </div>
            </v-col>


            <!--            <v-col cols="12" md="4" lg="3" sm="6">-->

            <!--              <v-text-field-->
            <!--                  outlined-->
            <!--                  :value="payment.date | toJalali"-->
            <!--                  label="تاریخ پرداخت"-->
            <!--                  :rules="requiredRules"-->
            <!--                  prepend-icon="event"-->
            <!--                  readonly-->
            <!--                  @click="modal=true"-->
            <!--              ></v-text-field>-->
            <!--              <AdminDatePicker @close="modal=false" :dialog="modal"-->
            <!--                               v-model="payment.date"></AdminDatePicker>-->
            <!--            </v-col>-->
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                  outlined
                  label="ایمیل "
                  v-model="payment.email"
                  :rules="emailRules"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                  outlined
                  label="توضیحات"
                  v-model="payment.information"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                  outlined
                  label="میزان تخفیف "
                  v-model="payment.discount"
                  dir="ltr"
                  type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" lg="3" sm="6">

              <v-autocomplete
                  v-model="selectedPackage"
                  :items="packages"
                  :loading="packagesLoading"
                  hide-no-data
                  hide-selected
                  item-text="title"
                  item-value="id"
                  outlined
                  label="بسته"
                  :search-input.sync="packageId"
                  :filter="packageNamesAndCodes"
                  return-object
              >
                <template v-slot:selection="{item}">
                  {{ item.title }}

                </template>
                <template v-slot:item="{item}">
                  {{ item.title }}
                </template>
              </v-autocomplete>

            </v-col>

            <v-col cols="6" sm="3" md="2" lg="2" xl="1">
              <v-text-field outlined v-model="packageToAddCount" label="تعداد"
                            type="number"></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn small class="primary mt-4" :disabled="selectedPackage== null" @click="addPackage">
                افزودن بسته
                <v-icon class="mx-1" small>fas fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">

              <v-fade-transition>
                <v-data-table
                    :headers="headers"
                    :items="payment.paymentPackages"
                    hide-default-footer
                    v-if="payment.paymentPackages.length"

                >
                  <template v-slot:item="{item}">
                    <tr>
                      <td>{{ item.package.title }}</td>
                      <td>
                        {{ item.quantity }}
                      </td>
                      <td>
                        {{ item.package.price | toLocaleOrFixed }}
                      </td>
                      <td class="text-center">
                        <v-btn fab x-small text class="ma-1 primary"
                               @click="increaseQuantity(item)">
                          <v-icon small>
                            fas fa-plus
                          </v-icon>
                        </v-btn>
                        <v-btn fab x-small text class="ma-1 red white--text"
                               @click="decreaseQuantity(item)">
                          <v-icon small>
                            fas fa-minus
                          </v-icon>
                        </v-btn>
                        <v-btn fab x-small text class="ma-1 red white--text"
                               @click="removeItem(item)">
                          <v-icon small>
                            fas fa-trash
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>

                  </template>
                </v-data-table>
              </v-fade-transition>

            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!isNormal" class="grey white--text" @click="skip">رد شدن</v-btn>
        <v-btn class="primary" @click="addInvoice">
          ارسال
        </v-btn>
      </v-card-actions>
      <v-row>
        <v-col>
          <AlertsComponent></AlertsComponent>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
/*eslint-disable*/
import AlertsComponent from "@/components/AlertsComponent";
import {EventBus} from "../../event-bus";
import AdminDatePicker from '@/components/AdminDatePicker';
import moment from 'moment-jalaali';
import {mapGetters} from 'vuex';

export default {
  props: {
    mode: {
      default: 'normal'
    },
    userId: {
      default: null
    },
    // clients: {
    //   default: () => {
    //     return [];
    //   }
    // }
  },
  components: {
    AlertsComponent, AdminDatePicker
  },
  created() {
    // this.getClientsList()
    // this.clientsCopy = this.clients;
    // if (!this.clients.length) {
    //     this.getClients();
    // }


    // this.getCoupons();
    // this.getAllPaymentMethods();
  },
  data() {
    return {
      dropDown: {
        users: false
      },
      clients: [],
      isFocused: false,
      showWarningDialog: false,
      paymentMethodsLoading: false,
      headers: [
        {
          text: 'نام بسته',
          value: 'title', sortable: false
        },
        // {text: 'ارز', value: 'currency', sortable: false},
        {text: 'تعداد', value: 'quantity', sortable: false},
        {text: 'قیمت', value: 'price', sortable: false},
        {text: 'عملیات', value: 'actions', align: 'center', sortable: false},
      ],
      selectedPackage: null,
      packageToAddCount: 1,
      packageId: '',
      currencies: [
        {title: 'دلار', value: 'dollar'},
        {title: 'دلار استرالیا', value: 'aud'},
        {title: 'دلار کانادا', value: 'cad'},
        {title: 'پوند', value: 'gbp'},
        {title: 'یورو', value: 'euro'},
      ],
      paymentMethods: [],
      modal: false,
      packagesLoading: false,
      couponsLoading: false,
      packages: [],
      coupons: [],
      clientsLoading: false,
      clientsCopy: [],
      payment: {
        user_id: null,
        paymentPackages: [],
        currency: '',
        discount: '',
        information: '',
        email: '',
      },
      user: "",
      timer: null,
    }
  },
  methods: {
    selectedClient(user) {
      this.payment.user_id = user.id
      if (this.user.full_name != "") {
        this.user = user.full_name
      } else {
        this.user = user.email
      }
      this.dropDown.users = false
      this.payment.email = user.email
    },
    searchClientsList() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getClientsList()
      }, 1000)
    },
    getClientsList() {
      this.$store.dispatch('getClientsListInvoice', {
        page: 1, options: {
          patient_name: this.user,
        }
      }).then((resp) => {
        this.dropDown.users = true
        this.clients = resp.data.users
      })
    },
    skip() {
      this.$emit('done')
    },
    emptyOrSpace(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    filterUsers(item, queryText, itemText) {

      if (item == null) {
        return false;
      }
      if (item.header) {
        return false;
      }

      const hasValue = val => val != null ? val : '';

      const text = hasValue(itemText);
      const email = hasValue(item.email);
      const query = hasValue(queryText);

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || email.indexOf(query.toString().toLowerCase()) > -1;
    },
    setCurrency(id) {
      let method = this.paymentMethods.find(item => item.id == id);
      if (method != undefined) {
        this.payment.currency = method.code;
      }
    },
    increaseQuantity(item) {
      item.quantity++;
    },
    decreaseQuantity(item) {
      item.quantity--;
      if (item.quantity <= 0) {
        this.payment.paymentPackages.splice(this.payment.paymentPackages.indexOf(item), 1);
      }
    },
    removeItem(item) {
      this.payment.paymentPackages.splice(this.payment.paymentPackages.indexOf(item), 1);
    },
    addPackage() {
      let pack = this.payment.paymentPackages.find((pack) => {
        if (pack.package.id == this.selectedPackage.id) {
          return pack;
        }
      });
      if (pack == undefined || pack == null) {
        this.payment.paymentPackages.push({
          quantity: this.packageToAddCount,
          package: this.selectedPackage
        });
      } else {
        pack.quantity++;
      }


    },
    packageNamesAndCodes(item, queryText, itemText) {
      if (item.header) {
        return false;
      }
      const hasValue = val => val != null ? val : '';
      const text = hasValue(itemText);
      const code = hasValue(item.id);
      const query = hasValue(queryText);
      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || `${code}`.indexOf(query.toString().toLowerCase()) > -1;
    },
    // getCoupons() {
    //   if (this.checkRoleAndPermissions('admin.coupons.all')) {
    //     this.couponsLoading = true;
    //     this.$store.dispatch('getAllCoupons').then((resp) => {
    //       this.coupons = resp.data.coupons;
    //
    //     }).finally(() => {
    //       this.couponsLoading = false;
    //     })
    //   }
    // },
    // getAllPaymentMethods() {
    //   this.paymentMethodsLoading = true;
    //   this.$store.dispatch('getAllPaymentMethods').then((resp) => {
    //     this.paymentMethods = resp.data;
    //     console.log(resp.data);
    //
    //   }).finally(() => {
    //     this.paymentMethodsLoading = false;
    //   })
    // },
    addInvoice() {
      if (this.payment.discount < 0) {
        EventBus.$emit('notify', 'red', undefined, 'مفدار میزان تخفیف را به صورت صحیح وارد کنید');
        return;
      }
      let payload = {}
      payload.currency = this.payment.currency
      payload.users = [
        {
          id: this.payment.user_id,
          paypal_email: this.payment.email
        }
      ]
      let packages = []
      this.payment.paymentPackages.forEach((item) => {
        packages.push({
          id: item.package.id,
          quantity: item.quantity
        })
      })
      payload.packages = packages
      payload.negotiation_discount = this.payment.discount

      this.sendInvoice(payload);

    },
    sendInvoice(payload) {
      if (this.$refs.addPayment.validate()) {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('adminSendInvoice', payload).then((resp) => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.$router.push()
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      } else {
        EventBus.$emit('notify', 'red', undefined, 'لطفا مقادیر را به درستی وارد نمایید');
      }
    },

    getClients() {
      this.clientsLoading = true;
      this.$store.dispatch('getAllPatients').then((resp) => {
        this.clientsCopy = resp.data.clients;
      }).finally(() => {
        this.clientsLoading = false;
      })
    },
    getPackages() {
      this.packagesLoading = true;
      // this.$store.dispatch('getAllPackages').then((resp) => {
      //     this.packages = resp.data;
      // }).finally(() => {
      //     this.packagesLoading = false;
      // })
      this.$store.dispatch('getAllPackagesInCurrency', {currency: this.payment.currency}).then((resp) => {
        this.packages = resp.data.packages;
      }).finally(() => {
        this.packagesLoading = false;
      })
    },
  },
  filters: {
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
    currencyType(currency) {
      return currency == 'dollar' ? 'دلار' : 'تومان';
    },
    toLocaleOrFixed(value) {
      let output = Number(value).toLocaleString();
      return output != 0 ? output : null;
    }
  },
  computed: {
    // ...mapGetters({
    //   currencies: 'getCurrencies'
    // }),
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ]
    },
    isNormal() {
      return this.mode == 'normal';
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  watch: {
    'payment.currency'() {
      this.selectedPackage = null;
      this.payment.paymentPackages = [];
      this.getPackages();
    },
    userId() {
      if (this.userId != null) {
        this.payment.user_id = this.userId;
      }
    }
  }

}
</script>

<style scoped>
.userItem {
  margin-bottom: 10px;
  cursor: pointer;
}

.emailUser {
  font-size: 11px;
}

.dropdown {
  overflow-y: scroll;
  position: absolute;
  top: 58px;
  background: white;
  padding: 10px;
  border: 1px solid #dddddd;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  max-height: 200px;
  z-index: 10;
}
</style>