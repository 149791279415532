<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-alert
                        class="primary white--text text-center"
                        border="left"
                        elevation="1"
                        icon="mdi-exclamation-thick"
                >
                    لطفا همه زمانهای مناسب برای برگزاری جلسه آینده را به زمان محلی خود را وارد نمایید، هرچه تعداد بیشتری زمان وارد کنید، امکان برگزاری سریع تر جلسه را به تیم پشتیبانی میدهید.
                </v-alert>
            </v-col>
        </v-row>
        <v-row>

            <v-col cols="12" lg="9">

                <v-card class="pa-2 elevation-0" min-height="463">

                    <v-row justify="center">
                        <v-col cols="auto" class="px-3" align="start">
                            <v-btn fab dark small :color="currentWeek === 2 ? 'primary' : 'rgba(67,55,140,0.51)'"
                                   @click="prevWeek">
                                <v-icon dark>mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="title" align="center">
                            <span>وارد کردن زمانهای مناسب برای جلسه مشاوره</span>
                        </v-col>
                        <v-col class="px-3" align="end" @click="nextWeek" cols="auto">
                            <v-btn fab dark small :color="currentWeek === 1 ? 'primary' : 'rgba(67,55,140,0.51)'">
                                <v-icon dark>mdi-arrow-left</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-for="(week, index) in cloneDays" :key="`week-title-${index}`">
                        <v-col class="pt-0" align="center" v-if="currentWeek == index+1">
                            <span class="text--primary">{{ week.days[0].jdate + jfaToEnMonth(week.days[0].jmonth) }} تا {{ week.days[6].jdate + jfaToEnMonth(week.days[6].jmonth) }}</span>
                        </v-col>
                    </v-row>


                    <v-row class="pa-1" v-for="(week, id) in cloneDays" :key="`week-${id}`">
                        <v-col class="text-center pa-1" :cols="$vuetify.breakpoint.xsOnly ? 12 : ''"
                               v-for="(weekDay, index) in week.days" :key="`week-${index}`"
                               v-show="currentWeek == id+1">
                            <v-card
                                    outlined
                            >
                                <div class="pt-2">{{ weekDay.day }}</div>
                                <div>{{ weekDay.jdate + ' ' + jfaToEnMonth(weekDay.jmonth) }}</div>
                                <div>{{ weekDay.mmonth + ' ' + weekDay.mdate }}</div>
                                <div v-show="isMobile" align="start" class="pa-1" @click="showSelTimes = !showSelTimes">
                                    <v-icon large>{{showSelTimes == false ? 'mdi-chevron-down' : 'mdi-chevron-up'}}
                                    </v-icon>
                                </div>
                                <v-divider></v-divider>
                                <v-card
                                        v-show="!isMobile ? true : showSelTimes"
                                        :ripple="false"
                                        class="elevation-0"
                                        min-height="250"
                                        @click="selectDate(weekDay)"
                                        :ref="weekDay.fullDate"
                                        :style="[isSelected == weekDay.fullDate ? { 'backgroundColor': 'rgba(67,55,140,0.50)' } : {}]">
                                    <v-row>
                                        <v-col :cols="!$vuetify.breakpoint.xsOnly ? 12 : ''" class="pa-1"
                                               v-for="(time, index) in weekDay.timebooks" :key="`time-${index}`">
                                            <v-hover v-slot:default="{ hover }">
                                                <v-chip color="default" label v-ripple style="direction: ltr;">
                                                    <v-icon :class="{'no-opacity':true,'chip-visible':hover} "
                                                            color="red" small
                                                            @click.self="removeTime(weekDay, time)"
                                                            v-ripple>
                                                        fas fa-times
                                                    </v-icon>
                                                    <span class="mx-1">{{ time.time }}</span>
                                                </v-chip>
                                            </v-hover>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-card>
                        </v-col>
                    </v-row>


                    <v-row v-if="$router.currentRoute.name != 'dashboard'">
                        <v-col>
                            <v-btn color="secondary" dark class="elevation-0" @click="postConsultInfo" v-if="checkRoleAndPermissions('admin.doctor.calendar.edit')">ثبت زمان‌ها
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" v-if="!isMobile">
                <v-card class="pa-2" :class="{'heartbeat':heartbeat}">
                    <v-card-title class="headline lighten-2" primary-title v-if="profile!=null">
                        <v-col cols="12" align="center" class="py-0">
                            <h5>زمان محلی: {{ profile.tz }}</h5>
                        </v-col>
                        <v-col cols="12" align="center" class="py-0">
                            <span class="title">{{ fullDate }}</span>
                        </v-col>
                    </v-card-title>
                    <v-divider class="mb-3"></v-divider>
                    <!--TIME BOX-->
                    <v-row v-for="(timeSel, index) in timeSelArr" :key="`timeSel${index}`" class=" ma-0">
                        <v-col align="center" cols="4" v-for="time in timeSel" :key="time" class="pa-1 ma-0">
                            <v-chip color="primary" dark label
                                    :outlined="dayContainsTime(time)"
                                    style="font-size: .8rem; direction: ltr" @click="selectTime(time)">
                                {{ time }}
                            </v-chip>
                        </v-col>
                    </v-row>
                    <!--END OF TIME BOX-->
                </v-card>
            </v-col>

            <div class="text-center" v-if="isMobile">
                <v-dialog v-model="dialog" width="500">
                    <v-card class="d-flex flex-column justify-center align-center">
                        <v-card-title class="headline grey lighten-2" primary-title v-if="profile!=null">
                            <v-col cols="12" align="center" class="py-0">
                                <span class="subtitle-1">زمان محلی: {{ profile.tz }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="py-0">
                                <span class="title">{{ fullDate }}</span>
                            </v-col>
                        </v-card-title>

                        <v-card flat>
                            <v-row v-for="(timeSel, index) in timeSelArr" :key="`timeSel${index}`">
                                <v-col cols="4" class="px-1 py-1" v-for="time in timeSel" :key="time">
                                    <v-chip dark label color="primary"
                                            :outlined="selectedTimeInDate.includes(time) ? false : true"
                                            style="font-size: .8rem; direction: ltr;" @click="selectTime(time)">
                                        {{ time }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn text color="primary" @click="dialog = false">تایید و بازگشت</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="dialog = false">بستن</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </v-row>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    // import counselorCalendar from '@/components/dashboards/counselorCalendar.vue';
    // import adminCalendar from '@/components/dashboards/adminCalendar.vue';
    // import clientCalendar from '@/components/dashboards/clientCalendar.vue';
    // import {timezones} from '../../utilities';
    import moment from 'moment-jalaali';
    import {EventBus} from "../../event-bus";

    export default {
        components: {
            // counselorCalendar,
            // adminCalendar,
            // clientCalendar
        },
        data() {
            return {
                itemsToRemove: [],
                // type: 'success',
                // snackbar: false,
                // timeout: 2000,
                isSuccess: false,
                isSelected: false,
                timezone: '',
                // timezones: [],
                months: moment().months(),
                today: moment().format("D"),
                fullDate: moment().format("jYYYY/jMM/jDD"),
                thisMonth: moment().format("jMMMM"),
                thisYear: moment().format("YYYY"),
                weekDays: moment.weekdays(),
                weekNum: 2,
                firstDay: parseInt(moment().startOf('month').format('D')),
                lastDay: parseInt(moment().endOf('month').format('D')),
                firstWeekDay: moment().startOf('month').format("dddd"),
                local: this.$store.getters.getLocal,

                selectedTimeTimezone: null,
                selectedTimezone: "",
                profile: null,
                maxCal: "",
                minCal: "",
                days: [],
                cloneDays: {},
                currentWeek: 1,
                timesChoosed: {},
                timeSelArr: {
                    "1": ["00:00 - 01:00", "01:00 - 02:00", "02:00 - 03:00"],
                    "2": ["03:00 - 04:00", "04:00 - 05:00", "05:00 - 06:00"],
                    "3": ["06:00 - 07:00", "07:00 - 08:00", "08:00 - 09:00"],
                    "4": ["09:00 - 10:00", "10:00 - 11:00", "11:00 - 12:00"],
                    "5": ["12:00 - 13:00", "13:00 - 14:00", "14:00 - 15:00"],
                    "6": ["15:00 - 16:00", "16:00 - 17:00", "17:00 - 18:00"],
                    "7": ["18:00 - 19:00", "19:00 - 20:00", "20:00 - 21:00"],
                    "8": ["21:00 - 22:00", "22:00 - 23:00", "23:00 - 24:00"]
                },
                calSelectedDay: null,
                isMobile: false,
                dialog: false,
                newTimebooks: [],
                consultInfo: {
                    timebooks: [],
                },
                selectedTimeInDate: [],
                heartbeat: false,
                heartBeatHandle: null,
                showSelTimes: false
            }
        },
        mounted() {
            // this.timezones = timezones;

            if (this.$router.currentRoute.name == 'counselorCalendar') {

                this.$store.dispatch('getCounselorProfile', {userid: this.$route.params.counselorId}).then((resp) => {
                    this.profile = resp.data.doctor;

                    this.fillTimes();
                });
                // this.profile = this.$store.getters.getCounselorProfile;
            } else {
                this.profile = this.$store.getters.getUserInfo;

                this.fillTimes();
            }

        },
        beforeDestroy() {
            EventBus.$off('saveTimeEvent', this.postConsultInfo);
        },
        created() {

            this.initiate();

            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            EventBus.$on('saveTimeEvent', this.postConsultInfo);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        watch: {
            selectedTimezone: function () {
                this.getDays();
                // this.dateSel();
            }
        },
        computed: {
            calTimezone: function () {
                switch (this.selectedTimezone) {
                    case "IR":
                        return "fa";
                    default:
                        return "en";
                }
            },
            // text() {
            //     switch (this.type) {
            //         case "success":
            //             return this.$vuetify.lang.t('$vuetify.saveSuccessfullyMsg');
            //         case "warning":
            //             return this.$vuetify.lang.t('$vuetify.warningSelectDateMsg');
            //         case "error":
            //             return this.$vuetify.lang.t('$vuetify.saveErrorMsg');
            //         default:
            //             return "";
            //     }
            // },
            // snackbarType() {
            //     switch (this.type) {
            //         case "success":
            //             return 'success';
            //         case "warning":
            //             return 'warning';
            //         case "error":
            //             return 'error';
            //         default:
            //             return "";
            //     }
            // },
            // iconType() {
            //     switch (this.type) {
            //         case "success":
            //             return 'mdi-check';
            //         case "warning":
            //             return 'mdi-exclamation';
            //         case "error":
            //             return 'mdi-close';
            //         default:
            //             return "";
            //     }
            // }
        },
        methods: {
            fillTimes() {
                // if (this.$router.currentRoute.name == 'counselorCalendar') {
                //     this.profile = this.$store.getters.getCounselorProfile;
                // } else {
                //     this.profile = this.$store.getters.getUserInfo;
                // }

                this.selectedTimeTimezone = this.profile.tz;
                this.selectedTimezone = this.profile.country;

                this.consultInfo.timebooks = this.profile.timebooks;

                if (this.consultInfo.timebooks != null) {
                    this.fillCalendar(this.consultInfo.timebooks);
                }

                if (document.body.scrollWidth < 500) {
                    this.isMobile = true;
                }

                if (this.calTimezone == "fa") {
                    this.minCal = moment().format("jYYYY/jMM/jDD");
                    this.maxCal = moment()
                        .add(14, "days")
                        .format("jYYYY/jMM/jDD");
                } else if (this.calTimezone == "en") {
                    this.minCal = moment().format("YYYY/MM/DD");
                    this.maxCal = moment()
                        .add(14, "days")
                        .format("YYYY/MM/DD");
                }
            },
            initiate() {
                this.getDays();
                let copyDays = [...this.days];
                this.cloneDays = copyDays;

                if (this.$router.currentRoute.name == 'counselorCalendar') {
                    this.$store.dispatch('getCounselorProfile', {userid: this.$route.params.counselorId}).then((resp) => {
                        this.profile = resp.data.doctor;
                    });
                    // this.profile = this.$store.getters.getCounselorProfile;
                } else {
                    this.profile = this.$store.getters.getUserInfo;
                }

            },
            dayContainsTime(time) {
                let x = this.selectedTimeInDate.filter(selectedItem => selectedItem.time == time);
                if (x.length) {
                    return false;
                }
                return true;
            },
            handleResize() {
                if (window.innerWidth < 500) {
                    this.isMobile = true;
                }
            },
            save() {
                // console.log(this.timezone);
            },
            userHasRole(role) {
                return this.$store.getters.userHasRole(role);
            },
            removeTime(weekDay, time) {
                this.selectDate(weekDay);
                this.selectTime(time);
                this.$forceUpdate();
            },
            faToEnMonth(inp) {
                switch (inp) {
                    case "ژانویه":
                        return "January";
                    case "فوریه":
                        return "February";
                    case "مارس":
                        return "March";
                    case "آوریل":
                        return "April";
                    case "مه":
                        return "May";
                    case "ژوئن":
                        return "June";
                    case "ژوئیه":
                        return "July";
                    case "اوت":
                        return "Auguest";
                    case "سپتامبر":
                        return "September";
                    case "اکتبر":
                        return "October";
                    case "نوامبر":
                        return "November";
                    case "دسامبر":
                        return "December";
                    default:
                        return inp;
                }
            },
            jfaToEnMonth(inp) {
                switch (inp) {
                    case "Farvardin":
                        return "فروردین";
                    case "Ordibehesht":
                        return "اردیبهشت";
                    case "Khordad":
                        return "خرداد";
                    case "Tir":
                        return "تیر";
                    case "Mordad":
                        return "مرداد";
                    case "ُShahrivar":
                        return "شهریور";
                    case "Mehr":
                        return "مهر";
                    case "Aban":
                        return "آبان";
                    case "Azar":
                        return "آذر";
                    case "Dey":
                        return "دی";
                    case "Bahman":
                        return "filk";
                    case "Esfand":
                        return "اسفند";
                    default:
                        return inp;
                }
            },
            prevWeek() {
                if (this.currentWeek > 1) {
                    this.currentWeek--;
                    // console.log(this.currentWeek);
                    this.calSelectedDay = null;
                    this.selectedTimeInDate = [];
                    this.isSelected = false;
                }
            },
            nextWeek() {
                if (this.currentWeek < this.weekNum) {
                    this.currentWeek++;
                    // console.log(this.currentWeek);
                    this.calSelectedDay = null;
                    this.selectedTimeInDate = [];
                    this.isSelected = false;
                }
            },
            getDays() {
                // moment.loadPersian({
                //     dialect: "persian-modern"
                // });
                let maxDays = this.weekNum * 7;
                let days = [{
                    days: [],
                    name: ""
                }];
                let j = 0;
                for (let i = 0; i < maxDays; i++) {
                    let thisMoment = moment().add(i, 'days');
                    if (this.selectedTimezone == "IR") {
                        days[j].days.push({
                            fullDate: thisMoment.format("jYYYY/jMM/jDD"),
                            year: thisMoment.format("jYYYY"),
                            jmonth: thisMoment.format("jMMMM"),
                            mfamonth: thisMoment.format("MMMM"),
                            mmonth: this.faToEnMonth(thisMoment.format("MMMM")),
                            day: thisMoment.format("dddd"),
                            jdate: thisMoment.format("jDD"),
                            mdate: thisMoment.format("DD"),
                            id: i + 1
                        })
                    } else {
                        days[j].days.push({
                            fullDate: thisMoment.format("YYYY/MM/DD"),
                            year: thisMoment.format("YYYY"),
                            mmonth: thisMoment.format("MMMM"),
                            jmonth: thisMoment.format("jMMMM"),
                            day: thisMoment.format("dddd"),
                            mdate: thisMoment.format("DD"),
                            jdate: thisMoment.format("jDD"),
                            id: i + 1
                        });
                    }
                    if (i % 7 == 6) {
                        if (i != maxDays - 1) {
                            j++;
                            days[j] = {
                                days: [],
                                name: ""
                            };
                        }
                    }

                    this.days = days;
                }
                return this.days;
            },
            fillCalendar(times) {
                // GET TIME BOOKS FROM PROFILE
                let out = {};
                times.forEach(item => {
                    let newKey = moment(item.date, "YYYY/MM/DD").format("YYYY/MM/DD");
                    out[newKey] = [];
                    item.periods.forEach(function (period) {
                        var end = moment(period.end, 'HH').format('HH:mm');
                        if (end == "00:00") {
                            end = "24:00";
                        }
                        out[newKey].push({
                            time: moment(period.start, 'HH').format('HH:mm') + ' - ' + end,
                            id: period.id
                        });
                    });
                });
                this.timesChoosed = out;

                //FIND DATES IN 2 WEEKS
                this.days.forEach(week => {
                    week.days.forEach(day => {
                        day.timebooks = [];
                        if (Object.keys(this.timesChoosed).includes(day.fullDate)) {
                            day.timebooks = [...this.timesChoosed[day.fullDate]];
                        }
                    })
                });
                this.cloneDays = [...this.days];
            },
            selectDate(input) {
                if (input.timebooks == undefined) {
                    return;
                }

                this.isSelected = input.fullDate;
                this.calSelectedDay = input;
                this.dialog = true;
                // !input.timebooks ? input['timebooks'] = [] :
                //     input.timebooks.forEach(() => {
                //         // console.log('TIME', time);
                //         for (let key in this.timeSelArr) {
                //             // console.log(this.timeSelArr[key].indexOf(time));
                //         }
                //     });
                this.selectedTimeInDate = input.timebooks;

                if (!this.heartbeat) {
                    this.heartbeat = true;
                    this.heartBeatHandle = setTimeout(() => {
                        this.heartbeat = false;
                    }, 1000);
                } else {
                    clearTimeout(this.heartBeatHandle);
                    this.heartBeatHandle = setTimeout(() => {
                        this.heartbeat = false;
                    }, 1000);

                }
            },
            selectTime(input) {
                if (this.calSelectedDay == null) {
                    this.$store.commit('setSnackbar', {show: true, type: 'warning'});

                } else {

                    const Day = this.calSelectedDay.id - 1;
                    const timebooks = this.cloneDays[this.currentWeek - 1].days[Day <= 6 ? Day : Day - 7].timebooks;


                    let found = timebooks.filter((item) => {
                        if (typeof input != "object") {
                            return item.time === input;
                        } else {
                            return item.time === input.time;
                        }
                    });

                    if (found.length) {
                        if (found[0].id != null) {
                            this.itemsToRemove.push(found[0].id);
                        }
                        timebooks.splice(timebooks.indexOf(found[0]), 1);
                    } else {
                        timebooks.push({id: null, time: input});
                    }

                    //PREPARE DATA FOR POST
                    this.newTimebooks = [];
                    this.cloneDays.forEach(week => {
                        week.days.forEach(day => {
                            if (!day.timebooks) {
                                day['timebooks'] = []
                            }
                            if (day.timebooks.length != 0) {
                                let start = "";
                                let end = "";
                                this.newTimebooks.push({'date': day.fullDate, 'periods': []});
                                day.timebooks.forEach(time => {
                                    start = time.time.split(" - ")[0];
                                    end = time.time.split(" - ")[1];
                                    this.newTimebooks.forEach(item => {
                                        if (item.date == day.fullDate) {
                                            item.periods.push({'start': start, 'end': end})
                                        }
                                    })
                                })
                            }
                        });
                    })
                    this.consultInfo.timebooks = this.newTimebooks;
                    // console.log('new timebooks after select time: ', this.consultInfo.timebooks);
                    this.$forceUpdate();
                }
            },
            postConsultInfo() {
                this.profile.timebooks = this.consultInfo.timebooks;
                if (this.$router.currentRoute.name == 'counselorCalendar') {
                    this.consultInfo.id = this.$route.params.counselorId;

                    if (this.itemsToRemove.length) {
                        this.$store.dispatch('adminCounselorRemoveTimes', {
                            id: this.consultInfo.id,
                            payload: {times: this.itemsToRemove}
                        }).then(() => {
                            this.itemsToRemove = [];
                            // this.$store.dispatch('saveCounselorTimebooks', this.consultInfo).then(() => {
                            //     this.$store.dispatch('getUserInfo').then(() => {
                            //         this.initiate();
                            //         this.fillTimes();
                            //         this.$emit('timesSaved');
                            //     })
                            // }).catch(err => {
                            //     console.log(err);
                            // });
                            this.$store.dispatch('saveCounselorTimebooks', this.consultInfo).then(() => {
                                // this.$store.commit('setCouselorProfile', this.profile);
                                // this.type = 'success';
                                // this.snackbar = true;
                                EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                            })
                        });
                    } else {
                        this.$store.dispatch('saveCounselorTimebooks', this.consultInfo).then(() => {
                            // this.$store.commit('setCouselorProfile', this.profile);
                            // this.type = 'success';
                            // this.snackbar = true;
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        })
                    }

                } else {
                    // console.log('co', this.consultInfo);
                    //first remove
                    //then add
                    if (this.userHasRole('counselor')) {

                        if (this.itemsToRemove.length) {
                            this.$store.dispatch('counselorRemoveTimes', {times: this.itemsToRemove}).then(() => {
                                this.itemsToRemove = [];
                                this.$store.dispatch('counselorPostConsultInfo', this.consultInfo).then(() => {
                                    this.$store.dispatch('getUserInfo').then(() => {
                                        this.initiate();
                                        this.fillTimes();
                                        this.$emit('timesSaved');
                                    })
                                }).catch(err => {
                                    console.log(err);
                                });
                            });
                        } else {
                            this.$store.dispatch('counselorPostConsultInfo', this.consultInfo).then(() => {
                                this.$store.dispatch('getUserInfo').then(() => {
                                    this.initiate();
                                    this.fillTimes();

                                    this.$emit('timesSaved');
                                })
                            }).catch(err => {
                                console.log(err);
                            });
                        }

                    } else {

                        if (this.itemsToRemove.length) {
                            this.$store.dispatch('clientRemoveTimes', {times: this.itemsToRemove}).then(() => {
                                this.itemsToRemove = [];
                                this.$store.dispatch('clientPostConsultInfo', this.consultInfo).then(() => {
                                    this.$store.dispatch('getUserInfo').then(() => {
                                        this.initiate();
                                        this.fillTimes();
                                        this.$emit('timesSaved');
                                    })
                                }).catch(err => {
                                    console.log(err);
                                });
                            });
                        } else {
                            this.$store.dispatch('clientPostConsultInfo', this.consultInfo).then(() => {
                                this.$store.dispatch('getUserInfo').then(() => {
                                    this.initiate();
                                    this.fillTimes();

                                    this.$emit('timesSaved');
                                })
                            }).catch(err => {
                                console.log(err);
                            });
                        }
                    }

                }
            }
        }
    }
</script>

<style>
    /*#snackbar-calendar .v-snack__wrapper {*/
    /*    background-color: #fff;*/
    /*    border-right: 5px solid #86C23B;*/
    /*    color: #888;*/
    /*}*/
    /*#snackbar-calendar .v-snack__wrapper {*/
    /*    background-color: #fff;*/
    /*    border-right: 5px solid red;*/
    /*    color: red;*/
    /*}*/
</style>

<style scoped>
    .v-chip {
        padding: 0 7px;
    }

    .no-opacity {
        opacity: 0;
        width: 0;
        transition: all .2s ease-in-out;
    }

    .chip-visible {
        width: 15px;
        opacity: 1;
    }

    @keyframes heartbeat {
        0% {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        50% {
            -webkit-box-shadow: 0 0 15px rgba(67, 55, 140, .8);
            -moz-box-shadow: 0 0 15px rgba(67, 55, 140, .8);
            box-shadow: 0 0 15px rgba(67, 55, 140, .8);
        }
        100% {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }

    .heartbeat {
        animation-duration: 1s;
        animation-name: heartbeat;
        animation-iteration-count: infinite;
    }

</style>

