<template>
  <div>
    <v-container fluid>
      <v-card class="my-1 pa-4">
        <h2>
          چارت پرداختی
        </h2>
        <TableOptions
            :hasExport="false"
            :searchFilters="filters"
            @applyFilters="applyFilters"
            :action-required-permission="{
                  download:'admin.expressentry'
                }"

        ></TableOptions>
        <div class="mt-10">
          <apexchart type="line" height="350" :options="chartOptions"
                     :series="series"></apexchart>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>

/*eslint-disable*/
import apexchart from 'vue-apexcharts';
import AdminDatePicker from "@/components/AdminDatePicker";
import TableOptions from "@/components/TableOptions";
import {sessions_chart} from "@/searchFilters";

export default {
  components: {
    apexchart, AdminDatePicker, TableOptions,
  },
  data() {
    return {
      filters: sessions_chart,
      series: [{
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        // title: {
        //   text: 'Product Trends by Month',
        //   align: 'left'
        // },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
      },
    }
  }
}
</script>

<style scoped>

</style>