<template>
  <div>
    <div style="position: relative">
      <v-text-field
          v-model="User"
          :items="clients"
          :loading="clientsLoading"
          hide-no-data
          hide-selected
          item-text="full_name"
          item-value="id"
          :filter="filterUsers"
          outlined
          label="کاربر"
          :hint="isUserName ? 'جست‌و‌جو با آیدی کاربر' :  'جست‌و‌جو با نام کاربر'"
          persistent-hint
          value=""
          @input="userSelectedID = null"
      >
        <template v-slot:prepend>
          <v-checkbox
              dense class="pa-0 ma-0" v-model="isUserName">

          </v-checkbox>
        </template>
        <!--                  payment.user_id-->
      </v-text-field>
      <div class="listTimeSelected" v-if="UsersList">
        <div v-for="(user , i ) in UsersList" :key="i" class="UserList" @click="selectingUser(user)">
          <div class="d-flex justify-space-between">
            <div>
              <b>
                {{ user.full_name }}
              </b>
              <br>
              <span>
                      {{ user.email }}
                        </span>
            </div>
            <div>
              <v-chip color="primary" class="mt-2" small v-if="user.notes.length != 0">
                {{ user.notes[0].description }}
              </v-chip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {

  props: {
    userId: null
  },
  data() {
    return {
      User: null,
      clientsLoading: false,
      userSelectedID: null,

      clients: {
        default: () => {
          return [];
        }
      },
      isUserName: false,
      UsersList: null,

    }
  },
  methods: {
    selectingUser(user) {
      this.userSelectedID = user.id
      this.User = user.full_name
      this.UsersList = null
      clearTimeout(this.UserNameTimer)
      this.$emit("userSelected", user.id)
    },
    filterUsers(item, queryText, itemText) {

      if (item == null) {
        return false;
      }
      if (item.header) {
        return false;
      }

      const hasValue = val => val != null ? val : '';

      const text = hasValue(itemText);
      const email = hasValue(item.email);
      const query = hasValue(queryText);

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || email.indexOf(query.toString().toLowerCase()) > -1;
    },
  },
  computed: {},
  watch: {
    'User'(val) {
      clearTimeout(this.UserNameTimer)
      if (this.userSelectedID != null) {
        return;
      }
      this.UserNameTimer = setTimeout(() => {
        if (val) {
          EventBus.$emit('setLoading', true)
          let payload = {
            page: 1,
            options: {
              type: 'patient',
              loadNotes: true,
            }
          }
          if (this.isUserName) {
            payload.options.user_id = this.User
          } else {
            payload.options.patient_name = this.User
          }

          this.$store.dispatch('getClientsListAddPayment', payload).then((resp) => {
            this.UsersList = resp.data.users
          }).finally(() => {
            EventBus.$emit('setLoading', false)
          })
        } else {
          this.UsersList = null
        }
      }, 1000)
    },
    userId(prev) {
      if (!prev) {
        this.User = null
        this.userSelectedID = null
      }
    }
  }


}
</script>

<style scoped>
.listTimeSelected {
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  background: white;
  width: 100%;
  min-height: 300px;
  overflow-y: scroll;
  z-index: 10;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}

.UserList {
  margin-bottom: 5px;
  padding-right: 5px;
}
</style>
