<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-dialog v-model="permissionActionModal" width="500px">
          <v-card>
            <v-card-title>
              افزودن سطح دسترسی
            </v-card-title>
            <v-card-text>
              <v-form ref="roleForm">
                <v-text-field label="نام سطح " outlined dense :rules="requiredRules"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" elevation="0">
                افزودن
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card-title class="justify-space-between">
          <div>
            سطوح دسترسی
          </div>
          <div>
<!--            <v-btn color="primary" elevation="0" small>-->
<!--              افزودن-->
<!--            </v-btn>-->
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="permissions"
              disable-pagination
              hide-default-footer
              :items-per-page="5"
              v-if="permissions"
              class="elevation-1"
          >
            <template v-slot:item.actions>
              <v-btn color="primary" fab x-small elevation="0">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      permissionActionModal: false,
      headers: [
        {text: 'کد ', value: 'id'},
        {text: 'سطح ', value: 'name'},
        // {
        //   text: 'عملیات',
        //   value: 'actions'
        // }
      ],
      permissions: null,
    }
  },
  methods: {
    getPermissionList() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getPermissionList').then((resp) => {
        this.permissions = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
  },
  mounted() {
    this.getPermissionList()
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  }
}
</script>

<style scoped>

</style>