<template>
    <div>
        <v-container>
            <v-card class="my-8">
                <v-card-title>
                    مدیریت ارز ها
                    <v-spacer></v-spacer>

                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-data-table
                            hide-default-footer
                            :headers="headers"
                            :items="paymentMethods"
                            :items-per-page="-1"
                    >
                        <template v-slot:item.parent="{item}">
                            <span v-if="item.parent!=null">
                            {{item.parent.title}}
                            </span>
                        </template>
                        <template v-slot:item.conversion_rate="{item}">
                          <template v-if="checkRoleAndPermissions('admin.currencies.edit')">
                            <v-edit-dialog
                                v-if="item.parent!=null"
                                :return-value.sync="item.conversion_rate"
                                large
                                @save="save(item)"
                                cancel-text="لغو"
                                save-text="ویرایش"

                            >
                              <div>


                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                {{ item.conversion_rate }}
                                            </span>
                                  </template>
                                  <span>نرخ تبدیل {{item.parent.title}} به {{item.title}}</span>
                                </v-tooltip>
                              </div>
                              <template v-slot:input>
                                <div class="my-3 title">
                                        <span>
                                            آپدیت نرخ تبدیل
                                        </span>
                                </div>
                                <v-text-field
                                    v-model="item.conversion_rate"
                                    label="ویرایش"
                                    single-line
                                    autofocus
                                    style="direction:ltr;text-align: left;"
                                    outlined
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-else-if="item.parent!=null">
                            {{ item.conversion_rate }}
                          </template>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../event-bus";
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                page: 1,
                total: 1,
                paymentMethods: [],
                headers: [
                    {text: 'متد پرداختی', value: 'title'},
                    {text: 'نرخ تبدیل', value: 'conversion_rate'},
                    {text: 'ارز والد', value: 'parent'},
                ],

            }
        },
        computed: {
            ...mapGetters({
                currencies: 'getCurrencies'
            }),

            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        created() {
            this.getAllPaymentMethods();
        },
        methods: {
            save(item) {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('adminEditConversionRate', {
                    currencyId: item.id,
                    payload: {
                        conversion_rate: item.conversion_rate
                    }
                }).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notify', 'green');
                    EventBus.$emit('setLoading', false);
                })
            },
            // cancel() {
            //     console.log('canceling');
            // },
            // open() {
            //     console.log('opening');
            // },
            // close() {
            //     console.log('closing');
            // },
            getAllPaymentMethods() {
                this.$store.dispatch('getAllCurrencies', {page: this.page}).then((resp) => {
                    this.paymentMethods = resp.data.currencies;
                })
            }
        }
    }
</script>

<style scoped>

</style>


