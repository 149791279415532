<template>
  <v-container>
    <v-card class="my-8">

      <v-card-title ref="adminReports">
        گزارش مراجعان
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div class="addForm">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-dialog
                  ref="dialog"
                  v-model="FromModal"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      dense
                      outlined
                      v-model="reportInfo.from"
                      label="از تاریخ"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="reportInfo.from"
                    :max="new Date().toISOString().substr(0, 10)"
                    scrollable locale="fa"
                    first-day-of-week="6">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="FromModal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="FromModal = false">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-dialog
                  ref="dialog"
                  v-model="ToModal"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      dense
                      outlined
                      v-model="reportInfo.to"
                      :value="reportInfo.to"
                      label="تا تاریخه"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="reportInfo.to"
                    :max="new Date().toISOString().substr(0, 10)"
                    scrollable locale="fa"
                    first-day-of-week="6">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="ToModal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(reportInfo.to)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pt-4">
              <v-btn color="primary" elevation="0">
                درخواست گزارش
              </v-btn>
            </v-col>
          </v-row>

        </div>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="reports"
            mobile-breakpoint="300"
        >
          <template v-slot:item.name="{ item }">
            <v-chip @click="downloadReport(item)" style="cursor: pointer;">
              {{ item.name }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn class="my-2 elevation-1" color="primary" @click="downloadReport(item)" fab small
                   v-if="!isDownloading(item)">
              <v-icon small>fas fa-download</v-icon>
            </v-btn>
            <div style="min-width:50px" v-else>

              <v-progress-linear
                  v-model="getDownloading(item).value"
                  query
              ></v-progress-linear>

            </div>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-pagination
          v-model="page"
          :length="total"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "../../../event-bus";
import FileDownload from 'js-file-download';
import Axios from 'axios';

export default {
  name: "UserReport",
  created() {
    this.getAdminReports();
  },
  props: {
    getReportAction: {
      default: 'adminSepidarExports'
    },
    title: {
      default: 'گزارشات مالی'
    },
    type: {
      default: 'payment'
    }
  },

  data() {
    return {
      ToModal: false,
      FromModal: false,

      reportInfo: {},

      downloading: [],

      requesting: false,

      headers: [
        {
          text: 'نام گزارش',
          align: 'start',
          sortable: true,
          value: 'name',
        }, {
          text: 'از تاریخ',
          align: 'center',
          sortable: true,
          value: 'from_date',
        }, {
          text: 'تا تاریخ',
          align: 'center',
          sortable: true,
          value: 'to_date',
        },
        {
          text: 'تاریخ گزارش',
          align: 'center',
          sortable: true,
          value: 'generated_at',
        },
        {
          align: 'center',
          sortable: false,
          value: 'actions',
        }
      ],
      reports: [],
      page: 1,
      total: 1
    }
  },
  methods: {
    getDownloading(item) {
      return this.downloading.find(downloadItem => downloadItem.id == item.id);
    },
    removeFromDownloading(item) {

      this.downloading.splice(this.downloading.indexOf(item), 1)
      // let item = this.downloading.find(item => item.id == id);
      // if (item != undefined) {
      //
      // }
    },
    isDownloading(item) {
      let hasItem = this.downloading.find(downloadItem => downloadItem.id == item.id)
      if (hasItem != undefined) {
        return true;
      }
      return false;
    },
    ajaxDownload(url, filename, downloadItem) {

      try {
        return new Promise((resolve, reject) => {

          Axios({
            method: 'GET',
            url,
            withCredentials: true,
            headers: {
              Authorization: this.$store.getters.getAccessToken
            },
            responseType: 'blob',
            onDownloadProgress: (progress) => {
              downloadItem.value = parseInt(Math.round((progress.loaded / progress.total) * 100))
            }
          }).then(resp => {
            FileDownload(resp.data, filename);
            resolve();
          }).catch((err) => {
            if (!url.includes('checkLogin')) {
              // EventBus.$emit('notify', 'red', undefined, err.response.data.message);
            }
            reject(err);
          }).finally(() => {
            this.removeFromDownloading(downloadItem);
            // store.commit('setLoading', false);
          });
        });
      } catch (err) {
        // EventBus.$emit('notify', 'red', undefined, 'خطایی رخ داده است');
        // store.commit('setLoading', false);
      }
    },
    getAdminReports() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch(this.getReportAction, {
        page: this.page,
        type: 'users-journey'
      }).then((resp) => {
        this.total = resp.data.pagination.total_pages;
        this.reports = resp.data.reports;
        // this.loading = false;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })

    },

    downloadReport(item) {
      let x = this.downloading.push({
        id: item.id,
        state: true,
        value: 0
      });

      this.ajaxDownload('https://api.simiaroom.com/api/v1/admin/reports/' + item.id, item.name, this.downloading[x - 1]);
    },

  },
  watch: {
    page() {
      this.getAdminReports();
      this.$vuetify.goTo(this.$refs.adminReports.offsetTop);
    }
  }
}

</script>

<style scoped>

</style>