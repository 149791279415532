<template>
  <div class="fill-height" style="overflow: hidden">
    <v-container style="height: 90%" ref="adminClients" fluid>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-card class="" style="overflow: hidden">
          <v-card-text class=" pa-0 ma-0">
            <v-row class="" dense>
              <v-col cols="12" :lg="drawerActive ? 3 : 1">
                <v-navigation-drawer :width="'100%'" right>
                  <div class="userListArea">
                    <div class="navigationIcon text-left">
                      <v-btn @click="toggleDrawer()" color="primary mb-2" elevation="1" x-small fab>
                        <v-icon small v-if="drawerActive">mdi-arrow-right</v-icon>
                        <v-icon small v-else>mdi-arrow-left</v-icon>
                      </v-btn>
                    </div>
                    <v-fade-transition>
                      <TableOptions :key="1" class="" style="width: 90%;margin: 0 auto;" :searchFilters="crmFilters"
                                    v-show="drawerActive"
                                    :cols="{
                cols:12,
                md:12,
                lg:12,
                sm:12
              }" @applyFilters="applyFilters">
                      </TableOptions>
                    </v-fade-transition>
                    <div class="clientListArea">
                      <template v-if="clients.length > 0">
                        <v-list>
                          <v-list-item link v-for="(item,i) in clients" :key="i" @click="goToClientInfo(item.id)"
                                       :class="$route.params.clientId == item.id?'activeUser' : '' ">
                            <v-fade-transition>
                              <v-list-item-avatar color="grey" v-if="drawerActive">
                                <v-icon color="white">mdi-account</v-icon>
                              </v-list-item-avatar>
                            </v-fade-transition>
                            <v-list-item-content>
                              <v-fade-transition>
                                <v-list-item-title>
                                  <template v-if="drawerActive">
                                    {{ item.full_name }}
                                  </template>
                                  <template v-else>
                                    {{ item.full_name != null ? item.full_name : item.email }}
                                  </template>
                                </v-list-item-title>
                              </v-fade-transition>
                              <v-list-item-subtitle v-if="drawerActive">{{
                                  item.email
                                }}
                                <br>
                                <template v-if="drawerActive">
                                  <v-chip x-small color="green" text-color="white" v-for="(item , i) in item.situations"
                                          :key="i">{{ item.situation | translateLabel }}
                                  </v-chip>
                                </template>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="drawerActive">
                              <div class="d-flex">
                                <!-- more icon -->
                                <OptionMenuCrm @dialogAssignAccount="dialogAssignAccount($event)" :user_id="item.id"/>
                                <!-- more icon -->
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </template>
                      <template v-else>
                        <p class="font-weight-bold">کاربر پیدا نشد</p>
                      </template>
                      <div class="pagination" v-if="drawerActive">
                        <v-pagination v-model="page" :length="total"></v-pagination>
                      </div>
                    </div>
                  </div>
                </v-navigation-drawer>
              </v-col>
              <v-col cols="12" :lg="drawerActive ? 9 : 11">
                <div v-if="$route.name == 'mainPageUserBasedCrm'"
                     class="d-flex grey lighten-1 fill-height flex-column justify-space-around text-center"
                     style="border-radius: 10px">
                  <div class="">یک کاربر را انتخاب کنید</div>
                </div>
                <div v-else style="overflow: scroll;height: 80vh;" class=" fill-height">
                  <router-view/>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <template v-else class="fill-height">
        <v-window v-model="windowMobile" class="fill-height">
          <TableOptions :key="1" :searchFilters="crmFilters" v-show="drawerActive"
                        :cols="{
                cols:12,
                md:12,
                lg:12,
                sm:12
              }" @applyFilters="applyFilters">
          </TableOptions>
          <v-window-item :value="1" class="fill-height">
            <div class="fill-height ">
              <template v-if="clients.length > 0">
                <v-list class="clientListAreaMobile">
                  <v-list-item link v-for="(item,i) in clients" :key="i" @click="goToClientInfo(item.id)">
                    <v-list-item-avatar color="grey">
                      <v-icon color="white">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.full_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip x-small color="green" text-color="white" v-for="(item , i) in item.situations" :key="i">
                        {{ item.situation | translateLabel }}
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-action>
                      <div class="d-flex">
                        <!-- more icon -->
                        <OptionMenuCrm @dialogAssignAccount="dialogAssignAccount($event)" :user_id="item.id"/>
                        <!-- more icon -->
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
              <template v-else>
                <p class="font-weight-bold">کاربر پیدا نشد</p>
              </template>
              <div class="pagination">
                <v-pagination v-model="page" :length="total"></v-pagination>
              </div>
            </div>
          </v-window-item>
          <v-window-item :value="2">
            <div style="overflow: scroll;height: 80vh;" class=" fill-height">
              <router-view/>
            </div>
          </v-window-item>
        </v-window>
      </template>
    </v-container>

    <!--    load menu dialog-->
    <v-dialog v-model="dialogAssignAccountManger" width="550">
      <AssignAccountManger :user_id="user_id" @dialogAssignAccount="dialogAssignAccount($event)"/>
    </v-dialog>
    <!--    load menu dialog-->


  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import TableOptions from "@/components/TableOptions";
import {crmFilter} from "../../../searchFilters";
import OptionMenuCrm from "@/components/crm/optionMenuCrm";
import AssignAccountManger from "@/components/crm/menuComponent/assignAccountManger";
import CancelSeessionCrm from "@/components/crm/cancelSeessionCrm";


export default {
  components: {CancelSeessionCrm, AssignAccountManger, OptionMenuCrm, TableOptions},
  data() {
    return {
      total: null,
      page: 1,
      clients: [],
      patient_name: null,
      timer: null,
      options: {},
      crmFilters: crmFilter,
      expandOnHover: false,
      width: "100%",
      windowMobile: 1,
      drawerActive: true,
      dialogAssignAccountManger: false,
      user_id: null,
      dialogCancelSession: true,
    }

  },
  methods: {
    applyFilters(options) {
      this.page = 1;
      this.options = options;
      this.getClientsList();
    },
    getClientsList() {
      EventBus.$emit('setLoading', true)
      this.clients = [];
      this.$store.dispatch('getUserList', {
        page: this.page,
        ...this.options,
        account_manager: true,
      }).then((data) => {
        this.clients = data.data.users
        this.total = data.data.pagination.total_pages
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    goToClientInfo(id) {
      this.$router.push({name: 'CrmUserInfo', params: {clientId: id}});
      EventBus.$emit('returnWindow')
      this.drawerActive = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.windowMobile = 2
      }
    },
    toggleDrawer(value = null) {
      if (value != null) {
        this.drawerActive = value
      } else {
        this.drawerActive = !this.drawerActive
      }
    },
    dialogAssignAccount(data) {
      this.dialogAssignAccountManger = data.dialog
      this.user_id = data.userID
    }

  },
  mounted() {
    // this.getClientsList()
    if (this.$route.params.clientId != null && this.$vuetify.breakpoint.mdAndDown) {
      this.windowMobile = 2
    }
    // if (this.$route.name == 'CrmUserInfo') {
    //   // this.drawerActive = false
    // }
    EventBus.$on('toggleDrawer', (val) => {
      this.toggleDrawer(val)
    })
    EventBus.$on('returnBackSm', () => {
      this.windowMobile = 1
      this.$router.push({name: 'mainPageUserBasedCrm'})
    })
    EventBus.$on('updateListUsersCRM', () => {
      this.getClientsList();
    })
  },
  beforeDestroy() {
    EventBus.$off('toggleDrawer')
    EventBus.$off('returnBackSm')
    EventBus.$off('updateListUsersCRM')
  },
  watch: {
    page() {
      this.getClientsList();
      this.$vuetify.goTo(this.$refs.adminClients.offsetTop);
    },


    // patient_name(val) {
    //   if (val != " " && this.patient_name != null) {
    //     clearTimeout(this.timer)
    //     this.timer = setTimeout(() => {
    //       this.getClientsList()
    //     }, 500)
    //   }
    // }
  },
  beforeUpdate() {
    // alert('update')
  },
  updated() {

  },
  filters: {
    translateLabel(val) {
      switch (val) {
        case 'check_user_profile':
          return 'تکمیل اطلاعات'
        case 'set_session':
          return 'تنظیم جلسه'
        case "wait_for_session":
          return 'در انتظار برگزاری'
        case "seller_determine":
          return 'تایید پشتیبان فروش '
        case "sell_progress":
          return "در انتظار پیگیری خرید"
        case  "will_not_buy":
          return 'عدم تمایل به خرید'
        case  "will_buy":
          return 'تمایل به خرید'
        case "buy_package":
          return 'در انتظار خرید'
        case "set_doctor":
          return 'تخصیص دکتر'
        case "purchase_follow_up":
          return "در انتظار پیگیری خرید "
        case "discontinue":
          return 'عدم ادامه'
        default :
          return val
      }
    }
  }
}
</script>

<style scoped>
.clientListAreaMobile {
  height: 80%;
  overflow: scroll;
}

.clientListArea {
  /*height: 550px;*/
  overflow: scroll !important;
  overflow-x: hidden !important;
}

.activeUser {
  background: #ededed;
  font-weight: bolder;
  transition: all 0.3s;
}

.userListArea {
  position: relative;
}

.navigationIcon {
  /*position: absolute;*/
  top: 0;
  left: 10px;
  z-index: 999;
}

.pagination {
  bottom: -20px;
}

</style>