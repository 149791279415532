/*eslint-disable*/
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import {i18n} from './plugins/vuetify';
// import i18n from './i18n';
import Axios from "axios";
// import VueGtm from 'vue-gtm';
// import VueTour from 'vue-tour';
import VueSocialSharing from 'vue-social-sharing';


import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

// Vue.component('apexchart', VueApexCharts)

// import VueSocketIO from 'vue-socket.io';
// require('vue-tour/dist/vue-tour.css');
// Vue.use(VueTour);

// import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts)
//
// Vue.component('apexchart', VueApexCharts)

import VueSocketIOExt from 'vue-socket.io-extended';
import {io} from 'socket.io-client';

const urlParams = new URLSearchParams(window.location.search);
// const token = urlParams.get('token');


import VueNativeNotification from 'vue-native-notification'

Vue.use(VueNativeNotification, {
    // Automatic permission request before
    // showing notification (default: true)
    requestOnNotify: true
})


import firebase from "firebase/app";
import "firebase/messaging";
import PushNotification from "../Lib/Notification";

new PushNotification().initialFirebaseSw()

const socket = io('https://socket.simiaroom.com', {
    query: `token=${store.getters.getBcCode}`
});

Vue.use(VueSocketIOExt, socket);

import CountryFlag from 'vue-country-flag'
import {firebaseCredential} from "@/firebase-credantional";

Vue.component('country-flag', CountryFlag);

Vue.use(require('vue-moment-jalaali'));


Vue.use(VueSocialSharing);
// Vue.use(VueGtm, {
//     // id:'GTM-WBKRP9P',
//     enabled: true,
//     loadScript: true
// });

Vue.config.productionTip = false;

const activeCycle = store.getters.hasValidActiveCycle;
if (activeCycle) {
    Axios.defaults.headers["X-USER-CYCLE"] = activeCycle._id;
}

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
